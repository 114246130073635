import { useState } from 'react';
import { TRADE_ITEM_STATUS } from '@treasurer/common';
import styled from 'styled-components';
import StyledButton from '@/Components/Button/StyledButton';
import { usePurchase } from '@/contexts/PurchaseContext';
import {
  useRequestRecruitmentPurchase,
  useRequestPurchase,
} from '@/fetcher/ItemQueries';
import { LineDivider, PaddedContainer } from '@/styles/CommonStyledComponents';
import { ReactComponent as DisclaimerIcon } from '../../../assets/images/icon_exclamation_mark.svg';
import { ReactComponent as PrecautionIcon } from '../../../assets/images/precautionIcon.svg';
import {
  Disclaimer,
  HighlightedText,
  MainGrayText,
  MainText,
  WarningMessage,
} from '../PaymentStyles';
import ocbPointView from './ocbPointUi';
import OrderCompleteModal from '@/Components/Order/OrderCompleteModal';
import LabelValueList from '@/Components/ui/LabelValueList';

const FixedContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px -4px 12px 0px rgba(212, 212, 212, 0.25);
`;

const InnerContainer = styled(PaddedContainer)`
  max-width: 768px;
  margin: auto;
`;

interface FixedCtaContainerProps {
  isDisabled: boolean;
  mctTrToken: string;
  availablePointree: number;
}

function FixedCtaContainer({
  isDisabled,
  mctTrToken,
  availablePointree,
}: FixedCtaContainerProps) {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { state } = usePurchase();
  const {
    item,
    isMetal,
    orderSummary,
    totalPrice,
    txBudget,
    krwBudget,
    pointBudget,
    selectedPointSystem,
    pointUseAmount,
    krwUseAmount,
  } = state;

  const requestPurchase = useRequestPurchase({
    onSuccess: () => {
      setIsConfirmationModalOpen(true);
    },
  });
  const requestRecruitmentPurchase = useRequestRecruitmentPurchase({
    onSuccess: () => {
      setIsConfirmationModalOpen(true);
    },
  });

  if (!orderSummary) return null;

  const isRecruitmentItem = item.status === TRADE_ITEM_STATUS.ON_SALE;

  const belowTxBudget = krwBudget + pointBudget < txBudget;

  const InsufficientInputMessage = () => (
    <WarningMessage>
      <PrecautionIcon />
      {isMetal
        ? `주문 희망 금액 ${txBudget.toLocaleString()}원에 맞춰
      입력해 주세요.`
        : '입력금액이 부족해요.'}
    </WarningMessage>
  );

  const PointUseDisclaimerMessage = () => (
    <Disclaimer>
      <DisclaimerIcon />
      포인트 사용에 따라 최종 {isMetal && '구매 중량 및 '}결제 금액이 변동될 수
      있어요.
    </Disclaimer>
  );

  const processPurchase = () => {
    const orderInfo = {
      tradeItemId: item.id,
      quantity: orderSummary.aggregatedResult.aggregatedQuantity,
      txBudget,
      krwBudget,
      pointSystemId: selectedPointSystem,
      pointBudget,
      extra: {
        mctTrToken,
        availablePointree,
      },
    };

    isRecruitmentItem
      ? requestRecruitmentPurchase.mutate(orderInfo)
      : requestPurchase.mutate(orderInfo);
  };

  const requestPurchaseWithOcb = () => {
    ocbPointView(mctTrToken, (name: any, authResult: any) => {
      if (name === 'POINTS_AUTHENTICATED') {
        if (authResult.isSuccessPwd) {
          processPurchase();
        }
      } else if (name === 'POINTS_AUTH_FAILED') {
        console.log(authResult);
        // setIsProcessingTx(false);
      }
    });
  };

  const handlePaymentButton = () => {
    if (selectedPointSystem === 'ocbPoint' && pointUseAmount > 0) {
      requestPurchaseWithOcb();
    } else {
      processPurchase();
    }
  };

  const totalPriceArray = [
    {
      label: <MainGrayText>현재 입력된 금액</MainGrayText>,
      value: (
        <MainGrayText>
          {(krwBudget + pointBudget).toLocaleString()}원
        </MainGrayText>
      ),
    },
    {
      label: <MainText>최종 결제 금액</MainText>,
      value: (
        <HighlightedText>
          {belowTxBudget ? '— ' : totalPrice.toLocaleString()}원
        </HighlightedText>
      ),
    },
  ];

  return (
    <>
      <FixedContainer>
        <InnerContainer>
          <LabelValueList items={totalPriceArray} />

          <LineDivider />

          {belowTxBudget ? (
            <InsufficientInputMessage />
          ) : (
            selectedPointSystem !== 'none' && <PointUseDisclaimerMessage />
          )}

          <StyledButton onClick={handlePaymentButton} disabled={isDisabled}>
            구매하기
          </StyledButton>
        </InnerContainer>
      </FixedContainer>

      <OrderCompleteModal
        isConfirmationModalOpen={isConfirmationModalOpen}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        orderType={'BID'}
      />
    </>
  );
}

export default FixedCtaContainer;
