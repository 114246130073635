import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  useEffect,
} from 'react';
import { isMetal } from '@treasurer/common';
import { getPurchaseOrderSummary } from '@/fetcher/ItemQueries';
import { useUserBalance } from '@/fetcher/userQueries';

interface PurchaseState {
  item: any | null;
  isMetal: boolean;
  unitPrice: number;
  orderSummary: any;
  txBudget: number;
  krwBudget: number;
  krwUseAmount: number;
  pointBudget: number;
  pointUseAmount: number;
  quantity: number;
  orderType: 'BID' | 'FUNDING';
  totalPrice: number;
  selectedPointSystem: string;
  krwBalance: number;
  pointBalance: number;
}

type PurchaseAction =
  | { type: 'SET_ITEM'; payload: any }
  | { type: 'SET_UNIT_PRICE'; payload: number }
  | { type: 'SET_ORDER_INPUT'; payload: number }
  | { type: 'SET_ORDER_TYPE'; payload: 'BID' | 'FUNDING' }
  | { type: 'SET_ORDER_SUMMARY'; payload: any }
  | { type: 'SET_TOTAL_PRICE'; payload: number }
  | { type: 'SET_KRW_BUDGET'; payload: number }
  | { type: 'SET_KRW_USE_AMOUNT'; payload: number }
  | { type: 'SET_POINT_SYSTEM'; payload: string }
  | { type: 'SET_POINT_BUDGET'; payload: number }
  | { type: 'SET_POINT_USE_AMOUNT'; payload: number }
  | { type: 'SET_KRW_BALANCE'; payload: number }
  | {
      type: 'SET_POINT_BALANCE';
      payload: number;
    }
  | { type: 'RESET_ORDER'; payload?: Partial<PurchaseState> };

const initialPurchaseState: PurchaseState = {
  item: null,
  isMetal: false,
  unitPrice: 0,
  orderSummary: null,
  txBudget: 0,
  krwBudget: 0,
  krwUseAmount: 0,
  pointBudget: 0,
  pointUseAmount: 0,
  quantity: 0,
  orderType: 'BID',
  totalPrice: 0,
  selectedPointSystem: 'none',
  krwBalance: 0,
  pointBalance: 0,
};

function purchaseReducer(
  state: PurchaseState,
  action: PurchaseAction,
): PurchaseState {
  switch (action.type) {
    case 'SET_ITEM':
      return {
        ...state,
        item: action.payload,
        isMetal: isMetal(action.payload),
      };
    case 'SET_UNIT_PRICE':
      return { ...state, unitPrice: action.payload };
    case 'SET_ORDER_INPUT':
      if (state.isMetal) {
        return {
          ...state,
          txBudget: action.payload,
          krwBudget: Math.min(action.payload, state.krwBalance),
        };
      } else {
        const totalPriceWithFee =
          action.payload * state.unitPrice * (state.item.buyPriceRate || 1);
        return {
          ...state,
          quantity: action.payload,
          txBudget: totalPriceWithFee,
          krwBudget: Math.min(totalPriceWithFee, state.krwBalance),
        };
      }
    case 'SET_ORDER_TYPE':
      return { ...state, orderType: action.payload };
    case 'SET_ORDER_SUMMARY':
      return { ...state, orderSummary: action.payload };
    case 'SET_TOTAL_PRICE':
      return { ...state, totalPrice: action.payload };
    case 'SET_KRW_BUDGET':
      return { ...state, krwBudget: action.payload };
    case 'SET_KRW_USE_AMOUNT':
      return { ...state, krwUseAmount: action.payload };
    case 'SET_POINT_SYSTEM':
      const initialKrwBudget = Math.min(state.txBudget, state.krwBalance);
      return {
        ...state,
        selectedPointSystem: action.payload,
        pointBudget: 0,
        pointBalance: 0,
        krwBudget: initialKrwBudget,
      };
    case 'SET_POINT_BUDGET':
      const adjustedPointBudget = Math.min(
        action.payload || 0,
        state.txBudget,
        state.pointBalance,
      );
      const krwBudget = state.txBudget - adjustedPointBudget;
      const adjustedKrwBudget = Math.min(
        Math.max(krwBudget, 0),
        state.krwBalance,
      );

      return {
        ...state,
        pointBudget: adjustedPointBudget,
        krwBudget: adjustedKrwBudget,
      };
    case 'SET_POINT_USE_AMOUNT':
      return {
        ...state,
        pointUseAmount: action.payload,
      };
    case 'SET_KRW_BALANCE':
      return { ...state, krwBalance: action.payload };
    case 'SET_POINT_BALANCE':
      return { ...state, pointBalance: action.payload };
    case 'RESET_ORDER':
      return {
        ...initialPurchaseState,
        krwBalance: state.krwBalance,
      };
    default:
      return state;
  }
}

const PurchaseContext = createContext<{
  state: PurchaseState;
  dispatch: React.Dispatch<PurchaseAction>;
}>({
  state: initialPurchaseState,
  dispatch: () => undefined,
});

export const PurchaseProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(purchaseReducer, initialPurchaseState);

  const { data: userBalance } = useUserBalance();

  const initialKrwBudget = Math.min(state.krwBudget, state.krwBalance);

  const { data: purchaseOrderSummary } = getPurchaseOrderSummary({
    tradeItemId: state.item?.id,
    krwBudget: initialKrwBudget,
    pointSystemId: state.selectedPointSystem,
    pointBudget: state.pointBudget,
  });

  useEffect(() => {
    if (userBalance && userBalance.availableDepositAmount !== undefined) {
      dispatch({
        type: 'SET_KRW_BALANCE',
        payload: userBalance.availableDepositAmount,
      });
    }
  }, [userBalance, dispatch]);

  useEffect(() => {
    if (purchaseOrderSummary) {
      const calculatedTotalPrice =
        purchaseOrderSummary?.aggregatedResult.aggregatedPrice;
      const calculatedKrwUseAmount =
        purchaseOrderSummary?.paymentList.krw?.amount || 0;
      const calculatedPointUseAmount =
        purchaseOrderSummary?.paymentList[state.selectedPointSystem]?.amount ||
        0;

      const belowTxBudget =
        state.krwBudget + state.pointBudget < state.txBudget;
      const displayedKrwInputValue = belowTxBudget
        ? state.krwBudget
        : calculatedKrwUseAmount;

      dispatch({
        type: 'SET_TOTAL_PRICE',
        payload: calculatedTotalPrice,
      });
      dispatch({
        type: 'SET_KRW_USE_AMOUNT',
        payload: displayedKrwInputValue,
      });
      dispatch({
        type: 'SET_POINT_USE_AMOUNT',
        payload: calculatedPointUseAmount,
      });
      dispatch({
        type: 'SET_ORDER_SUMMARY',
        payload: purchaseOrderSummary,
      });
    }
  }, [
    purchaseOrderSummary,
    dispatch,
    state.krwBalance,
    state.pointBalance,
    state.selectedPointSystem,
  ]);

  return (
    <PurchaseContext.Provider value={{ state, dispatch }}>
      {children}
    </PurchaseContext.Provider>
  );
};

export const usePurchase = () => useContext(PurchaseContext);
