import { FlexRowCentered } from '@/styles/CommonStyledComponents';
import styled from 'styled-components';

export const SectionTitle = styled.div`
  color: #000;
  font-size: 17px;
  font-weight: 700;
`;

export const Disclaimer = styled(FlexRowCentered)`
  color: #979797;
  font-size: 12px;
  padding-bottom: 16px;
  justify-content: center;
  gap: 4px;
  word-break: keep-all;
`;

export const WarningMessage = styled(Disclaimer)`
  color: #e44340;
`;

export const MainText = styled.span`
  font-size: 16px;
  font-weight: 700;
`;
export const MainGrayText = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #979797;
`;

export const HighlightedText = styled(MainText)`
  color: #fe8824;
`;
