import { useUserDepositRequestAuth } from '@/Queries/userQueries';
import NotFound from '@/Routes/NotFound';
import { localSetItem } from '@/utils/handleStorage';
import { isNil } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../Loading';
import VirtualAccountPageLinker from './VirtualAccountPageLinker';

const LinkAuthVirtualAccount = ({ match, history }: RouteComponentProps) => {
  const uuid = match.params.uuid;
  const { data: token, isLoading } = useUserDepositRequestAuth(uuid);

  if (isLoading) {
    return (
      <Container>
        <Loading />;
      </Container>
    );
  }

  if (isNil(token)) {
    return <NotFound />;
  }

  localSetItem('session', token, 20160);

  return <VirtualAccountPageLinker />;
};

export default LinkAuthVirtualAccount;

const Container = styled.div`
  margin-top: 50px;
`;
