interface PaymentMethod {
  id: string;
  label: string;
  feePercentage?: number;
}

export const PAYMENT_TREASURER: PaymentMethod[] = [
  { id: 'krw', label: '예치금' },
];

export const POINT_SYSTEMS: PaymentMethod[] = [
  { id: 'none', label: '사용 안 함' },
  { id: 'treasurerPoint', label: '트레져러 포인트' },
  // { id: 'treasurerMetalPoint', label: '금은 포인트' },
  // { id: 'ocbPoint', label: 'OK 캐쉬백', feePercentage: 1.5 },
  // { id: 'kbPoint', label: 'KB 포인트리', feePercentage: 1.5 },
  // { id: 'lPoint', label: 'L.POINT', feePercentage: 1.5 },
];

export type PointSystemId = typeof POINT_SYSTEMS[number]['id'];
