import styled from '@emotion/styled';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ArrowBack } from '../assets/images/ArrowBack.svg';
import FullLoading from '../Components/Loading/FullLoading';
import { getNotice } from '../fetcher/useQueries';
import NestedWebViewHeader from '@/Components/Common/NestedWebViewHeader';

const NoticeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  margin-bottom: 20px;
`;

const NoticeWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 22px;
`;

const NoticeTitle = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  padding-bottom: 10px;
`;

const NoticeText = styled.span`
  font-size: 14px;
  line-height: 18px;
`;

const NoticeContentWrapper = styled.div`
  width: 100%;
  padding: 10px 0px 20px 0px;

  ul {
    li {
      padding: 12px 0px;
      border-bottom: 1px solid rgba(72, 79, 97, 0.2);

      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
`;

const NoticeContentTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    width: 60%;
  }

  @media (max-width: 300px) {
    width: 100%;
  }
`;

const NoticeDate = styled.p`
  font-size: 12px;
  line-height: 30px;
  margin-left: auto;
  text-align: end;
  width: 100px;

  @media (max-width: 768px) {
    width: 80px;
  }
`;

const NoticePage = () => {
  const history = useHistory();
  const { data: noticeData, isLoading: isLoadingNoticeData } = getNotice();

  if (isLoadingNoticeData) {
    <FullLoading />;
  }

  return (
    <NoticeContainer>
      <NestedWebViewHeader
        title="공지사항"
        redirectTo="/more"
        style={{ marginLeft: '-20px' }}
      />

      <NoticeWrapper>
        <NoticeTitle>공지사항</NoticeTitle>
        <NoticeText>이벤트, 개발 업데이트 사항 등을 알려드립니다.</NoticeText>
        <NoticeContentWrapper>
          <ul>
            {noticeData &&
              noticeData.map((v) => (
                <li key={v.id}>
                  <a href={v.url} rel="noopener noreferrer" target="_blank">
                    <NoticeContentTitle>{v.title}</NoticeContentTitle>
                    <NoticeDate>
                      {moment(v.createdAt).format('YY.MM.DD')}
                    </NoticeDate>
                  </a>
                </li>
              ))}
          </ul>
        </NoticeContentWrapper>
      </NoticeWrapper>
    </NoticeContainer>
  );
};

export default NoticePage;
