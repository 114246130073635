import axios from '../apis/axios-instance';
import { VoteTypes, VoteUserTypes } from '../types/AppTypes';
import { ApiResult } from '../types/Home/Types';

const getMyVoteListFetcher = async () => {
  try {
    const { data } = await axios.get(`/v2/api/treasurer/user/vote/list`);
    return data.data;
  } catch (error) {}
};

const getVoteListFetcher = async (voteId: string | undefined) => {
  const { data } = await axios.get<ApiResult<VoteTypes[]>>(
    `/v2/api/treasurer/app/vote/list/${voteId}`,
  );
  return data.data;
};

const getVoteUserDataFetcher = async (voteId: string) => {
  const { data } = await axios.get<ApiResult<VoteUserTypes>>(
    `/v2/api/treasurer/app/vote/user/${voteId}`,
  );

  return data.data;
};

const postVoteUserFetcher = async ({
  voteId,
  vote,
}: {
  voteId: number;
  vote: number;
}) => {
  const { data } = await axios.post(`/v2/api/treasurer/app/vote/${voteId}`, {
    vote,
  });

  return data.result;
};

const checkExistingUserVoteFetchers = async (proposalId: number) => {
  try {
    const response = await axios.get(
      `/v2/api/treasurer/sellProposal/vote/check/${proposalId}`,
    );
    return response.data.data;
  } catch (error) {
    console.error('Error in checkExistingUserVoteFetchers:', error);
  }
};

// TODO: deprecated
const getVoteItemDataFetcher = async (tradeItemId: number) => {
  const { data } = await axios.get(
    `/v2/api/treasurer/app/vote/data/${tradeItemId}`,
  );

  return data.data;
};

const getVoteItemByProposalIdFetcher = async (proposalId: number) => {
  const { data } = await axios.get(
    `/v2/api/treasurer/sellProposal/vote/data/${proposalId}`,
  );
  return data.data;
};

const getLiveProposalVoteFetcher = async (tradeItemId: number) => {
  const { data } = await axios.get(
    `/v2/api/treasurer/item/vote/liveProposal/${tradeItemId}`,
  );
  return data.data;
};

const getDisposalInProgressItemFetcher = async () => {
  const { data } = await axios.get(
    `/v2/api/treasurer/user/disposal/inProgress`,
  );
  return data.data;
};

export {
  getMyVoteListFetcher,
  getVoteListFetcher,
  getVoteUserDataFetcher,
  postVoteUserFetcher,
  getVoteItemDataFetcher,
  getVoteItemByProposalIdFetcher,
  getLiveProposalVoteFetcher,
  checkExistingUserVoteFetchers,
  getDisposalInProgressItemFetcher,
};
