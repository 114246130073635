import styled from 'styled-components';
import palette from '@/config/styles/color/Color';

const TabMenuContainer = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  border-bottom: 1px solid ${palette.ghost[4]};
  margin: 0 -16px;
`;

const TabMenuButton = styled.button<{ active: boolean }>`
  background-color: transparent;
  border: none;
  padding: 8px 0;
  margin-right: 16px;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.active ? '3px solid black' : '3px solid transparent'};
  color: ${(props) =>
    props.active ? `${palette.black[0]}` : `${palette.ghost[2]}`};

  :first-child {
    margin-left: 16px;
  }
`;

type TabMenuProps = {
  tabMenuComposition: { category: string; label: string }[];
  selectedCategory: string;
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
};

const TabMenu = ({
  tabMenuComposition,
  selectedCategory,
  setSelectedCategory,
}: TabMenuProps) => {
  const handleTabClick = (category: string) => {
    setSelectedCategory(category);
  };

  return (
    <TabMenuContainer>
      {tabMenuComposition.map(({ category, label }) => (
        <TabMenuButton
          key={category}
          active={selectedCategory === category}
          onClick={() => handleTabClick(category)}
        >
          {label}
        </TabMenuButton>
      ))}
    </TabMenuContainer>
  );
};

export default TabMenu;
