import ReactLoading from 'react-loading';
import { useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import {
  isMetal,
  roundToTwoDecimalPlaces,
  roundToFourDecimalPlaces,
  getFeeRate,
  getItemUnitLabel,
} from '@treasurer/common';
import { useDispatch } from 'react-redux';
import { isNil } from 'lodash';
import {
  pieceBuyRequest,
  pieceSellRequest,
} from '../../redux/actions/userAction';
import {
  getOrderSummary,
  useRequestBuy,
  useRequestSell,
} from '../../fetcher/ItemQueries';
import ConfirmModal from '../ConfirmModal';
import OrderCompleteModal from '../Order/OrderCompleteModal';
import { StyledCloseIcon } from './MetalBuyComponent';
import { HowmuchTradeItemAttributes } from '@/types/ItemTypes';
type IsMobileProps = {
  isMobile: boolean;
};

const Content = styled.div<IsMobileProps>`
  ${({ isMobile }) => isMobile && `padding: 20px;`}
  max-width: 400px;
  margin: auto;
`;

const Header = styled.div<IsMobileProps>`
  ${({ isMobile }) => isMobile && `margin: 25px 0;`}
`;

export const ModalTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 32px;
  color: #353c49;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #484f61;
`;

export const HorizontalLine = styled.div`
  border-top: 1px;
  border: 1px solid #f2f4f6;
  margin: 16px 0;
`;

export const TotalPrice = styled(Row)`
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #353c49;
`;

export const FinePrint = styled.div<IsMobileProps>`
  margin-top: 30px;
  margin-bottom: ${({ isMobile }) => (isMobile ? '80px' : '30px')};
  background: #f6f6f7;
  border-radius: 10px;
  padding: 20px 16px;
  word-break: keep-all;

  p {
    color: #353c49;
    font-weight: 700;
  }

  li {
    list-style-type: disc;
    list-style-position: outside;
    margin-left: 20px;
    color: #484f61;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 15px;
  }

  span {
    color: #a3a7b0;
  }
`;

const Button = styled.div<IsMobileProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff8a22;
  color: white;
  font-weight: 700;
  cursor: pointer;
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? '76px' : '50px')};
  font-size: ${({ isMobile }) => (isMobile ? '16px' : '18px')};
  border-radius: ${({ isMobile }) => (isMobile ? '0px' : '5px')};
  ${({ isMobile }) => isMobile && `position: fixed; bottom: 0;`}
`;

export const SpinnerBox = styled.div`
  display: flex;
  justify-content: center;
`;

type OrderSummaryModalContentProps = {
  item: HowmuchTradeItemAttributes;
  enteredNumber: number;
  orderType: 'BID' | 'ASK';
  appraisalPrice: number;
  unitPrice?: number;
  isOrderBookMethod?: boolean;
  onRequestClose: () => void;
};

const OrderSummaryModalContent = ({
  item,
  enteredNumber,
  orderType,
  appraisalPrice, // item.appraisalPrice를 사용하는 대신 저장된 값을 prop으로 받는 이유는 metal의 appraisalPrice가 1분 단위로 업데이트되기 때문 (가격 홀드)
  unitPrice,
  isOrderBookMethod,
  onRequestClose,
}: OrderSummaryModalContentProps) => {
  const [enabled, setEnabled] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  let orderSummary;
  let isLoadingOrderSummary = false;

  const tradeItemId = item?.id;
  const pieceUnitPrice = unitPrice ?? 0; // metal을 제외한 상품 조각만 unitPrice prop이 있음

  if (isMetal(item)) {
    const enteredPrice = enteredNumber;
    const enteredQuantity = enteredNumber;

    const orderSummaryResult = getOrderSummary(
      tradeItemId,
      orderType,
      enteredPrice,
      enteredQuantity,
    );
    orderSummary = orderSummaryResult.data;
    isLoadingOrderSummary = orderSummaryResult.isLoading;
  }

  // 수수료 제외 주문 금액
  const transactionPrice =
    (isMetal(item)
      ? orderSummary?.calculatedTransactionPrice
      : pieceUnitPrice * enteredNumber) ?? 0;

  const orderQuantity = Math.floor(
    (isMetal(item) ? orderSummary?.calculatedOrderQuantity : enteredNumber) ??
      0,
  );
  const orderGrams = isMetal(item)
    ? orderQuantity * (1 / (item?.ownershipQuantityDivider ?? 1))
    : 0;

  const feeRate = getFeeRate({ item, orderType });

  const displayedFeePercentage = roundToTwoDecimalPlaces(
    Math.abs(feeRate - 1) * 100,
  );

  const totalPriceWithFee = Math.round(transactionPrice * feeRate);

  const fee = totalPriceWithFee - transactionPrice;

  const currentPrice = isMetal(item) ? appraisalPrice : pieceUnitPrice;

  const {
    mutate: requestBuy,
    isLoading: isLoadingRequestBuy,
    error,
  } = useRequestBuy(
    {
      tradeItemId,
      appraisalPrice,
      quantity: Math.floor(orderQuantity),
      totalTransactionPrice: totalPriceWithFee,
    },
    {
      enabled,
      onSuccess: (result: any) => {
        setIsConfirmationModalOpen(true);
      },
      onError: async (result: any | { result: string }) => {
        setErrorMsg(result.message);
        setErrorModal(true);
      },
    },
  );

  const {
    mutate: requestSell,
    isLoading: isLoadingRequestSell,
    data,
  } = useRequestSell(
    {
      tradeItemId,
      appraisalPrice,
      quantity: orderQuantity,
      totalTransactionPrice: totalPriceWithFee,
    },
    {
      enabled,
      onSuccess: () => {
        setIsConfirmationModalOpen(true);
      },

      onError: async (result: any) => {
        setErrorMsg(result.message);
        setErrorModal(true);
      },
    },
  );

  // 호가 구매 주문
  const dispatch = useDispatch() as any;

  const orderBookBuyRequest = async () => {
    try {
      const result: any = await dispatch(
        pieceBuyRequest(tradeItemId, enteredNumber, pieceUnitPrice),
      );

      if (!isNil(result?.data?.data)) {
        setIsConfirmationModalOpen(true);
      }
    } catch (e) {
      if (typeof window !== 'undefined') window.console.error(e);
    }
  };

  // 호가 판매 주문
  const orderBookSellRequest = async () => {
    try {
      const result: any = await dispatch(
        pieceSellRequest(tradeItemId, enteredNumber, pieceUnitPrice),
      );
      if (!isNil(result?.data?.data)) {
        setIsConfirmationModalOpen(true);
      }
    } catch (e) {
      if (typeof window !== 'undefined') window.console.error(e);
    }
  };

  const isMobile = useMediaQuery('(max-width: 768px)');

  // throttle buy/sell button
  let timer: NodeJS.Timeout | undefined;

  const handleTransactionButton = (orderType: 'BID' | 'ASK') => {
    if (timer) {
      clearTimeout(timer);
      timer = undefined;
    }

    timer = setTimeout(() => {
      if (orderType === 'BID') {
        if (isOrderBookMethod) {
          orderBookBuyRequest();
        } else {
          requestBuy();
        }
      }
      if (orderType === 'ASK') {
        if (isOrderBookMethod) {
          orderBookSellRequest();
        } else {
          requestSell();
        }
        timer = undefined;
      }
    }, 1000);
  };

  if (isLoadingOrderSummary) return <div />;

  return (
    <>
      <div>
        <Content isMobile={isMobile}>
          <StyledCloseIcon onClick={onRequestClose} />

          <ModalTitle>
            총{' '}
            <span
              style={{
                textDecoration: 'underline',
                textUnderlineOffset: 3,
              }}
            >
              {totalPriceWithFee.toLocaleString()}원
            </span>
            에 {orderType === 'BID' ? '구매' : '판매'}
            할게요
          </ModalTitle>

          <Row>
            <div>주문시세</div>
            <div>
              {currentPrice.toLocaleString()}원/1{getItemUnitLabel(item)}
            </div>
          </Row>

          {isMetal(item) ? (
            <Row>
              <div>거래중량</div>
              <div>{roundToFourDecimalPlaces(orderGrams)}g</div>
            </Row>
          ) : (
            <Row>
              <div>거래조각</div>
              <div>{orderQuantity.toLocaleString()}개</div>
            </Row>
          )}

          <Row>
            <div>거래금액</div>
            <div>{transactionPrice?.toLocaleString()}원</div>
          </Row>

          <Row>
            <div>수수료</div>
            <div>{fee?.toLocaleString()}원</div>
          </Row>

          <HorizontalLine />

          <TotalPrice>
            <div>총 {orderType === 'BID' ? '구매' : '판매'}금액</div>
            <div style={{ color: '#ff8a22' }}>
              {totalPriceWithFee?.toLocaleString()}원
            </div>
          </TotalPrice>

          {isMetal(item) ? (
            <FinePrint isMobile={isMobile}>
              <p>{item?.modelKR} 서비스 이용 약관</p>
              <ul>
                <li>
                  총 거래금액은 수수료(
                  {displayedFeePercentage}
                  %) 합산 금액이며, 최소 거래 단위를 고려해 자동으로 계산된 금액
                  입니다.
                </li>
                <li>
                  거래에 대한 환불이 어렵습니다. 환불을 원할 시, 고객센터로
                  문의바랍니다.
                </li>
                <li>
                  거래에 따른 책임은 모두 본인에게 있으며, 이로 발생한 수익과
                  손실은 모두 본인에게 귀속됩니다.
                </li>
              </ul>
            </FinePrint>
          ) : isOrderBookMethod ? (
            <FinePrint isMobile={isMobile}>
              <p>공동 구매 약관</p>
              <ul>
                <li>
                  조각마켓에서 주문한 조각이 미체결 상태일 때 내자산 〉 마켓거래
                  내역에서 조각거래 주문취소가 가능합니다.
                </li>
                <li>
                  거래 체결 시 조각 거래에 대한 주문취소, 환불이 불가합니다.
                </li>
                <li>
                  플랫폼이용수수료는 상품의 조각 구매 금액의 1%
                  (부가가치세포함)이며, 조각 거래 시 플랫폼 이용 수수료을 같이
                  지급합니다.
                </li>
                <li>
                  조각구매, 거래에 따른 책임은 모두 본인에게 있으며, 이로 발생한
                  수익과 손실은 모두 본인에게 귀속됩니다.
                </li>
              </ul>
            </FinePrint>
          ) : (
            <FinePrint isMobile={isMobile}>
              <p>단일가 공동 구매 약관</p>
              <ul>
                <li>
                  단일가로 주문한 조각은 즉시 체결되므로 거래에 대한 취소는
                  어렵습니다.
                </li>
                {orderType === 'BID' ? (
                  <li>단일가 구매시 수수료는 면제됩니다.</li>
                ) : (
                  <li>
                    총 판매금액은 수수료 합산 금액이며, 단일가 판매 시 수수료는
                    상품 유동성에 따라 5~10%로 책정됩니다.
                  </li>
                )}
                <li>
                  거래에 따른 책임은 모두 본인에게 있으며, 이로 발생한 수익과
                  손실은 모두 본인에게 귀속됩니다.
                </li>
              </ul>
            </FinePrint>
          )}
        </Content>

        <Button
          onClick={() => handleTransactionButton(orderType)}
          isMobile={isMobile}
        >
          {!(isLoadingRequestBuy || isLoadingRequestSell) ? (
            orderType === 'BID' ? (
              '약관 동의 후 구매하기'
            ) : (
              '판매하기'
            )
          ) : (
            <SpinnerBox>
              <ReactLoading type="spin" color="#ffff" height={35} width={35} />
            </SpinnerBox>
          )}
        </Button>
      </div>

      <OrderCompleteModal
        isConfirmationModalOpen={isConfirmationModalOpen}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        orderType={orderType}
      />

      <ConfirmModal
        modalOpen={errorModal}
        onRequestClose={() => setErrorModal(false)}
        title={`${
          orderType === 'BID' ? '구매' : '판매'
        }주문이 정상적으로 처리되지 않았습니다.`}
        content={errorMsg}
        confirmBtnText="닫기"
        confirmBtnAction={() => {
          setErrorModal(false);
          window.location.reload();
        }}
      />
    </>
  );
};

export default OrderSummaryModalContent;
