import DefaultEventLandingPage from './DefaultEventLandingPage';
import eventDetails from '../../assets/images/event/subscriptionLaunch.png';

function SubscriptionLandingPage() {
  return (
    <DefaultEventLandingPage
      eventDetailImageSrc={eventDetails}
      eventName="금은 정기구독 서비스 런칭"
      link={'SubscriptionProgramScreen'}
      buttonText={'구독 신청하기'}
    />
  );
}

export default SubscriptionLandingPage;
