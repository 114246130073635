import { useState } from 'react';
import Dropdown from 'react-dropdown';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  DeleteButton,
  DeleteContainer,
  DeleteFAQContent,
  DeleteFAQContentWrapper,
  DeleteFAQTitle,
  DeleteFAQWrapper,
  DeleteWrapper,
  DeleteWrapperDesc,
  DeleteWrapperTitle,
  StyledLink,
} from './DeleteStyle';

interface DeleteServiceProps {
  reason: string;
  handleChange: (event: any) => void;
}

type Drop = {
  open: boolean;
};

const DELETE_FAQ_CONTENT: {
  title: string;
  content: string;
  linkTitle: string;
  link: string;
  state?: any;
}[] = [
  {
    title: '🔔 알림이 너무 자주 온다고 느껴지시나요?',
    content:
      '받고 싶은 알람과, 받기 싫은 알람을 설정 할 수 있어요. 내자산>프로필>알림설정에서 변경이 가능해요.',
    linkTitle: '알림설정 하기>',
    link: '/profile',
  },
  {
    title: '👀 개인정보 노출을 걱정하시나요?',
    content:
      '트레져러는 개인정보 보호법에 따라 고객님의 개인정보를 안전하게 보관하고 있어요. 자세한 정보를 보고싶으시다면 아래 버튼 링크를 통해 확인해 주세요.',
    linkTitle: '개인정보 취급 방침>',
    link: '/privacy#5',
  },
];

const DELETE_REASON = [
  '더 이상 사용하지 않아요',
  '서비스 사용이 어려워요',
  '서비스에 오류가 많아요',
  '알림이 너무 자주 울려요',
  '비슷한 다른 서비스를 이용하고 있어요',
  '서비스에 대한 신뢰도가 낮아요',
  '수익률이 좋지 않아요',
  '매각이 오랜 기간 되지 않아요',
  '기타이유',
];

const StyledDropDown = styled(Dropdown)<Drop>`
  .Dropdown-control {
    height: 58px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: white;
    border: 1px solid ${(props) => (props.open ? '#FF8A22' : '#ccc')};
    border-radius: 2px;
    box-sizing: border-box;
    color: #353c49;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease;
    z-index: 999;
  }

  .Dropdown-placeholder {
    color: rgba(72, 79, 97, 0.8);
  }

  .Dropdown-placeholder.is-selected {
    color: #ff8a22;
  }

  .Dropdown-menu {
    background: rgba(72, 79, 97, 0.05);
    padding: 20px 0px;
    border: none;

    .Dropdown-option {
      padding: 14px 16px;
      font-size: 14px;
      color: #353c49;
    }

    .Dropdown-option.is-selected {
      color: #ff8a22;
    }
  }

  .is-open > div:first-child > div:last-child > span.Dropdown-arrow {
    border-color: ${(props) =>
      props.open
        ? 'transparent transparent #FF8A22'
        : 'transparent transparent rgba(72, 79, 97, 0.8)'};
  }

  .Dropdown-arrow {
    right: 24px;
    top: 26px;
    border-color: ${(props) =>
        props.open ? '#FF8A22' : 'rgba(72, 79, 97, 0.8)'}
      transparent transparent;
  }
`;

const DeleteFAQ = () => {
  const [current, setCurrent] = useState(-1);

  const onShowCollpase = (i: number) => {
    if (current === i) {
      setCurrent(-1);
    } else {
      setCurrent(i);
    }
  };

  return (
    <DeleteFAQWrapper>
      {DELETE_FAQ_CONTENT.map((v, i) => (
        <DeleteFAQContentWrapper key={i}>
          <DeleteFAQTitle onClick={() => onShowCollpase(i)}>
            {v.title}
          </DeleteFAQTitle>
          <DeleteFAQContent className={current === i ? 'act' : undefined}>
            <DeleteWrapperDesc>{v.content}</DeleteWrapperDesc>
            <br />
            <StyledLink to={v.link}>{v.linkTitle}</StyledLink>
          </DeleteFAQContent>
        </DeleteFAQContentWrapper>
      ))}
    </DeleteFAQWrapper>
  );
};

const DeleteService = ({ reason, handleChange }: DeleteServiceProps) => {
  const history = useHistory();
  return (
    <>
      <DeleteContainer>
        <DeleteWrapper>
          <DeleteWrapperTitle>트레져러를 탈퇴하시나요?</DeleteWrapperTitle>
          <DeleteWrapperDesc
            style={{ marginTop: 12, marginBottom: 20, display: 'inline-block' }}
          >
            회원 탈퇴 전 확인해주세요
          </DeleteWrapperDesc>
          <DeleteFAQ />
          <div style={{ marginBottom: 20 }}>
            <DeleteWrapperDesc>
              서비스 사용 중 무엇이 불편했나요?
            </DeleteWrapperDesc>
          </div>
          <StyledDropDown
            open={!!reason}
            options={DELETE_REASON}
            onChange={handleChange}
            // value={DELETE_REASON[0]}
            placeholder="선택해 주세요"
          />
          <DeleteButton
            disabled={!reason}
            style={{ marginTop: 210 }}
            onClick={() => history.push('/delete/confirm')}
          >
            다음
          </DeleteButton>
        </DeleteWrapper>
      </DeleteContainer>
    </>
  );
};

export default DeleteService;
