import styled from 'styled-components';
import palette from '@/config/styles/color/Color';
import FullPageModal from '@/Components/Modal/FullPageModal';
import { ReactComponent as ArrowBack } from '../../assets/images/ArrowBack.svg';
import searchIcon from '../../assets/images/search_icon.svg';
import { getMarketItemList } from '@/fetcher/ItemQueries';
import { useEffect, useState } from 'react';
import MarketItemTable from './MarketItemTable';
import { MarketItemResponseData } from '@treasurer/common';
import { useIsTablet } from '@/utils/customHook/useIsMobile';

const Container = styled.div`
  max-width: 792px;
  margin: auto;
`;

const Header = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
`;

const SearchInput = styled.input`
  border-radius: 40px;
  background: ${palette.ghost[4]};
  padding: 6px 6px 6px 20px;
  margin-left: 20px;
  color: #9d9d9d;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  line-height: normal;
`;

const SearchButton = styled.div`
  border-radius: 36px;
  background: ${palette.ghost[3]};
  padding: 6px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor: pointer;
`;

const HorizontalLine = styled.div`
  border-top: 1px;
  border: 1px solid #f2f4f6;
`;

const KeywordSuggestion = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${palette.black[0]};
  padding: 16px;

  display: flex;
  flex-wrap: wrap;
  gap: 16px 8px;

  h2 {
    width: 100%;
    font-weight: 500;
  }
`;

const KeywordCard = styled.button`
  background: ${palette.ghost[4]};
  border-radius: 6px;
  padding: 8px 10px;
  color: ${palette.gray[0]};
`;

interface SearchModalProps {
  isSearchModalOpen: boolean;
  setIsSearchModalOpen: (value: boolean) => void;
}

const SearchModal = ({
  isSearchModalOpen,
  setIsSearchModalOpen,
}: SearchModalProps) => {
  const { data: marketItemData } = getMarketItemList();

  const [inputValue, setInputValue] = useState('');
  const [filteredResult, setFilteredResult] = useState<
    MarketItemResponseData[] | undefined
  >([]);

  const filterMarketData = (keyword: string) => {
    const keywords = keyword.toLowerCase().split(' ');
    return marketItemData?.filter((item) =>
      keywords.every(
        (keyword: string) =>
          item.brandKR.includes(keyword) ||
          item.modelKR.includes(keyword) ||
          item.ticker.toLowerCase().includes(keyword),
      ),
    );
  };

  useEffect(() => {
    const filteredInputValue = inputValue.slice(0, -1);

    const filteredResult = filterMarketData(filteredInputValue);

    setFilteredResult(filteredResult);

    const timer = setTimeout(() => {
      filterMarketData(inputValue);
    }, 1000);
    return () => clearTimeout(timer);
  }, [inputValue]);

  const keywordList = [
    '데이토나',
    '서브마리너',
    '롤렉스',
    '샤넬 클래식',
    '로마네꽁띠',
    '그랑에세조',
  ];

  const isTablet = useIsTablet();
  const isWeb = !isTablet;

  return (
    <FullPageModal modalOpen={isSearchModalOpen}>
      <Container style={{ marginTop: isWeb ? 60 : 0 }}>
        <Header>
          <ArrowBack
            style={{ cursor: 'pointer', width: '24px' }}
            onClick={() => setIsSearchModalOpen(false)}
          />
          <SearchInput
            placeholder="브랜드, 모델명, 티커를 검색해보세요"
            onChange={(e) => setInputValue(e.target.value)}
          />
          <SearchButton>
            <img src={searchIcon} alt="검색" />
          </SearchButton>
        </Header>

        <HorizontalLine />

        <MarketItemTable marketItems={filteredResult} />

        {/* <KeywordSuggestion>
          <h2>추천 검색</h2>
          {keywordList.map((item, index) => {
            return <KeywordCard key={`keyword-${index}`}>{item}</KeywordCard>;
          })}
        </KeywordSuggestion> */}
      </Container>
    </FullPageModal>
  );
};

export default SearchModal;
