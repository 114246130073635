import isNil from 'lodash/isNil';

import auth from '../../apis/auth';
import user from '../../apis/user';
import { localSetItem, localGetItem } from '../../utils/handleStorage';

export const login = (email, password) => async () => {
  try {
    const result = await auth.login(email, password);
    if (result?.data?.data) {
      await localSetItem('session', result?.data?.data?.accessToken, 20160);
      return result;
    }
    return result;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error('login err', e);
    return null;
  }
};

export const getUserAccessToken = async (dispatch, getState) => {
  const { userAuth } = getState().user;

  if (!isNil(userAuth.accessToken)) {
    return userAuth.accessToken;
  }

  try {
    const accessToken = await localGetItem('session');

    return accessToken;
  } catch (e) {
    // 데이터 검색 오류
    if (typeof window !== 'undefined') window.console.error(e);
    return null;
  }
};

// dispatch(action)으로 액션을 급파보낸다 > reducer 작동
export const requestAuthCode = (phoneNumber) => async () => {
  try {
    const result = await auth.requestAuthCode(phoneNumber);
    return result.data;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error(e);
    return null;
  }
};

export const saveBank = (bankInfo) => async () => {
  try {
    const result = await user.saveBankInfo(bankInfo);
    return result;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error(e);
    return null;
  }
};

// klip

export const klipPrepare = () => async () => {
  try {
    const result = await user.klipPrepare();
    return result.data;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error(e);
    return null;
  }
};

export const klipResult = (key) => async () => {
  try {
    const result = await user.klipResult(key);
    return result.data;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error(e);
    return null;
  }
};
