import { useEffect, useState } from 'react';
import isNil from 'lodash/isNil';
import Loading from '../Components/Loading';
import { item } from '../config/api';
import { MainContainer } from '../config/style';

const Upcoming = (props) => {
  const [loading, setLoading] = useState(false);
  const { history } = props;

  let recentItem;
  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    const _recentTradeItemPush = async () => {
      setLoading(true);
      try {
        const result = await item.getUpcomingItems();
        if (!isNil(result?.data?.data?.id)) {
          recentItem = result?.data?.data?.id;
          history.push(`/item/${recentItem}`);
        } else history.push('/');
      } catch (e) {
        if (typeof window !== 'undefined') window.console.error(e);
      } finally {
        setLoading(false);
      }
    };

    _recentTradeItemPush();
  }, []);

  return <MainContainer>{loading && <Loading />}</MainContainer>;
};

export default Upcoming;
