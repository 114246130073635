import styled from 'styled-components';
import palette from '../../config/styles/color/Color';

const TabMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  gap: 10px;
`;

const PillButton = styled.div<{ active: boolean }>`
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  padding: 9px 16px;
  color: ${(props) => (props.active ? '#fff' : '#333')};
  background-color: ${(props) =>
    props.active ? `${palette.orangeB[0]}` : '#F7F7F7'};
  cursor: pointer;
`;

type PillButtonTabMenuProps<T extends string | number> = {
  tabMenuComposition: { key: T; label: string }[];
  selectedTab: T;
  setSelectedTab: React.Dispatch<React.SetStateAction<T>>;
};

const PillButtonTabMenu = <T extends string | number>({
  tabMenuComposition,
  selectedTab,
  setSelectedTab,
}: PillButtonTabMenuProps<T>) => {
  const handleButtonClick = (selected: T) => {
    setSelectedTab(selected);
  };

  return (
    <TabMenuContainer>
      {tabMenuComposition.map(({ key, label }) => (
        <PillButton
          key={key}
          active={selectedTab === key}
          onClick={() => handleButtonClick(key)}
        >
          {label}
        </PillButton>
      ))}
    </TabMenuContainer>
  );
};

export default PillButtonTabMenu;
