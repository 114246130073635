import styled from 'styled-components';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import {
  VoteContainer,
  VoteDate,
  DisplayColumn,
  MiddleGrayTxt,
  VoteCriteria,
  VoteRate,
  LargeBoldTxt,
  VoteProgress,
  SmallGrayTxt,
} from './VoteStyle';
import { SpaceBetween } from '../../config/style';
import {
  checkExistingUserVote,
  getVoteItemByProposalId,
} from '@/fetcher/voteQueries';
import AppLink from '@/Components/AppLink';
import { RouteComponentProps } from 'react-router-dom';
import { ReactComponent as ActionCompleteImage } from '../../assets/images/actionComplete.svg';
import { ReactComponent as VoteCheck } from '../../assets/images/check_vote.svg';
import { FlexDiv } from '@/Components/Home/Mobile/HomeMobileNotice/HomeMobileNoticeStyle';
import { NAVIGATION_TABS } from '@treasurer/common';

interface MatchParams {
  proposalId: number;
}

function ProposalVoteResult({ match }: RouteComponentProps<MatchParams>) {
  const { proposalId } = match.params;

  const { data: voteItemData, isLoading: isVoteItemDataLoading } =
    getVoteItemByProposalId(proposalId);
  const { data: existingUserVote, isLoading: isCheckExistingUserVoteLoading } =
    checkExistingUserVote(proposalId);

  if (isVoteItemDataLoading || isCheckExistingUserVoteLoading) return null;

  const voteData = voteItemData.voteData;
  const itemData = voteItemData.itemData;
  const voteAllPiece = itemData.totalPieceCount;
  const turnout = voteData.yes + voteData.no;
  const voteRate = (turnout / voteAllPiece) * 100;

  const isVoteExpired =
    voteData.voteCategory === 'PROPOSAL_ACCEPTED' ||
    voteData.voteCategory === 'PROPOSAL_REJECTED';

  const chartState = {
    series: [turnout, voteAllPiece - turnout],
    options: {
      chart: {
        type: 'donut' as 'donut',
        witdh: '106px',
        height: '106px',
      },
      fill: {
        colors: ['#FFA41B', 'rgba(255, 164, 27, 0.5)'],
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      events: {
        click: undefined,
      },
      tooltip: {
        enabled: false,
      },
      states: {
        normal: {
          filter: {
            type: 'none',
          },
        },
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: false,
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
      },
    },
  };

  return (
    <VoteContainer>
      <ActionCompleteTitleSection>
        <ActionCompleteImage
          style={{ display: 'block', margin: '0 auto', width: '90px' }}
        />

        {isVoteExpired ? (
          <h1>
            투표기간이 지나
            <br />
            매각 희망가 투표가
            <br />
            종료되었습니다.
          </h1>
        ) : (
          <h1>
            매각 희망가 투표가
            <br />
            완료되었습니다.
          </h1>
        )}
      </ActionCompleteTitleSection>

      {/* <div style={{ margin: '18px 0px' }}>
        <SpaceBetween>
          <div style={{ width: '80%' }}>
            <VoteTitle>{itemData.brand}</VoteTitle>
            <VoteTitle>{itemData.model}</VoteTitle>
            <VoteKorTitle>
              {itemData.brandKR} {itemData.modelKR}
            </VoteKorTitle>
          </div>
          <VoteImg src={itemData.thumbnail} alt="투표 아이템 이미지" />
        </SpaceBetween>
      </div> */}

      <VoteDate>
        투표기간: {moment(voteData.endDate).format('YYYY-MM-DD HH:mm')} 까지
      </VoteDate>

      <DisplayColumn style={{ marginTop: 15 }}>
        <div id="chart">
          <ReactApexChart
            options={chartState.options}
            series={chartState.series}
            type="donut"
            width="140px"
            height="140px"
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <VoteCriteria>
            투표율 {moment(voteData.updatedAt).format('YYYY-MM-DD HH:mm')} 기준
          </VoteCriteria>
        </div>

        <VoteRate>
          {voteRate.toFixed(2)}
          <span>%</span>
        </VoteRate>
      </DisplayColumn>

      <div style={{ marginTop: 32, display: 'flex' }}>
        <DisplayColumn
          style={{
            borderRight: '1px solid rgba(72, 79, 97, 0.4)',
            width: '100%',
          }}
        >
          <SpaceBetween
            style={{
              width: '100%',
              paddingRight: '16px',
              textAlign: 'left',
            }}
          >
            <MiddleGrayTxt>유권자</MiddleGrayTxt>
            <span>{voteData.ownerCnt.toLocaleString()} 명</span>
          </SpaceBetween>
          <SpaceBetween
            style={{
              width: '100%',
              paddingRight: '16px',
              marginTop: 25,
              textAlign: 'left',
            }}
          >
            <MiddleGrayTxt style={{ marginRight: 10 }}>투표수</MiddleGrayTxt>
            <span>{voteData.voteCnt.toLocaleString()} 명</span>
          </SpaceBetween>
        </DisplayColumn>
        <DisplayColumn
          style={{
            width: '100%',
            marginLeft: 16,
          }}
        >
          <SpaceBetween
            style={{
              width: '100%',
              textAlign: 'left',
            }}
          >
            <MiddleGrayTxt>전체소유권</MiddleGrayTxt>
            <span>{voteAllPiece.toLocaleString()} 개</span>
          </SpaceBetween>
          <SpaceBetween
            style={{
              width: '100%',
              marginTop: 25,
              textAlign: 'left',
            }}
          >
            <MiddleGrayTxt style={{ marginRight: 10 }}>
              투표소유권
            </MiddleGrayTxt>
            <span>{turnout.toLocaleString()} 개</span>
          </SpaceBetween>
        </DisplayColumn>
      </div>
      <div style={{ marginTop: 30 }}>
        <SpaceBetween>
          <LargeBoldTxt>
            {isNaN(voteData.no / turnout)
              ? '0%'
              : `${((voteData.no / turnout) * 100).toFixed(2)}%`}
          </LargeBoldTxt>
          <LargeBoldTxt>
            {isNaN(voteData.yes / turnout)
              ? '0%'
              : `${((voteData.yes / turnout) * 100).toFixed(2)}%`}
          </LargeBoldTxt>
        </SpaceBetween>
        <SpaceBetween style={{ marginTop: 8 }}>
          <FlexDiv>
            <MiddleGrayTxt>반대</MiddleGrayTxt>
            {existingUserVote.voteOption === 2 && (
              <VoteCheck style={{ marginRight: '4px' }} />
            )}
          </FlexDiv>
          <FlexDiv>
            {existingUserVote.voteOption === 1 && (
              <VoteCheck style={{ marginRight: '4px' }} />
            )}
            <MiddleGrayTxt>찬성</MiddleGrayTxt>
          </FlexDiv>
        </SpaceBetween>
        <div style={{ marginTop: 8 }}>
          {turnout === 0 ? (
            <VoteProgress
              style={{ width: '100%', background: 'rgba(72, 79, 97, 0.1)' }}
            />
          ) : (
            <div style={{ display: 'flex' }}>
              <VoteProgress
                style={{
                  width: `${(voteData.no / turnout) * 100}%`,
                  background: '#213E85',
                  marginRight: 1,
                  borderRadius: '4px 0px 0px 4px',
                }}
              />
              <VoteProgress
                style={{
                  width: `${(voteData.yes / turnout) * 100}%`,
                  background: '#FF8A22',
                }}
              />
            </div>
          )}
        </div>
        <SpaceBetween
          style={{
            marginTop: 8,
          }}
        >
          <SmallGrayTxt>{voteData.no.toLocaleString()}개</SmallGrayTxt>
          <SmallGrayTxt>
            개수 차 {Math.abs(voteData.yes - voteData.no).toLocaleString()}
          </SmallGrayTxt>
          <SmallGrayTxt>{voteData.yes.toLocaleString()}개</SmallGrayTxt>
        </SpaceBetween>
      </div>

      <AppLink to={NAVIGATION_TABS.MY} notItemPage>
        <FloatingButton>확인</FloatingButton>
      </AppLink>
    </VoteContainer>
  );
}

export default ProposalVoteResult;

const ActionCompleteTitleSection = styled.div`
  h1 {
    color: #060a10;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 20px;
  }
`;

const FloatingButton = styled.div`
  background: #fe8824;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 5px;
  padding: 17px;
  text-align: center;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  width: 92%;
  max-width: 490px;
  cursor: pointer;
`;
