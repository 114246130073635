import { localGetItem } from '../../utils/handleStorage';
import { useUserInfo } from '@/Queries/userQueries';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isNil } from 'lodash';
import NotFound from '@/Routes/NotFound';
import LinkedVirtualAccountDepositPage from './LinkedVirtualAccountDepositPage';
import { BankCode } from '@/config/banks';
import VirtualAccountRegistrationIntroduceScreen from './VirtualAccountRegistrationIntroduceScreen';
import Loading from '../Loading';
import styled from 'styled-components';

const VirtualAccountPageLinker = () => {
  const history = useHistory();

  useEffect(() => {
    const token = localGetItem('session');

    if (!token) {
      history.push('/signin');
    }
  }, []);

  const { data: userData, isLoading: isUserInfoLoading } = useUserInfo();

  if (isUserInfoLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  const user = userData?.data;

  return (
    <>
      {isNil(user) ? (
        <NotFound />
      ) : !!user.virtualAccountNumber ? (
        <LinkedVirtualAccountDepositPage />
      ) : (
        <VirtualAccountRegistrationIntroduceScreen userInfo={user} />
      )}
    </>
  );
};

export default VirtualAccountPageLinker;

const Container = styled.div`
  margin-top: 50px;
`;
