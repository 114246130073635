import styled from 'styled-components';
import ReactLoading from 'react-loading';

interface StyledButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  isProcessing?: boolean;
  style?: React.CSSProperties;
}

const StyledButton: React.FC<StyledButtonProps> = ({
  children,
  onClick,
  disabled,
  isProcessing,
  style,
}) => {
  return (
    <StyledBaseButton
      style={style}
      onClick={isProcessing ? () => {} : onClick}
      disabled={disabled}
      isProcessing={isProcessing}
    >
      {isProcessing ? (
        <ReactLoading type="spin" color="#fff" height={30} width={30} />
      ) : (
        <ButtonText disabled={disabled}>{children}</ButtonText>
      )}
    </StyledBaseButton>
  );
};

export default StyledButton;

const StyledBaseButton = styled.button<{
  disabled?: boolean;
  isProcessing?: boolean;
}>`
  background-color: ${({ disabled }) => (disabled ? '#DDDDDD' : '#FE8824')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  border-radius: 8px;
  width: 100%;
  cursor: ${({ disabled, isProcessing }) =>
    disabled || isProcessing ? 'default' : 'pointer'};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  min-height: 58px;
`;

const ButtonText = styled.span<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? '#9C9C9C' : '#FFF')};
  font-size: 16px;
  font-weight: 700;
`;
