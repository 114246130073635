import { useEffect, useState, MutableRefObject } from 'react';
import styled from 'styled-components';
import { item } from '../config/api';
import { DisposalItemTypes, DisposalItemTypesArray } from '../types/ItemTypes';
import DisposalComponent from '../Components/item/DisposalComponent';
import ReactHelmet from '../Components/ReactHelmet';
import { useIsTablet } from '@/utils/customHook/useIsMobile';
import FullLoading from '@/Components/Loading/FullLoading';
import { getDisposalList } from '@/fetcher/useQueries';

interface DisposalProps {
  isRef?: MutableRefObject<HTMLElement | null>;
}

const DisposalAllContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  @media (max-width: 1024px) {
    padding: 0px;
  }
`;

const DisposalContainer = styled.div`
  width: 1036px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const DisposalTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

const DisposalOneSideContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 29px;

  @media (max-width: 1024px) {
    margin-bottom: 24px;
    padding: 6px 20px;
  }
`;

const DisposalOneSideWrapper = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;

    div:nth-of-type(2n) {
      margin-right: 0px;
    }
  }
`;
const DisposalOneSideItemInnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
`;
const DisposalOneSideItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 196px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 3px 4px 10px rgba(72, 79, 97, 0.2);
  border-radius: 12px;
  display: grid;
  grid-template-rows: 1fr 2fr;
  @media (max-width: 768px) {
    justify-content: center;
    width: 48%;
    padding: 14px 8px 24px 8px;
    min-height: 130px;
    display: flex;
    flex-direction: column;
  }
`;

const DisposalOneSideItemTitle = styled.span`
  font-size: 16px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
const DisposalOneSideItemTitleRightSide = styled(DisposalOneSideItemTitle)`
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const DisposalOneSideItemDesc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DisposalOneSideItemDescTwoRow = styled(DisposalOneSideItemDesc)`
  row-gap: 14px;
`;
const DisposalOneSideItemDescThreeRow = styled(DisposalOneSideItemDesc)`
  row-gap: 5px;
`;

const DisposalOneSideItemDescItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DisposalOneSideItemRegular = styled.p`
  font-size: 16px;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const DisposalItemListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
`;

const DisposalWebView = ({ isRef }: DisposalProps) => {
  const [investList, setInvestList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { data: disposalList, isLoading: isLoadingDisposalListData } =
    getDisposalList();

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      setLoading(true);
      try {
        const invest = await item.getItemCompList();
        setInvestList(invest);
      } catch (e) {
        window?.console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  const isTablet = useIsTablet();

  if (isLoadingDisposalListData || !disposalList) {
    return <div style={{ height: '100vh' }} />;
  }

  const efficientDisposalList = disposalList.filter((v) => v.status !== 407);
  const [votingList, disposalProgressList, disposalCompleteList] =
    efficientDisposalList.reduce<
      [DisposalItemTypes[], DisposalItemTypes[], DisposalItemTypes[]]
    >(
      (acc, cur) => {
        if (cur.status === 401) acc[0].push(cur);
        else if (cur.status === 402) acc[1].push(cur);
        else if (cur.status === 403) acc[2].push(cur);
        return acc;
      },
      [[], [], []],
    );

  const yieldRating: number[] = [];
  const period: number[] = [];
  const annualized: number[] = [];
  for (const disposalCompleted of disposalCompleteList) {
    const startDate: Date = new Date(String(disposalCompleted.startDate));
    const saleDate: Date = new Date(String(disposalCompleted.saleDate));
    // const endDate: Date = new Date(String(r.endDate));

    const elapsedMSec: number = saleDate.getTime() - startDate.getTime(); // 172800000
    const elapsedDay: number = elapsedMSec / 1000 / 60 / 60 / 24;

    if (disposalCompleted.finalReturn) {
      yieldRating.push(parseFloat(disposalCompleted.finalReturn));
      annualized.push(
        (Number(disposalCompleted.finalReturn) / 100 / elapsedDay) * 365 * 100,
      );
    }

    if (disposalCompleted.saleDate) period.push(elapsedDay);
  }

  const avgPeriod = period.reduce((a, b) => a + b, 0) / period.length;
  const avgYieldRating =
    yieldRating.reduce((a, b) => a + b, 0) / yieldRating.length;
  const avgAnnualized =
    annualized.reduce((a, b) => a + b, 0) / annualized.length;

  const metaData = {
    title: '매각현황 - 트레져러',
    description: '조각 상품 매각 후 시세차익을 통해 수익이 배분된 상품이예요',
    image:
      'https://treasurer-source.s3.ap-northeast-2.amazonaws.com/trade-item-image/og_image.png',
    canonical: `/soldout`,
  };

  if (loading || !disposalList.length) return <FullLoading />;

  return (
    <DisposalAllContainer ref={isRef} id="disposal">
      <ReactHelmet data={metaData} />
      <DisposalContainer>
        <DisposalOneSideContainer>
          <DisposalTitle style={{ paddingTop: '8px', paddingBottom: '14px' }}>
            매각 한 눈에 보기
          </DisposalTitle>
          <DisposalOneSideWrapper>
            <DisposalOneSideItemWrapper>
              <DisposalOneSideItemInnerWrapper>
                <DisposalOneSideItemTitle>상품</DisposalOneSideItemTitle>
                {/* <Storefront /> */}
              </DisposalOneSideItemInnerWrapper>
              <DisposalOneSideItemDescTwoRow>
                <DisposalOneSideItemDescItem>
                  <DisposalOneSideItemRegular>
                    총 조각판매 상품
                  </DisposalOneSideItemRegular>
                  <DisposalOneSideItemTitleRightSide>
                    {investList.length} 개
                  </DisposalOneSideItemTitleRightSide>
                </DisposalOneSideItemDescItem>
                <DisposalOneSideItemDescItem>
                  <DisposalOneSideItemRegular>
                    매각 완료된 상품
                  </DisposalOneSideItemRegular>
                  <DisposalOneSideItemTitleRightSide>
                    {disposalCompleteList.length} 개
                  </DisposalOneSideItemTitleRightSide>
                </DisposalOneSideItemDescItem>
              </DisposalOneSideItemDescTwoRow>
            </DisposalOneSideItemWrapper>
            <DisposalOneSideItemWrapper>
              <DisposalOneSideItemInnerWrapper>
                <DisposalOneSideItemTitle>진행중</DisposalOneSideItemTitle>
                {/* <QuestionAnswerOutlined /> */}
              </DisposalOneSideItemInnerWrapper>
              <DisposalOneSideItemDescTwoRow>
                <DisposalOneSideItemDescItem>
                  <DisposalOneSideItemRegular>
                    투표진행
                  </DisposalOneSideItemRegular>
                  <DisposalOneSideItemTitleRightSide>
                    {votingList.length} 개
                  </DisposalOneSideItemTitleRightSide>
                </DisposalOneSideItemDescItem>
                <DisposalOneSideItemDescItem>
                  <DisposalOneSideItemRegular>
                    매각진행
                  </DisposalOneSideItemRegular>
                  <DisposalOneSideItemTitleRightSide>
                    {disposalProgressList.length} 개
                  </DisposalOneSideItemTitleRightSide>
                </DisposalOneSideItemDescItem>
              </DisposalOneSideItemDescTwoRow>
            </DisposalOneSideItemWrapper>
            <DisposalOneSideItemWrapper>
              <DisposalOneSideItemInnerWrapper>
                <DisposalOneSideItemTitle>수익률</DisposalOneSideItemTitle>
                {/* <Timeline /> */}
              </DisposalOneSideItemInnerWrapper>
              <DisposalOneSideItemDescThreeRow>
                <DisposalOneSideItemDescItem>
                  <DisposalOneSideItemRegular>
                    평균 수익률
                  </DisposalOneSideItemRegular>
                  <DisposalOneSideItemTitleRightSide>
                    {avgYieldRating.toFixed(1)} %
                  </DisposalOneSideItemTitleRightSide>
                </DisposalOneSideItemDescItem>
                <DisposalOneSideItemDescItem>
                  <DisposalOneSideItemRegular>
                    최고 수익률
                  </DisposalOneSideItemRegular>
                  <DisposalOneSideItemTitleRightSide>
                    {Math.max(...yieldRating).toFixed(1)} %
                  </DisposalOneSideItemTitleRightSide>
                </DisposalOneSideItemDescItem>
                <DisposalOneSideItemDescItem>
                  <DisposalOneSideItemRegular>
                    연환산 수익률
                  </DisposalOneSideItemRegular>
                  <DisposalOneSideItemTitleRightSide
                    style={{ color: '#FF8A22' }}
                  >
                    {avgAnnualized.toFixed(1)} %
                  </DisposalOneSideItemTitleRightSide>
                </DisposalOneSideItemDescItem>
              </DisposalOneSideItemDescThreeRow>
            </DisposalOneSideItemWrapper>
            <DisposalOneSideItemWrapper>
              <DisposalOneSideItemInnerWrapper>
                <DisposalOneSideItemTitle>보유기간</DisposalOneSideItemTitle>
                {/* <CalendarTodayOutlined /> */}
              </DisposalOneSideItemInnerWrapper>
              <DisposalOneSideItemDescTwoRow>
                <DisposalOneSideItemDescItem>
                  <DisposalOneSideItemRegular>
                    평균 보유기간
                  </DisposalOneSideItemRegular>
                  <DisposalOneSideItemTitleRightSide>
                    {Math.floor(avgPeriod)} 일
                  </DisposalOneSideItemTitleRightSide>
                </DisposalOneSideItemDescItem>
                <DisposalOneSideItemDescItem>
                  <DisposalOneSideItemRegular>
                    최저 보유기간
                  </DisposalOneSideItemRegular>
                  <DisposalOneSideItemTitleRightSide>
                    {Math.min(...period)} 일
                  </DisposalOneSideItemTitleRightSide>
                </DisposalOneSideItemDescItem>
              </DisposalOneSideItemDescTwoRow>
            </DisposalOneSideItemWrapper>
          </DisposalOneSideWrapper>
        </DisposalOneSideContainer>
      </DisposalContainer>
      <div
        style={{
          background: 'rgba(72, 79, 97, 0.1)',
          padding: isTablet ? '20px' : '0px',
          width: '100%',
          //   paddingTop: "20px",
        }}
      >
        <div
          style={{
            width: isTablet ? '100%' : '1036px',
            margin: 'auto',
            paddingTop: '4px',
          }}
        >
          <DisposalTitle
            style={{
              paddingTop: !isTablet ? '16px' : '0',
              paddingBottom: !isTablet ? '18px' : '0',
            }}
          >
            매각 현황
          </DisposalTitle>
          <DisposalItemListContainer>
            {efficientDisposalList.map((v: DisposalItemTypes) => (
              <DisposalComponent key={v.id} item={v} />
            ))}
          </DisposalItemListContainer>
        </div>
      </div>
    </DisposalAllContainer>
  );
};

export default DisposalWebView;
