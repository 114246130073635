import axios from '../apis/axios-instance';
import {
  ApiResult,
  GetHomeData,
  bannerMapKey,
  Notice,
  News,
  ItemCompList,
  DisposalList,
  ItemComp,
  itemCompListMapKey,
  BannerInterface,
} from '../types/Home/Types';

const getHomeDataFetcher = async () => {
  const { data } = await axios.get<ApiResult<GetHomeData>>(
    `/api/treasurerWeb/home`,
  );
  const bannerMap: {
    [key in bannerMapKey]: BannerInterface[];
  } = {
    web: [],
    mobile: [],
    popup: [],
    bottom_w: [],
    bottom_m: [],
    middle: [],
  };
  for (let i = 0; i < data.data.banner.length; i += 1) {
    const curData = data.data.banner[i];
    const imageSrc = curData.image;
    if (imageSrc) {
      const fileName = imageSrc.substring(imageSrc.lastIndexOf('/') + 1);
      if (fileName.includes('_web')) bannerMap.web.push(curData);
      else if (fileName.includes('mobile')) bannerMap.mobile.push(curData);
      else if (fileName.includes('popup')) bannerMap.popup.push(curData);
      else if (fileName.includes('bottom_w')) bannerMap.bottom_w.push(curData);
      else if (fileName.includes('bottom_m')) bannerMap.bottom_m.push(curData);
      else if (fileName.includes('middle')) bannerMap.middle.push(curData);
    }
  }
  return { ...data.data, banner: bannerMap };
};
const getNoticeFetcher = async () => {
  const { data } = await axios.get<ApiResult<Notice[]>>(
    `/api/treasurerWeb/home/notice`,
  );
  return data.data;
};
const getNewsFetcher = async () => {
  const { data } = await axios.get<ApiResult<News[]>>(
    `/api/treasurerWeb/home/news`,
  );
  return data.data;
};
const getItemCompListFetcher = async () => {
  const { data } = await axios.get<ApiResult<ItemCompList>>(
    `/api/treasurerWeb/items/compression`,
  );
  const itemCompListMap: { [key in itemCompListMapKey]: ItemComp[] } = {
    marketList: [],
    readyList: [],
    openList: [],
    soldList: [],
  };
  for (let i = 0; i < data.data.length; i += 1) {
    const curItem = data.data[i];
    if (curItem.status === 201 || curItem.status === 202)
      itemCompListMap.marketList.push(curItem);
    else if (curItem.status === 1) itemCompListMap.readyList.push(curItem);
    else if (curItem.status === 101) itemCompListMap.openList.push(curItem);
    else if (curItem.status === 102) itemCompListMap.soldList.push(curItem);
  }
  return itemCompListMap;
};

const getDisposalListFetcher = async () => {
  const { data } = await axios.get<ApiResult<DisposalList>>(
    `/api/treasurerWeb/items/disposal`,
  );
  return data.data;
};

export {
  getHomeDataFetcher,
  getNoticeFetcher,
  getNewsFetcher,
  getItemCompListFetcher,
  getDisposalListFetcher,
};
