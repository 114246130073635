import DefaultEventLandingPage from './DefaultEventLandingPage';
import goldEventDetails from '../../assets/images/event/goldEvent2408.png';

function GoldEventLandingPage() {
  return (
    <DefaultEventLandingPage
      eventDetailImageSrc={goldEventDetails}
      eventName="8월 금 이벤트"
      link={'/item/1001'}
      buttonText={'이벤트 참여하기'}
      buttonColor={'#FFD93E'}
      buttonTextColor={'#383737'}
    />
  );
}

export default GoldEventLandingPage;
