import { BankCode, BankName, banks } from '@/config/banks';
import bankLogoCircle from '../assets/images/bankLogoCircle.png';

export const getBankNameByCode = (bankCode: BankCode): BankName | undefined => {
  for (let [key, value] of Object.entries(banks)) {
    if (value === bankCode) {
      return key as BankName;
    }
  }
  return undefined;
};

export const bankSpritePosition: { [key in BankCode]: number } = {
  '034': 0, // 광주은행
  '037': 0, // 전북은행
  '011': 1, // NH농협은행
  '027': 2, // 씨티은행
  '032': 3, // 부산은행
  '039': 3, // 경남은행
  '002': 4, // 산업은행
  '088': 5, // 신한은행
  '035': 5, // 제주은행
  '048': 6, // 신협은행
  '071': 7, // 우체국
  '081': 8, // 하나은행
  '023': 9, // SC제일은행
  '090': 10, // 카카오뱅크
  '089': 11, // 케이뱅크
  '003': 12, // IBK기업은행
  '007': 13, // 수협은행
  '092': 14, // 토스뱅크
  '004': 15, // 국민은행
  '045': 16, // 새마을금고
  '031': 17, // 대구은행
  '020': 18, // 우리은행
};

const logosPerRow = 5;
const logoWidth = 44;
const logoHeight = 44;

interface BankLogoProps {
  bankCode: BankCode;
}

export const getBankLogo = ({ bankCode }: BankLogoProps) => {
  const index = bankSpritePosition[bankCode];

  const backgroundX = `-${(index % logosPerRow) * logoWidth}px`;
  const backgroundY = `-${Math.floor(index / logosPerRow) * logoHeight}px`;

  return (
    <div
      style={{
        width: `${logoWidth}px`,
        height: `${logoHeight}px`,
        backgroundImage: `url(${bankLogoCircle})`,
        backgroundPosition: `${backgroundX} ${backgroundY}`,
        backgroundSize: 'auto',
      }}
    />
  );
};
