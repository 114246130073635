import auth from '../../apis/auth';

import user from '../../apis/user';
import withdrawAndDeposit from '../../apis/withdrawAndDeposit';
import * as types from '../constants/ActionTypes';

const createNewUser = (newUser) => ({
  type: types.CREATE_NEW_USER,
  newUser,
});

const reSendEmailUserAction = (resend) => ({
  type: types.RESEND_EMAIL,
  resend,
});

const receiveUserInfoAction = (userInfo) => ({
  type: types.RECEIVE_USERINFO,
  userInfo,
});

const receiveUserBalanceAction = (userBalance) => ({
  type: types.RECEIVE_USER_BALANCE,
  userBalance,
});

const requestUserDepositAction = (userRequestDeposit) => ({
  type: types.REQUEST_USER_DEPOSIT,
  userRequestDeposit,
});

const requestUserWithdrawAction = (userRequestWithdraw) => ({
  type: types.REQUEST_USER_WITHDRAW,
  userRequestWithdraw,
});

const userBuyPieceAction = (userBuyPiece) => ({
  type: types.BUY_USER_PIECE,
  userBuyPiece,
});

const userBuyPieceWithCardAction = (userBuyPieceWithCard) => ({
  type: types.BUY_USER_PIECE_WITH_CARD,
  userBuyPieceWithCard,
});

const userOwnershipAction = (userOwnership) => ({
  type: types.USER_OWNERSHIP,
  userOwnership,
});

const requestKycCodeAction = (requestCode) => ({
  type: types.REQUEST_KYC_CODE,
  requestCode,
});

const confirmAuthCode = (confirmCode) => ({
  type: types.CONFIRM_KYC_CODE,
  confirmCode,
});

const userOwnershipDetailAction = (ownershipDetail) => ({
  type: types.OWNERSHIP_DETAIL,
  ownershipDetail,
});

const userBuyPieceRequestAction = (buyPieceRequest) => ({
  type: types.BUY_USER_PIECE_REQUEST,
  buyPieceRequest,
});

const userSellPieceRequestAction = (sellPieceRequest) => ({
  type: types.SELL_USER_PIECE_REQUEST,
  sellPieceRequest,
});

const userOrderSellAction = (orderSell) => ({
  type: types.SELL_USER_ORDER,
  orderSell,
});

const userOrderBuyAction = (orderBuy) => ({
  type: types.BUY_USER_ORDER,
  orderBuy,
});

const userOwnBalacneInfomationAction = (ownInformation) => ({
  type: types.GET_OWN_BALANCE_INFOMATION,
  ownInformation,
});

const userGetOrderListAction = (orderList) => ({
  type: types.GET_ORDER_LIST,
  orderList,
});

const userGetOrderHistoryListAction = (orderList) => ({
  type: types.GET_ORDER_HISTORY_LIST,
  orderList,
});

const userGetOrderHistoryAllListAction = (orderList) => ({
  type: types.GET_ORDER_HISTORY_ALL_LIST,
  orderList,
});

const userCancelOrderAction = (cancelOrder) => ({
  type: types.CANCEL_ORDER,
  cancelOrder,
});

export const createUser =
  (email, password, phoneNumber, refereeCode, source, marketing) =>
  async (dispatch) => {
    try {
      const replacePhoneNumber = phoneNumber.replace(/-/gi, '');
      const result = await auth.signUp(
        email,
        password,
        replacePhoneNumber,
        refereeCode,
        source,
        marketing,
      );
      dispatch(createNewUser(result.data.data));

      return result;
    } catch (e) {
      if (typeof window !== 'undefined') window.console.error(e);
      return null;
    }
  };

export const reSendEmail = (email) => async (dispatch) => {
  try {
    const result = await auth.reSendEmailUser(email);
    dispatch(reSendEmailUserAction(result.data.data));

    return result;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error(e);
    return null;
  }
};

// dispatch(action)으로 액션을 급파보낸다 > reducer 작동
export const getUser = () => async (dispatch) => {
  try {
    const result = await user.getUserInfo();

    dispatch(receiveUserInfoAction(result));

    return result;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error(e);
    return null;
  }
};

export const getBalance = () => async (dispatch) => {
  try {
    const result = await user.getUserBalance();

    dispatch(receiveUserBalanceAction(result));

    return result;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error(e);
    return null;
  }
};

export const getItemOwnership = (tradeItemId) => async (dispatch) => {
  try {
    const result = await user.getUserItemOwnership(tradeItemId);

    dispatch(receiveUserBalanceAction(result.data.data));

    return result;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error(e);
    return null;
  }
};

// 입금 확인 요청
export const reqeustDeposit =
  (generateRandomNumber, amount) => async (dispatch) => {
    try {
      const result = await withdrawAndDeposit.requestForDeposit(
        generateRandomNumber,
        amount,
      );

      dispatch(requestUserDepositAction(result.data.data));

      return result;
    } catch (e) {
      if (typeof window !== 'undefined') window.console.error(e);
      return null;
    }
  };

// 입금 확인 요청
export const reqeustWithdraw = (amount) => async (dispatch) => {
  try {
    const result = await withdrawAndDeposit.requestForWithdraw(amount);

    dispatch(requestUserWithdrawAction(result.data.data));

    return result;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error(e);
    return null;
  }
};

export const userBuyPiece =
  (amount, tradeItemId, quantity) => async (dispatch) => {
    try {
      const result = await user.buyPiece(amount, tradeItemId, quantity);

      dispatch(userBuyPieceAction(result.data.data));

      return result;
    } catch (e) {
      if (typeof window !== 'undefined') window.console.error(e);
      return null;
    }
  };

export const userBuyPieceWithCard =
  (userId, tradeItemId, quantity, amount, impUid) => async (dispatch) => {
    try {
      const result = await user.buyPieceWithCard(
        userId,
        tradeItemId,
        quantity,
        amount,
        impUid,
      );

      dispatch(userBuyPieceWithCardAction(result.data.data));

      return result;
    } catch (e) {
      if (typeof window !== 'undefined') window.console.error(e);
      return null;
    }
  };

export const userOwnership = () => async (dispatch) => {
  try {
    const result = await user.getUserOwnership();

    dispatch(userOwnershipAction(result.data.data));

    return result;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error(e);
    return null;
  }
};

export const requestKycCode =
  (name, jumin, mobileCo, phoneNumber) => async (dispatch) => {
    try {
      const result = await auth.userKycRequest(
        name,
        jumin,
        mobileCo,
        phoneNumber,
      );

      dispatch(requestKycCodeAction(result.data));

      return result;
    } catch (e) {
      if (typeof window !== 'undefined') window.console.error('login err', e);
      return null;
    }
  };

export const confirmKycAuthCode =
  (authNum, phoneNumber) => async (dispatch) => {
    try {
      const result = await auth.confirmKycCode(authNum, phoneNumber);

      dispatch(confirmAuthCode(result.data));

      return result;
    } catch (e) {
      if (typeof window !== 'undefined') window.console.error('login err', e);
      return null;
    }
  };

export const getOwnershipDetail = (tradeItemId) => async (dispatch) => {
  try {
    const result = await user.getUserOwnershipDetail(tradeItemId);

    dispatch(userOwnershipDetailAction(result.data.data));

    return result;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error('login err', e);
    return null;
  }
};

export const pieceBuyRequest =
  (tradeItemId, quantity, unitPrice) => async (dispatch) => {
    try {
      const result = await user.pieceBuyRequest(
        tradeItemId,
        quantity,
        unitPrice,
      );

      dispatch(userBuyPieceRequestAction(result.data));

      return result;
    } catch (e) {
      if (typeof window !== 'undefined') window.console.error('sell err', e);
      return null;
    }
  };

export const pieceSellRequest =
  (tradeItemId, quantity, unitPrice) => async (dispatch) => {
    try {
      const result = await user.pieceSellRequest(
        tradeItemId,
        quantity,
        unitPrice,
      );

      dispatch(userSellPieceRequestAction(result.data));

      return result;
    } catch (e) {
      if (typeof window !== 'undefined') window.console.error('sell err', e);
      return null;
    }
  };

export const userOrderSellPiece = (tradeOrderId) => async (dispatch) => {
  try {
    const result = await user.orderSellPiece(tradeOrderId);

    dispatch(userOrderSellAction(result.data));

    return result;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error('sell err', e);
    return null;
  }
};

export const userOrderBuyPiece = (tradeOrderId) => async (dispatch) => {
  try {
    const result = await user.orderBuyPiece(tradeOrderId);

    dispatch(userOrderBuyAction(result.data));

    return result;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error('sell err', e);
    return null;
  }
};

export const userOwnBalanceInformation = (tradeItemId) => async (dispatch) => {
  try {
    const result = await user.ownBalanceInfo(tradeItemId);

    dispatch(userOwnBalacneInfomationAction(result.data));

    return result;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error('balance err', e);
    return null;
  }
};

export const userGetOrderList = (tradeItemId, phase) => async (dispatch) => {
  try {
    const result = await user.userOrderList(tradeItemId, phase);

    dispatch(userGetOrderListAction(result.data));

    return result;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error('balance err', e);
    return null;
  }
};

// userOrderHistoyList
export const userGetOrderHistoryList = (tradeItemId) => async (dispatch) => {
  try {
    const result = await user.userOrderHistoryList(tradeItemId);

    dispatch(userGetOrderHistoryListAction(result.data));

    return result;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error('balance err', e);
    return null;
  }
};

// userOrderHistoyListAll
export const userGetOrderHistoryAllList = () => async (dispatch) => {
  try {
    const result = await user.userOrderHistoryAllList();

    dispatch(userGetOrderHistoryAllListAction(result.data));

    return result;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error('balance err', e);
    return null;
  }
};

export const userCancelOrder = (cancelList) => async (dispatch) => {
  try {
    const result = await user.userCancelOrderRequest(cancelList);

    dispatch(userCancelOrderAction(result.data));

    return result;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error('balance err', e);
    return null;
  }
};

// NEW CANCEL

export const cancelOrder = (cancelItem) => async () => {
  try {
    const result = await user.userCancelOrderRequest(cancelItem);

    userCancelOrderAction(result.data);
    return result;
  } catch (e) {
    if (typeof window !== 'undefined') window.console.error('balance err', e);
    return null;
  }
};
