import styled from 'styled-components';
import {
  V2_BUTTON_COLOR,
  V2_INACTIVE_BORDER_COLOR,
  V2_INACTIVE_COLOR,
} from './color';

export const MainContainer = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100vh - 280px);
  @media (max-width: 1024px) {
    min-height: calc(100vh - 270px);
  }
  /* height: 100vh; */
`;

export const MainBackgroundSection = styled.section`
  background-color: ${(props) => (props.color ? props.color : 'none')};
  width: 1140px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
export const SectionWidth = styled.section`
  width: 1140px;
  padding: 30px 0px 0px;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 30px;
  }
`;

export const SectionWidthTest = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MainBackgroundSectionContainer = styled.div`
  width: 1140px;
  padding: 40px 80px;
  height: 100%;
  display: flex;
  flex-direction: ${(props) => (props.page === 'invest' ? 'column' : 'row')};
  justify-content: ${(props) =>
    props.page === 'invest' ? 'space-between' : 'center'};
  align-items: ${(props) =>
    props.page === 'invest' ? 'flex-start' : 'center'};
  @media (max-width: 1024px) {
    padding: 30px;
  }
`;

export const SpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SpaceBetweenColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ButtonStyle = styled.button`
  width: 100%;
  background-color: ${V2_BUTTON_COLOR};
  color: white;
  height: 40px;

  &:disabled {
    background-color: ${V2_INACTIVE_COLOR};
  }
`;

export const RegularText = styled.span`
  font-weight: 400;
`;

export const ExtraBoldText = styled.span`
  font-weight: 900;
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

export const BoldLargeText = styled.span`
  font-size: 18px;
  font-weight: 600;
`;

export const RegularSmallText = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

export const MarketPlaceBuySellContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  max-height: 600px;
  overflow-y: scroll;
  border: 1px solid rgba(72, 79, 97, 0.2);
  border-radius: 0px 0px 10px 10px;
  border-top: none;
  @media (max-width: 1024px) {
    border: none;
  }
`;

export const MarketPlaceRowStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${V2_INACTIVE_BORDER_COLOR};
  padding: 10px 0;

  &:is(:last-child) {
    border-bottom: none;
  }
`;

export const SpaceBetweenRowLineHeight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  line-height: 20px;
`;

export const MarketButtonStyle = styled.button`
  width: 100px;
  background-color: ${(props) =>
    props.myOrder ? `${V2_INACTIVE_BORDER_COLOR}` : props.backColor};
  color: white;
  height: 34px;
  border-radius: 4px;
  margin-left: 20px;
`;

// custom modal style
export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalTitle = styled.span`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
`;

export const ModalContent = styled.span`
  font-size: 14px;
  line-height: 18px;
  color: #484f61;
`;

export const ModalContentWrapper = styled.div`
  border: 1px solid #ff8a22;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
  margin: 10px 0px;
  border-radius: 6px;
`;

export const SectionDivider = styled.div`
  width: 100%;
  height: 10px;
  background: #f2f4f6;
`;
