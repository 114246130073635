import { getItemUnitLabel } from '@treasurer/common';
import LabelValueList from '@/Components/ui/LabelValueList';
import { PaddedContainer, LineDivider } from '@/styles/CommonStyledComponents';
import ItemProfileLayout from '@/Components/ui/ItemProfileLayout';
import { usePurchase } from '@/contexts/PurchaseContext';
import styled from 'styled-components';

function OrderSummarySection() {
  const { state } = usePurchase();
  const { item, isMetal, unitPrice, quantity, txBudget } = state;

  const itemTitle = `${item?.brandKR} ${item?.modelKR}`;
  const unitPriceDisplay = item && (
    <div>
      {unitPrice?.toLocaleString()}원{' '}
      <UnitLabel>/{getItemUnitLabel(item)}</UnitLabel>
    </div>
  );
  const pieceOrderPreFeePrice = unitPrice * quantity;

  const metalOrderData = [
    { label: '주문 희망 금액', value: `${txBudget.toLocaleString()}원` },
  ];

  const pieceOrderData = [
    {
      label: '조각 구매 수량',
      value: `${quantity.toLocaleString()}조각`,
    },
    {
      label: '조각 금액',
      value: `${pieceOrderPreFeePrice.toLocaleString()}원`,
    },
  ];

  const orderData = isMetal ? metalOrderData : pieceOrderData;

  const orderDataTextStyle = {
    color: '#424242',
    fontSize: '15px',
    fontWeight: '700',
  };

  return (
    <PaddedContainer>
      <ItemProfileLayout
        imgSrc={item.thumbnail}
        firstRowText={itemTitle}
        secondRowText={unitPriceDisplay}
        grayBackground
      />

      <LineDivider />

      <LabelValueList
        items={orderData}
        labelStyles={orderDataTextStyle}
        valueStyles={orderDataTextStyle}
      />
    </PaddedContainer>
  );
}

export default OrderSummarySection;

const UnitLabel = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: #979797;
`;
