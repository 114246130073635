import styled from 'styled-components';

interface BulletedListProps {
  items: string[];
}

export const BulletedList: React.FC<BulletedListProps> = ({ items }) => {
  return (
    <StyledBulletedList>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </StyledBulletedList>
  );
};

interface FinePrintProps {
  listItems: string[];
  title?: string;
}

function FinePrint({ title = '유의사항', listItems }: FinePrintProps) {
  return (
    <Container>
      <Title>{title}</Title>
      <BulletedList items={listItems} />
    </Container>
  );
}

export default FinePrint;

const Container = styled.div`
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #363b48;
  padding: 16px;
`;

const Title = styled.h3`
  font-weight: 700;
  margin-bottom: 12px;
`;

const StyledBulletedList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;
  word-break: keep-all;
  font-size: 12px;
  line-height: 18px;

  li {
    list-style-type: disc;
    list-style-position: outside;
    margin-left: 16px;
  }
`;
