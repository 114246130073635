import Modal from 'react-modal';
import styled from 'styled-components';
import AppLink from '../AppLink';

interface ConfirmAppLinkModalProps {
  modalOpen: boolean;
  title?: string;
  content?: string;
  confirmBtnText?: string;
  confirmBtnAction?: () => any;
  onRequestClose?: () => void;
  closeBtnText?: string;
  closeBtnAction?: () => any;
  orderType?: number;
  to?: string;
  isLogout?: boolean;
}

const ConfirmAppLinkModal = ({
  modalOpen,
  title,
  content,
  confirmBtnText,
  confirmBtnAction,
  onRequestClose,
  closeBtnText,
  closeBtnAction,
  orderType,
  to,
  isLogout,
}: ConfirmAppLinkModalProps) => {
  let subtitle: any;

  const afterOpenModal = () => {
    subtitle.style.color = '#000';
  };

  return (
    <Modal
      isOpen={modalOpen}
      onAfterOpen={() => afterOpenModal()}
      style={customStyles}
      onRequestClose={onRequestClose}
      // ariaHideApp added by kat to prevent "Warning: react-modal: App element is not defined. Please use `Modal.setAppElement(el)` or set `appElement={el}`" warning
      ariaHideApp={false}
    >
      <ModalContainer>
        <div style={{ marginBottom: 22 }}>
          {/* eslint-disable-next-line no-return-assign */}
          <BoldText ref={(_subtitle) => (subtitle = _subtitle)}>
            {title}
          </BoldText>
        </div>
        <div style={{ marginBottom: 20 }}>
          <RegularText style={{ whiteSpace: 'pre-line' }}>
            {content}
          </RegularText>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {closeBtnText && (
            <ButtonStyle
              style={{
                marginRight: 12,
                color: '#484F61',
                border: '1px solid #484F61',
              }}
              onClick={closeBtnAction}
            >
              {closeBtnText}
            </ButtonStyle>
          )}
          <AppLink
            to={to}
            title={title}
            isLogout={isLogout}
            style={{ width: '100%' }}
          >
            <ButtonStyle onClick={confirmBtnAction}>
              {confirmBtnText}
            </ButtonStyle>
          </AppLink>
        </div>
      </ModalContainer>
    </Modal>
  );
};

const ButtonStyle = styled.button`
  width: 100%;
  color: #ff8a22;
  height: 39px;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #ff8a22;
  box-sizing: border-box;
  border-radius: 6px;
`;

const BoldText = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const RegularText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ModalContainer = styled.div`
  width: 567px;
  padding: 40px 40px 30px;

  @media (max-width: 1024px) {
    max-width: 100%;
    width: 100%;
    max-height: 70vh;
    overflow-y: auto;
    scroll-behavior: smooth;
    background-color: white;
  }

  @media (max-width: 768px) {
    padding: 38px 20px 24px 20px;
    max-width: 80vw;
    width: 100%;
  }
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 'none',
    boxShadow: '3px 4px 16px rgba(72, 79, 97, 0.2)',
    borderRadius: '10px',
  },
};

export default ConfirmAppLinkModal;
