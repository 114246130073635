import { useHistory } from 'react-router-dom';
import UserDeleteImage from '../../assets/images/userDelete.png';
import {
  DeleteButton,
  DeleteSuccessContainer,
  DeleteWrapperDesc,
  DeleteWrapperTitle,
} from './DeleteStyle';

const DeleteSuccess = () => {
  const history = useHistory();

  return (
    <DeleteSuccessContainer>
      <img src={UserDeleteImage} alt="deleteImg" />
      <DeleteWrapperTitle style={{ marginTop: 56, fontWeight: 'bold' }}>
        그동안 트레져러를
      </DeleteWrapperTitle>
      <DeleteWrapperTitle style={{ fontWeight: 'bold' }}>
        이용해 주셔서 감사합니다
      </DeleteWrapperTitle>
      <div
        style={{
          marginTop: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <DeleteWrapperDesc>
          다시 트레져러를 찾아 오실 때, 더 나은
        </DeleteWrapperDesc>
        <DeleteWrapperDesc>
          서비스를 제공할 수 있도록 노력하겠습니다.
        </DeleteWrapperDesc>
      </div>
      <div style={{ padding: '0px 16px', width: '100%' }}>
        <DeleteButton
          style={{ marginTop: 200, marginBottom: 20 }}
          onClick={() => history.push('/')}
        >
          홈으로 이동
        </DeleteButton>
      </div>
    </DeleteSuccessContainer>
  );
};

export default DeleteSuccess;
