import palette from '@/config/styles/color/Color';
import { useUserInfo } from '@/Queries/userQueries';
import { UserTypes } from '@/types/UserTypes';
import { isNil } from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';
import NhIcon from '../../assets/images/nhIcon.png';
import VirtualAccountRegistrationModals from '../NewMyPage/VirtualAccountRegistrationModals';
const VirtualAccountRegistrationIntroduceScreen = ({
  userInfo,
}: {
  userInfo: UserTypes;
}) => {
  const [requestDepositModal, setRequestDepositModal] = useState(false);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const { data: user } = useUserInfo();

  if (isNil(user)) {
    return <div></div>;
  }

  const connectButtonHandler = () => {
    if (user.data?.virtualAccountNumber) {
      setRequestDepositModal(true);
    } else {
      setRegisterModalOpen(true);
    }
  };
  return (
    <ScreenContainer>
      <IntroduceContainer>
        <ImageContainer>
          <IconImage src={NhIcon} />
        </ImageContainer>
        <TopTextContainer>
          <NHBoldIntroduceText>
            편리하고 안전한 거래를 위하여
          </NHBoldIntroduceText>
          <NHBoldIntroduceText>NH전용계좌를 이용해보세요</NHBoldIntroduceText>
        </TopTextContainer>
        <NHNormalIntroduceText>
          NH전용계좌 이용 시, 별도의 입금신청 없이
        </NHNormalIntroduceText>
        <NHNormalIntroduceText>예치금 입금이 가능합니다.</NHNormalIntroduceText>
      </IntroduceContainer>
      <AccountRegisterButton onClick={connectButtonHandler}>
        전용계좌 발급받기
      </AccountRegisterButton>
      <VirtualAccountRegistrationModals
        userInfo={user?.data}
        registerModalOpen={registerModalOpen}
        setRegisterModalOpen={setRegisterModalOpen}
      />
    </ScreenContainer>
  );
};

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: -100px auto 20px auto;
  max-width: 500px;
  height: 100vh;
  padding: 0 20px;
`;

const IntroduceContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ImageContainer = styled.div`
  margin: 24px auto;
`;

const IconImage = styled.img`
  object-fit: contain;
  max-height: 120px;
`;

const TopTextContainer = styled.div`
  margin-bottom: 24px;
`;

const NHBoldIntroduceText = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
`;

const NHNormalIntroduceText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #484f61;
  line-height: normal;
  text-align: center;
`;

const AccountRegisterButton = styled.button`
  width: 100%;
  background-color: #fe8824;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;
  padding: 17px;
`;

export default VirtualAccountRegistrationIntroduceScreen;
