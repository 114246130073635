import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createInstance, HackleProvider } from '@hackler/react-sdk';

import initStore from './redux/store';
import App from './Components/App';
import './index.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

const rootElement = document.getElementById('root');
const store = initStore();

const hackleClient = createInstance('5CtMkcj8gPtUkNGAlRzit4DJHeL9MOUJ');

if (rootElement.hasChildNodes()) {
  hydrate(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>,
    rootElement,
  );
} else {
  render(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>,
    rootElement,
  );
}
