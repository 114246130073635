import { CreateCryptoSlaveRequest } from '@treasurer/common';
import { useMutation } from 'react-query';
import client from '@/apis/axios-instance';
import { BaseResponse } from '@treasurer/common';

export const useCreateCryptoSlave = () =>
  useMutation('create/slaveList', async (param: CreateCryptoSlaveRequest) => {
    const { data } = await client.post<BaseResponse<never>>(
      '/v2/crypto/slaves',
      {
        ...param,
      },
    );
    return data;
  });
