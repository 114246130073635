import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { ReactComponent as ArrowBack } from '../../assets/images/ArrowBack.svg';
import SommelierEvent1 from '../../assets/images/event/SommelierEvent1.png';
import SommelierEvent2 from '../../assets/images/event/SommelierEvent2.png';
import SommelierEvent3 from '../../assets/images/event/SommelierEvent3.png';
import SommelierEvent4 from '../../assets/images/event/SommelierEvent4.png';
import SommelierEvent5 from '../../assets/images/event/SommelierEvent5.png';

const EventContainer = styled.div`
  width: 480px;
  min-height: 100vh;
  margin: 0 auto;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const EventStatusBack = styled.div`
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    height: 56px;
    margin-top: 12px;
    margin-bottom: 0px;
    padding-left: 20px;
  }
`;

const EventImgWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const EventImg = styled.img`
  width: 100%;
  max-width: 480px;
  height: auto;
  margin-top: -3px;
`;

const EventButtonA = styled.a`
  padding: 11px 0px;
  margin: 0;
  width: 90%;
  max-height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 9%;
  left: 5%;

  background: linear-gradient(269.77deg, #ffaf28 0.12%, #f9a400 99.84%);
  box-shadow: 10px 10px 14px rgba(72, 79, 97, 0.1);
  border-radius: 10px;

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    color: #ffffff;
  }
`;

const WineEvent = () => {
  const history = useHistory();

  const isTablet = useMediaQuery('(max-width: 1024px)');

  return (
    <>
      {isTablet && (
        <EventStatusBack
          onClick={
            history.length <= 1
              ? () => {
                  history.replace('/');
                }
              : () => history.goBack()
          }
        >
          <ArrowBack style={{ marginRight: 24 }} />
          [이벤트] 와인 추천하고, 100만원 받아가세요 💰
        </EventStatusBack>
      )}

      <EventContainer>
        <EventImg src={SommelierEvent1} alt="소믈리에이벤트1" />
        <EventImgWrapper>
          <EventImg src={SommelierEvent2} alt="소믈리에이벤트2" />
          <EventButtonA
            href="https://vfnugsucgth.typeform.com/to/KiWTTCXP"
            style={{ bottom: '9%' }}
          >
            <span>투자하고 싶은 와인 알려주기</span>
          </EventButtonA>
        </EventImgWrapper>
        <EventImgWrapper>
          <EventImg src={SommelierEvent3} alt="소믈리에이벤트3" />
          <EventButtonA
            href="https://vfnugsucgth.typeform.com/to/KiWTTCXP"
            style={{ bottom: '3%' }}
          >
            <span>투자하고 싶은 와인 알려주기</span>
          </EventButtonA>
        </EventImgWrapper>
        <EventImg src={SommelierEvent4} alt="소믈리에이벤트4" />
        <EventImg src={SommelierEvent5} alt="소믈리에이벤트5" />
      </EventContainer>
    </>
  );
};

export default WineEvent;
