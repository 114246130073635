import { getUserDepositRequestAuthFetchers } from '@/fetcher/userFetchers';
import { AxiosError, AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import user from '../apis/user';
import { press } from '../config/api';
import {
  AssetNotiTypes,
  AssetUserOrderHistoryAllListTypes,
} from '../types/AssetTypes';
import { ItemCompTypes } from '../types/ItemTypes';
import { UserOwnershipTypes } from '../types/TradeTypes';
import {
  userBalanceHistoryTypes,
  userBalanceTypes,
  UserOwnershipHistoryTypes,
  userOwnershipResponseTypes,
  UserTypes,
} from '../types/UserTypes';

export interface MyUserOwnershipTypes {
  userOwnershipList: UserOwnershipHistoryTypes[];
  closing: UserOwnershipTypes[];
  userAssets: number;
  userPrincipalAssets: number;
  userProfitOrLoss: number;
  userRateOfReturn: number;
}

export interface MyUserDawHistoryStatusTypeTypes
  extends userBalanceHistoryTypes {
  krwStatus: string;
  KrwActionsType: string;
}

export const useUserInfo = (
  options?: UseQueryOptions<
    AxiosResponse<{
      result: string;
      data: UserTypes;
    }>,
    AxiosError,
    {
      result: string;
      data: UserTypes;
    },
    ['userInfo']
  >,
) => useQuery(['userInfo'], () => user.getUserInfo(), options);

export const useUserOwnerShip = (
  options?: UseQueryOptions<
    userOwnershipResponseTypes,
    AxiosError,
    MyUserOwnershipTypes,
    ['userOwnership']
  >,
) => useQuery(['userOwnership'], () => user.getUserOwnership(), options);

// export const useUserOrderHistory = (
//   options?: UseQueryOptions<
//     AssetUserOrderHistoryAllListTypes,
//     AxiosError,
//     AssetUserOrderHistoryAllListTypes,
//     ['userOrderHistory']
//   >,
// ) =>
//   useQuery(['userOrderHistory'], () => user.userOrderHistoryAllList(), options);

// temp
// export const useUserTransactionHistory = (options?: ['userOrderHistory']) =>
//   useQuery(['userOrderHistory'], () => user.userOrderHistoryAllList());

export const useUserNotiList = (
  options?: UseQueryOptions<
    AssetNotiTypes[],
    AxiosError,
    ItemCompTypes[],
    ['userNotiList']
  >,
) => useQuery(['userNotiList'], () => press.getMyNotification(), options);

export const useUserNotiTradeItemIdList = (
  options?: UseQueryOptions<
    number[],
    AxiosError,
    number[],
    ['userNotiTradeItemIdList']
  >,
) =>
  useQuery(
    ['userNotiTradeItemIdList'],
    () => press.getMyNotificationTradeItemId(),
    options,
  );

export const useUserDepositRequestAuth = (uuid: string) =>
  useQuery(['uuidAuth'], () => getUserDepositRequestAuthFetchers(uuid));
