import palette from '../config/styles/color/Color';

export const handleValueColorType = (value: number) => {
  if (value > 0) {
    return `${palette.orangeB[0]}`;
  }
  if (value < 0) {
    return `${palette.navy[0]}`;
  }
  return `${palette.gray[0]}`;
};

export const handleBackgroundColorType = (value: number) => {
  if (value > 0) {
    return `${palette.orangeB[3]}`;
  }
  if (value < 0) {
    return `${palette.navy[3]}`;
  }
  return `${palette.gray[3]}`;
};
