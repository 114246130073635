import { GET_MARKET_LIST } from '../constants/ActionTypes';

const initialState = {
  marketList: {},
};

const marketList = (state = initialState.marketList, action) => {
  switch (action.type) {
    case GET_MARKET_LIST:
      return action.marketList;
    default:
      return state;
  }
};

export const getMarketList = (state) => state.marketList; // filter active === 1

const main = (state = initialState, action) => {
  switch (action.type) {
    default:
      return {
        marketList: marketList(state.marketList, action),
      };
  }
};

export default main;
