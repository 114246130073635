import { transcribeNumbersToKorean } from '@treasurer/common';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import NumberPad from '../ItemDetail/Mobile/OnSale/NumberPad';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import { useHistory } from 'react-router-dom';
import { usePurchase } from '@/contexts/PurchaseContext';
import FullPageModal from '../Modal/FullPageModal';
import OrderSummaryModalContent from './OrderSummaryModalContent';
import CustomModal from '../Modal/CustomModal';

interface InputDisplayProps {
  enteredNumber: number | null;
}

interface RequestButtonProps {
  disabled: boolean;
}

const Container = styled.div`
  padding: 20px;
  max-width: 400px;
  margin: auto;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;
`;

const CurrentPrice = styled.div`
  background: #f6f6f7;
  border-radius: 10px;
  padding: 12px 24px;
  min-width: 117px;
  max-width: fit-content;
  margin: 32px auto;

  color: #484f61;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
`;

const UnitPrice = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin: 2px auto;
`;

const InputDisplay = styled.div<InputDisplayProps>`
  text-align: center;
  color: ${(props) => (props.enteredNumber ? `#FF8A22` : `#A3A7B0`)};
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 12px;
`;

const SubDisplay = styled.div`
  color: #484f61;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  line-height: 19px;
`;

const Detail = styled.div`
  margin-top: 32px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  div {
    display: flex;
    justify-content: space-between;
  }
`;

const RequestButton = styled.button<RequestButtonProps>`
  background-color: ${(props) => (props.disabled ? `#a3a7b0` : `#FF8A22`)};
  border-radius: 4px;
  color: #ffffff;
  font-weight: 700;
  padding: 11px 0px;
  width: 100%;
  height: 40px;
  cursor: ${(props) => (props.disabled ? `default` : `pointer`)};
`;

interface MetalBuyComponentProps {
  item: any;
  appraisalPrice: number;
  onRequestClose?: () => void;
}

const MetalBuyComponent = ({
  item,
  appraisalPrice,
  onRequestClose,
}: MetalBuyComponentProps) => {
  const [enteredNumber, setEnteredNumber] = useState<number | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  // TODO:
  const [isOrderSummaryModalOpen, setIsOrderSummaryModalOpen] = useState(false);

  const history = useHistory();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { dispatch } = usePurchase();

  // TODO:
  const memoizedAppraisalPrice = useMemo(
    () => appraisalPrice,
    [isOrderSummaryModalOpen],
  );

  const isAdminPath = location.pathname.endsWith('/test');

  const onChangeNumPadBtn = useCallback((num: number) => {
    setEnteredNumber((prev) => {
      if ((String(prev) + String(num)).length > 8) {
        return prev;
      }
      return parseInt(String(prev || 0) + String(num), 10);
    });
  }, []);

  const onChangeNumPadDelete = () => {
    if (Number.isNaN(parseInt(String(enteredNumber).slice(0, -1), 10))) {
      setEnteredNumber(null);
      return;
    }
    setEnteredNumber((prev) => parseInt(String(prev).slice(0, -1), 10));
  };

  const onClear = useCallback(() => {
    setEnteredNumber(null);
  }, []);

  const updatePurchaseContext = () => {
    if (enteredNumber) {
      dispatch({ type: 'SET_ITEM', payload: item });
      dispatch({ type: 'SET_UNIT_PRICE', payload: appraisalPrice });
      dispatch({ type: 'SET_ORDER_TYPE', payload: 'BID' });
      dispatch({ type: 'SET_ORDER_INPUT', payload: enteredNumber });
    }
  };

  // TODO:
  const handleBuyButton = () => {
    updatePurchaseContext();
    history.push(`/${isAdminPath ? 'test-' : ''}payment/${item.id}`);
  };

  useEffect(() => {
    if (enteredNumber && enteredNumber >= 1000 && enteredNumber <= 10000000) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [enteredNumber]);

  return (
    <Container>
      {isMobile && <StyledCloseIcon onClick={onRequestClose} />}

      <CurrentPrice>
        현재 시세
        <UnitPrice>
          {appraisalPrice / item.displayQuantityDivider}원 /{' '}
          {1 / item.displayQuantityDivider}g
        </UnitPrice>
      </CurrentPrice>

      <InputDisplay enteredNumber={enteredNumber}>
        {enteredNumber
          ? `${enteredNumber.toLocaleString()}원`
          : '얼마나 구매할까요?'}
      </InputDisplay>
      <SubDisplay>
        {enteredNumber
          ? `${transcribeNumbersToKorean(enteredNumber)} ${
              enteredNumber < 1000
                ? '(천원 이상부터 구매 가능해요)'
                : enteredNumber > 10000000
                ? '(1회 최대 1천만원까지 구매 가능해요)'
                : ''
            }`
          : '천원 이상 금액부터 구매 가능해요'}
      </SubDisplay>

      <NumberPad
        onClear={onClear}
        onChangeNumPadBtn={onChangeNumPadBtn}
        onChangeNumPadDelete={onChangeNumPadDelete}
      />

      <RequestButton disabled={isButtonDisabled} onClick={handleBuyButton}>
        주문서 확인
      </RequestButton>

      {/* TODO: */}
      {isMobile ? (
        <FullPageModal modalOpen={isOrderSummaryModalOpen}>
          <OrderSummaryModalContent
            item={item}
            enteredNumber={Number(enteredNumber)}
            orderType="BID"
            appraisalPrice={memoizedAppraisalPrice}
            // unitPrice={memoizedAppraisalPrice}
            onRequestClose={() => setIsOrderSummaryModalOpen(false)}
          />
        </FullPageModal>
      ) : (
        <CustomModal
          modalOpen={isOrderSummaryModalOpen}
          onRequestClose={() => setIsOrderSummaryModalOpen(false)}
          customWidth="390px"
        >
          <OrderSummaryModalContent
            item={item}
            enteredNumber={Number(enteredNumber)}
            orderType="BID"
            appraisalPrice={memoizedAppraisalPrice}
            // unitPrice={memoizedAppraisalPrice}
            onRequestClose={() => setIsOrderSummaryModalOpen(false)}
          />
        </CustomModal>
      )}
    </Container>
  );
};

export default MetalBuyComponent;
