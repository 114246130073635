import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowBack } from '../../assets/images/ArrowBack.svg';

export const DeleteContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  /* justify-content: center; */
  width: 100%;
  margin: 20px;
  /* min-height: 50vh; */

  @media (max-width: 768px) {
    margin: 0px;
  }
`;

export const DeleteWrapper = styled.div`
  width: 400px;
  background: #ffffff;
  box-shadow: 4px 3px 16px rgba(72, 79, 97, 0.2);
  border-radius: 20px;
  padding: 40px 16px 40px 16px;

  @media (max-width: 768px) {
    width: 100%;
    /* padding: 20px 16px 20px 16px; */
    box-shadow: none;
    border-radius: 0px;
    /* padding: 0px; */
  }
`;

export const DeleteWrapperTitle = styled.div`
  font-size: 20px;
  line-height: 23px;
  font-weight: 400;
`;

export const DeleteDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: black;
  margin-top: 24px;
`;

export const DeleteWrapperDesc = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #484f61;
`;

export const DeleteFAQWrapper = styled.div`
  background: rgba(72, 79, 97, 0.05);
  padding: 0px 16px;
  margin: 20px -16px;
  color: #484f61;
`;

export const DeleteFAQContentWrapper = styled.div`
  display: flex;
  padding: 20px 0px;
  border-bottom: 1px solid rgba(72, 79, 97, 0.2);

  &:last-child {
    border-bottom: none;
  }
`;

export const DeleteFAQTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  border-bottom: 1px solid;
  padding-bottom: 1px;
`;

export const DeleteFAQLink = styled(Link)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  border-bottom: 1px solid;
  padding-bottom: 1px;
`;

export const DeleteFAQContent = styled.div`
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 0vh;
  transition: all 0.3s;
  font-size: 14px;
  line-height: 22px;
  color: #484f61;

  &.act {
    visibility: visible;
    opacity: 1;
    height: auto;
    padding-top: 12px;
  }
`;

export const StyledLink = styled(Link)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  border-bottom: 1px solid;
  padding-bottom: 1px;
  color: #ff8a22;
`;

export const DeleteButton = styled.button`
  width: 100%;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;

  background: #ff8a22;
  border-radius: 5px;

  :disabled {
    background: rgba(72, 79, 97, 0.5);
  }
`;

export const DeleteDescWrapper = styled.div`
  padding: 20px 0px;
  border-bottom: 1px solid rgba(72, 79, 97, 0.2);
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const DeleteSuccessContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  margin-top: 164px;
`;

export const ArrowFront = styled(ArrowBack)`
  transform: rotate(180deg);
  width: 24px;
  height: 24px;
  margin-bottom: -2px;
  stroke-width: 1px;
`;
