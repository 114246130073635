import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { sign } from '../config/api';
import { isValidEmailFormat } from '../utils/validation';
import auth from '../apis/auth';
import ConfirmModal from '../Components/ConfirmModal';

import { ReactComponent as KlipIcon } from '../assets/images/klip/klip_logo.svg';
import KakaoKlipModal from '../Components/kakaoKlipModal';
import { klipPrepare } from '../redux/actions/authAction';
import { localSetItem } from '../utils/handleStorage';

import EmailAgree from '../assets/images/emailAgree.png';
import palette from '../config/styles/color/Color';

const Divide = styled.div`
  height: 10px;
  background-color: rgba(72, 79, 97, 0.08);
  margin: 25px -16px 25px -16px;
`;

const EmailConfirmContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const EmailConfirmWrapper = styled.div`
  width: 400px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 16px 20px 16px;
  }
`;

const ContentsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 26px;
  }
`;

const ConfirmImage = styled.img`
  width: 120px;
  height: 120px;
  margin: 42px 0px;

  @media (max-width: 768px) {
    width: 92px;
    height: 92px;
  }
`;

const EmailConfirmTitle = styled.span`
  font-size: 20px;
  line-height: 25px;
`;

const EmailConfirmDesc = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #3d3d3d;
`;

const KycLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 15px 0px;
  background-color: #ff8a22;
  margin-top: 30px;
  box-shadow: 0px 4px 4px rgba(255, 110, 48, 0.2);
  border-radius: 10px;
  width: 201px;
`;

const KlipButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff8a22;
  font-size: 18px;
  font-weight: bold;
  padding: 15px 0px;
  background-color: #fff;
  margin-top: 30px;
  box-shadow: 0px 4px 4px rgba(255, 110, 48, 0.2);
  border-radius: 10px;
  border: 1px solid #ff8a22;
  width: 201px;
`;

const SingUpInput = styled.input`
  width: 100%;
  height: 46px;
  padding: 13px 15px;
  border-radius: 10px;
`;

const ErrorMessage = styled.span`
  font-size: 14px;
  color: #ff8a22;
`;
const Box = styled.div`
  background: #ffffff;
  box-shadow: 4px 3px 16px rgba(72, 79, 97, 0.2);
  border-radius: 20px;
  padding: 20px 16px 20px 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 400px;
  margin: 14px 0px;

  @media (max-width: 768px) {
    box-shadow: none;
    border-radius: 0px;
    padding: 0px;
    width: 100%;
    margin: 0px;
  }
`;

const EmailConfirm = (props) => {
  const [failedReSendEmail, setFailedReSendEmail] = useState(false);
  const [user, setUser] = useState({ email: '' });

  // klip
  const [klipModalOpen, setKlipModalOpen] = useState(false);
  const [klipRequestKey, setKlipRequestKey] = useState('');
  
  return (
    <EmailConfirmContainer>
      <EmailConfirmWrapper>
        <ContentsContainer>
          <ConfirmImage src={EmailAgree} alt="confrim" />
          <Box>
            <EmailConfirmTitle>회원가입 완료</EmailConfirmTitle>
            <EmailConfirmDesc style={{ marginTop: 36 }}>
              트레져러 가입을 환영해요🙌
            </EmailConfirmDesc>
            <EmailConfirmDesc>
              간편한 조각소유 서비스를 시작할 수 있어요.
            </EmailConfirmDesc>
            <KycLink to="/kyc">본인인증 하기</KycLink>
          </Box>
        </ContentsContainer>
      </EmailConfirmWrapper>
      <KakaoKlipModal
        modalOpen={klipModalOpen}
        klipRequestKey={klipRequestKey}
        xBtnAction={() => setKlipModalOpen(false)}
        onRequestClose={() => setKlipModalOpen(false)}
      />
      <ConfirmModal
        modalOpen={failedReSendEmail}
        title="이메일을 찾을 수 없습니다."
        content={`가입한 이메일이 확인되지 않습니다.
            이메일을 다시 한 번 확인해주세요.`}
        confirmBtnText="확인"
        confirmBtnAction={() => setFailedReSendEmail(false)}
        onRequestClose={() => setFailedReSendEmail(false)}
      />
    </EmailConfirmContainer>
  );
};

export default EmailConfirm;
