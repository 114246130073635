/*
*   0 - 100%
    1 - 80%
    2 - 50%
    3 - 20%
    4 - 10%
*/

const palette = {
  orangeB: [
    '#FF8A22',
    'rgba(255, 138, 34, 0.8)',
    'rgba(255, 138, 34, 0.5)',
    'rgba(255, 138, 34, 0.2)',
    'rgba(255, 138, 34, 0.1)',
  ],
  orangeL: [
    '#FFA41B',
    'rgba(255, 164, 27, 0.8)',
    'rgba(255, 164, 27, 0.5)',
    'rgba(255, 164, 27, 0.2)',
    'rgba(255, 164, 27, 0.1)',
  ],
  coral: [
    '#FF6565',
    'rgba(255, 101, 101, 0.8)',
    'rgba(255, 101, 101, 0.5)',
    'rgba(255, 101, 101, 0.2)',
    'rgba(255, 101, 101, 0.1)',
  ],
  navy: [
    '#213E85',
    'rgba(33, 62, 133, 0.8)',
    'rgba(33, 62, 133, 0.5)',
    'rgba(33, 62, 133, 0.2)',
    'rgba(33, 62, 133, 0.1)',
  ],
  purple: [
    '#AC6AFF',
    'rgba(172, 106, 255, 0.8)',
    'rgba(172, 106, 255, 0.5)',
    'rgba(172, 106, 255, 0.2)',
    'rgba(172, 106, 255, 0.1)',
  ],
  gray: [
    '#484F61',
    'rgba(72, 79, 97, 0.8)',
    'rgba(72, 79, 97, 0.5)',
    'rgba(72, 79, 97, 0.2)',
    'rgba(72, 79, 97, 0.1)',
  ],
  ghost: [
    '#A3A7B0',
    'rgba(163, 167, 176, 0.8)',
    'rgba(163, 167, 176, 0.5)',
    'rgba(163, 167, 176, 0.2)',
    'rgba(163, 167, 176, 0.1)',
  ],
  black: [
    '#353C49',
    'rgba(29, 29, 29, 0.8)',
    'rgba(29, 29, 29, 0.5)',
    'rgba(29, 29, 29, 0.2)',
    'rgba(29, 29, 29, 0.1)',
  ],
};

export default palette;
