import DefaultEventLandingPage from './DefaultEventLandingPage';
import sellProposalEventDetails from '../../assets/images/event/sellProposalEventLanding240716.png';
import { NAVIGATION_TABS } from '@treasurer/common';

function SellProposalEventLandingPage() {
  return (
    <DefaultEventLandingPage
      eventDetailImageSrc={sellProposalEventDetails}
      eventName="매각희망가 제안 이벤트 참여"
      link={NAVIGATION_TABS.MY}
      isAppOnlyEvent
      buttonColor="#4F57F8"
    />
  );
}

export default SellProposalEventLandingPage;
