import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ArrowBack } from '../assets/images/ArrowBack.svg';

import { localGetItem } from '../utils/handleStorage';
import DeleteService from './Delete/DeleteService';
import DeleteConfirm from './Delete/DeleteConfirm';

const ItemStatusBack = styled.h1`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    margin-top: 12px;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
  }
`;

const Delete = () => {
  const [reason, setReason] = useState('');

  const history = useHistory();
  const token = localGetItem('session');

  const {
    location: { pathname },
  } = history;

  useEffect(() => {
    if (!token) {
      history.push('/');
    }

    if (pathname === '/delete/confirm' && !reason) {
      history.go(-1);
    }
  }, [token]);

  const handleChange = (event: any) => {
    setReason(event.value as string);
  };

  return (
    <div style={{ minHeight: '50vh' }}>
      <ItemStatusBack className="tablet" onClick={() => history.go(-1)}>
        <ArrowBack style={{ marginRight: 24 }} />
        회원탈퇴
      </ItemStatusBack>
      {pathname === '/delete/confirm' && reason && (
        <DeleteConfirm reason={reason} />
      )}

      {pathname === '/delete' && (
        <DeleteService handleChange={handleChange} reason={reason} />
      )}
    </div>
  );
};

export default Delete;
