import styled from 'styled-components';

export const CommonContainer = styled.div`
  max-width: 768px;
  margin: 20px auto;
`;

export const PaddedContainer = styled.div<{ padding?: string }>`
  padding: ${(props) => (props.padding ? props.padding : '20px')};
`;

export const FlexColumn = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap};
`;

export const FlexRowCentered = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FlexRowTopAligned = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexRowBottomAligned = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const SpaceBetween = styled(FlexRowCentered)`
  justify-content: space-between;
`;

export const ExtendedSpaceBetween = styled(SpaceBetween)`
  flex: 1;
`;

export const SpaceBetweenTopAligned = styled(FlexRowTopAligned)`
  justify-content: space-between;
`;

export const SectionDivider = styled.hr<{ verticalMargin?: number }>`
  width: 100%;
  border-top: solid 8px #f5f6f8;
  margin: ${(props) =>
    props.verticalMargin ? `${props.verticalMargin}px 0` : '0'};
`;

export const LineDivider = styled.hr<{ verticalMargin?: number }>`
  width: 100%;
  border-top: solid 1px #f5f6f8;
  margin: ${(props) =>
    props.verticalMargin ? `${props.verticalMargin}px 0` : '20px 0'};
`;

export const DashedDivider = styled.hr<{ verticalMargin?: number }>`
  width: 100%;
  border-top: dashed 1px #f5f6f8;
  margin: ${(props) =>
    props.verticalMargin ? `${props.verticalMargin}px 0` : '20px 0'};
`;
