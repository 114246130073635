import { useState } from 'react';
import styled from 'styled-components';
import followEventDetails from '../../assets/images/event/followEventLanding.png';
import AppLink from '@/Components/AppLink';
import AppOnlyModal from '@/Components/Modal/AppOnlyModal';
import { NAVIGATION_TABS } from '@treasurer/common';

const isRN = (window as any).isRNWebView;

const CtaButton = ({
  isAppOnlyModalOpen,
  setIsAppOnlyModalOpen,
}: {
  isAppOnlyModalOpen: boolean;
  setIsAppOnlyModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const onClickBannerInBrowser = () => {
    if (!isRN) setIsAppOnlyModalOpen(true);
  };

  return isRN ? (
    <AppLink to={NAVIGATION_TABS.DISCOVER} notItemPage>
      <FloatingButton>이벤트 참여하기</FloatingButton>
    </AppLink>
  ) : (
    <>
      <FloatingButton onClick={onClickBannerInBrowser}>
        이벤트 참여하기
      </FloatingButton>

      {isAppOnlyModalOpen && (
        <AppOnlyModal
          featureName="888 이벤트 참여"
          isModalOpen={isAppOnlyModalOpen}
          onModalClose={() => setIsAppOnlyModalOpen(false)}
        />
      )}
    </>
  );
};

const FollowEventLandingPage = () => {
  const [isAppOnlyModalOpen, setIsAppOnlyModalOpen] = useState(false);

  return (
    <Container>
      <img src={followEventDetails} />

      <FinePrint>
        <h2>꼭 읽어주세요</h2>
        <ul>
          <li>
            본 이벤트는 트레져러 앱을 설치하신 고객님을 대상으로 진행되며,
            이벤트 기간 내 조각을 구매하신 고객에 한해서 혜택을 받으실 수
            있습니다.
          </li>
          <li>따라사기는 금을 제외한 상품만 인정됩니다.</li>
          <li>
            이벤트 기간내, 마케팅 수신동의를 하신 분들 대상으로 진행 됩니다.
          </li>
          <li>주간 리워드는 익주 월요일에 지급됩니다.</li>
          <li>주간 리더보드는 매주 일요일에 업데이트됩니다.</li>
          <li>이벤트 기간 중 회원 탈퇴 시, 조각 지급이 불가합니다.</li>
          <li>지급된 조각은 내자산 〉 보유 조각 현황에서 확인이 가능합니다.</li>
          <li>
            해당 이벤트는 당사 사정에 따라 사전 고지 없이 변경 및 종료될 수
            있습니다.
          </li>
          <li>
            본 이벤트에 궁금한 점이 있으시다면 카카오톡 트레져러로 문의
            부탁드립니다.
          </li>
        </ul>
      </FinePrint>

      <CtaButton
        isAppOnlyModalOpen={isAppOnlyModalOpen}
        setIsAppOnlyModalOpen={setIsAppOnlyModalOpen}
      />
    </Container>
  );
};

export default FollowEventLandingPage;

const Container = styled.div`
  max-width: 550px;
  margin: 0 auto;
`;

const FinePrint = styled.div`
  background: #f8f8f8;
  color: #000;
  padding: 40px 20px 150px 20px;
  word-break: keep-all;

  h2 {
    font-size: 16px;
    margin-bottom: 30px;
  }

  li {
    list-style-type: disc;
    list-style-position: outside;
    margin-left: 20px;
    font-size: 14px;
    line-height: 1.5;
  }
`;

const FloatingButton = styled.div`
  background: #020202;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  border-radius: 5px;
  padding: 25px 0;
  text-align: center;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  width: 89%;
  max-width: 490px;
  cursor: pointer;
`;
