import styled from 'styled-components';
import { ReactComponent as ArrowBack } from '../../assets/images/ArrowBack.svg';

type IsMobileProps = {
  isMobile: boolean;
};

const Header = styled.div<IsMobileProps>`
  /* ${({ isMobile }) => isMobile && `margin: 25px 0;`} */
`;

type HeaderWithCloseModalButtonProps = {
  onRequestClose: () => void;
  isMobile: boolean;
};

const HeaderWithCloseModalButton = ({
  onRequestClose,
  isMobile,
}: HeaderWithCloseModalButtonProps) => (
  <Header onClick={onRequestClose} isMobile={isMobile}>
    <ArrowBack style={{ cursor: 'pointer', width: '15px' }} />
  </Header>
);

export default HeaderWithCloseModalButton;
