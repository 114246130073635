import axios from '../apis/axios-instance';

export const home = {
  getHomeData: () =>
    axios.get(`/api/treasurerWeb/home`).then((data) => data.data.data),
  getNotice: () =>
    axios.get('/api/treasurerWeb/home/notice').then((data) => data.data.data),
  getNews: () =>
    axios.get('/api/treasurerWeb/home/news').then((data) => data.data.data),
};

export const item = {
  getItemList: () => axios.get('/api/treasurerWeb/items'),
  getItemCompList: () =>
    axios
      .get('/api/treasurerWeb/items/compression')
      .then((data) => data.data.data),
  getAuthItemList: () => axios.get('/api/treasurerWeb/items/auth'),
  getItemDetail: (itemId) =>
    axios.get(`/api/treasurerWeb/items/detail/${itemId}`),
  getItemQuick: (itemId) =>
    axios.get(`/api/treasurerWeb/items/quick/${itemId}`),
  getBuyItemList: () => axios.get('/api/treasurerWeb/items/buy/items'),
  getUpcomingItems: () => axios.get('/api/treasurerWeb/items/upcoming'),
  getDisposalList: () =>
    axios
      .get('/api/treasurerWeb/items/disposal')
      .then((data) => data.data.data),
  getItemMarketPrice: (ticker) =>
    axios.get(`/api/treasurerWeb/items/${ticker}/market/price`),
};

export const sign = {
  requestCode: (phoneNumber) =>
    axios.get(`/api/treasurerWeb/sign/phoneNumberLogin/${phoneNumber}`),
  confirmEmail: (hashEmail) =>
    axios.post(`/api/treasurerWeb/sign/confirm/email`, { hashEmail }),
  requestPasswordReset: (email) =>
    axios.post(`/api/treasurerWeb/sign/password/reset`, { email }),
  confirmResetPassword: (hashEmail) =>
    axios.post(`/api/treasurerWeb/sign/reset/confirm/password`, { hashEmail }),
  newPasswordConfirm: (hashEmail, password) =>
    axios.post(`/api/treasurerWeb/sign/change/password`, {
      hashEmail,
      password,
    }),
};

export const press = {
  notificationPress: (tradeItemId) =>
    axios.post(`/api/treasurerWeb/notification/press`, { tradeItemId }),
  getPressActions: (tradeItemId) =>
    axios.get(`/api/treasurerWeb/user/press/actions/${tradeItemId}`),
  getNotification: (tradeItemId) =>
    axios.get(`/api/treasurerWeb/notification/${tradeItemId}/all`),
  getMyNotification: () =>
    axios
      .get(`/api/treasurerWeb/notification/press/my`)
      .then((data) => data.data.data),
  getMyNotificationTradeItemId: () =>
    axios
      .get(`/api/treasurerWeb/notification/press/my/id`)
      .then((data) => data.data.data),
  // app push
  appPush: (deviceId, type) =>
    axios.post(`/v2/api/treasurer/app/fcm/active`, { deviceId, type }),
};

export const watch = {
  getWatchData: () => axios.get('/api/treasurerWeb/watch/list'),
  getWatchSearchData: (keyword) =>
    axios.get(`/api/treasurerWeb/watch/list/search?keyword=${keyword}`),
};

export const app = {
  getUserPush: (deviceId) =>
    axios
      .post('/v2/api/treasurer/app/fcm/user', { deviceId })
      .then((data) => data.data),
  getUserVote: (tradeItemId) =>
    axios.get(`/v2/api/treasurer/app/vote/${tradeItemId}/user`),
  getVoteAll: (tradeItemId) =>
    axios.get(`/v2/api/treasurer/app/vote/${tradeItemId}/all`),
  postUserVote: (tradeItemId, vote) =>
    axios.post(`/v2/api/treasurer/app/vote/${tradeItemId}`, { vote }),
};

export const user = {
  userDelete: (reason) =>
    axios
      .post('api/treasurerWeb/sign/user/delete', { reason })
      .then((data) => data.data),
};
