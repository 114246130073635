import numeral from 'numeral';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DisposalItemTypes } from '../../types/ItemTypes';
import AppLink from '../AppLink';

interface DisposalComponentProps {
  item: DisposalItemTypes;
}

type Disposal = {
  isDisposal: boolean;
};

const ItemWrapper = styled.div<Disposal>`
  width: 332px;
  height: 350px;
  background: #ffffff;
  box-shadow: 3px 4px 16px rgba(72, 79, 97, 0.2);
  border-radius: 20px;
  padding: 20px 20px 20px 26px;
  display: flex;
  flex-direction: column;
  /* margin-right: 20px; */

  @media (max-width: 1024px) {
    margin-top: 20px;
    min-width: 48%;
    height: 90%;
  }

  @media (max-width: 768px) {
    /* min-width: 0; */
    min-width: ${(props) => (props.isDisposal ? '100%' : '280px')};
    height: ${(props) => (props.isDisposal ? '100%' : '350px')};
    width: 100%;
  }
`;

const ItemBrand = styled.p`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const ItemModel = styled.p`
  font-size: 18px;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ItemImage = styled(LazyLoadImage)`
  width: 114px;
  height: 114px;

  @media (max-width: 768px) {
    /* width: 40%; */
    height: 100%;
  }
`;

const ItemDescCard = styled.div`
  width: 80px;
  height: 40px;
  box-shadow: 1px 2px 11px rgba(72, 79, 97, 0.2);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 30%;
  }
`;

const ItemDescTinyFont = styled.p`
  font-size: 10px;
  color: #353c49;
`;

const ItemDescBoldFont = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #353c49;
  line-height: 20px;
`;

const ItemSaleDateBold = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #484f61;

  /* @media (max-width: 768px) {
        font-size: 3vw;
    } */
`;

const ItemSaleDateTiny = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: rgba(72, 79, 97, 0.8);
  margin-left: 34px;

  @media (max-width: 768px) {
    margin-left: 12px;
  }
`;

const ItemTopWrapper = styled.div<Disposal>`
  display: flex;
  align-items: ${({ isDisposal }) => (isDisposal ? 'flex-start' : 'flex-end')};
  justify-content: space-between;
  height: 102;

  span {
    margin-right: -20px;
  }
`;

const ItemTopLeftWrapper = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '50%' : '178px')};
  height: 58px;
`;

const ItemStatus = styled.div`
  width: 74px;
  height: 26px;
  background: rgba(72, 79, 97, 0.1);
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const DisposalComponent = ({ item }: DisposalComponentProps) => {
  const location = useLocation();
  const isDisposal =
    location.pathname === '/soldout' ||
    location.pathname === '/piece' ||
    location.pathname === '/soldout-webview';

  const isMobile = useMediaQuery('(max-width: 768px)');
  if (!item) return null;

  const startDate: Date = new Date(String(item.startDate));
  const saleDate: Date = new Date(String(item.saleDate));
  const endDate: Date = new Date(String(item.endDate));

  const elapsedMSec: number = saleDate.getTime() - startDate.getTime(); // 172800000
  const elapsedDay: number = elapsedMSec / 1000 / 60 / 60 / 24;

  const recruitmentMSec: number = endDate.getTime() - startDate.getTime();
  const recruitmentDay: number = recruitmentMSec / 1000 / 60 / 60 / 24;

  return (
    <>
      {/* {loading ? (
        <ItemWrapper
          style={{ boxShadow: 'none', backgroundColor: 'transparent' }}
          isDisposal={isDisposal}
        >
          <Skeleton variant="rectangular" height={300} />
        </ItemWrapper>
      ) : ( */}
      <ItemWrapper isDisposal={isDisposal}>
        <AppLink to={`/item/${item.tradeItemId}`}>
          <ItemTopWrapper isDisposal={isDisposal}>
            <ItemTopLeftWrapper isMobile={isMobile}>
              {isDisposal && (
                <ItemStatus>
                  {item.status === 401
                    ? '투표 진행'
                    : item.status === 402
                    ? '매각 진행'
                    : item.status === 403
                    ? '매각 완료'
                    : item.status === 405
                    ? '투표 예정'
                    : item.status === 406
                    ? '투표 종료'
                    : item.status === 407
                    ? '매각 부결'
                    : ''}
                </ItemStatus>
              )}
              <ItemBrand>{item.brand}</ItemBrand>
              <ItemModel>{item.model}</ItemModel>
            </ItemTopLeftWrapper>
            <ItemImage
              src={item.image}
              alt={item.model}
              effect="black-and-white"
            />
          </ItemTopWrapper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 30,
            }}
          >
            <ItemDescCard>
              <ItemDescTinyFont>보유기간</ItemDescTinyFont>
              <ItemDescBoldFont>{elapsedDay || '-'}일</ItemDescBoldFont>
            </ItemDescCard>
            <ItemDescCard>
              <ItemDescTinyFont>최종수익</ItemDescTinyFont>
              <ItemDescBoldFont>
                {item.finalReturn ? item.finalReturn : '-'}%
              </ItemDescBoldFont>
            </ItemDescCard>
            <ItemDescCard
              style={{
                background:
                  'linear-gradient(256.5deg, #FF8A22 6.55%, #ffa41b 91.73%)',
              }}
            >
              <ItemDescTinyFont style={{ color: '#fff' }}>
                연환산 수익
              </ItemDescTinyFont>
              <ItemDescBoldFont style={{ color: '#fff' }}>
                {`${
                  item.finalReturn
                    ? elapsedDay
                      ? (
                          (Number(item.finalReturn) / 100 / elapsedDay) *
                          365 *
                          100
                        ).toFixed(1)
                      : '-'
                    : '-'
                }`}
                %
              </ItemDescBoldFont>
            </ItemDescCard>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 24,
            }}
          >
            <div style={{ display: 'flex', marginTop: 6 }}>
              <ItemSaleDateBold>판매기간</ItemSaleDateBold>
              <ItemSaleDateTiny>{`${item.startDate} ~ ${item.endDate.slice(
                5,
              )} (${recruitmentDay}일)`}</ItemSaleDateTiny>
            </div>
            <div style={{ display: 'flex', marginTop: 6 }}>
              <ItemSaleDateBold>매각날짜</ItemSaleDateBold>
              <ItemSaleDateTiny>
                {item.saleDate ? item.saleDate : '-'}
              </ItemSaleDateTiny>
            </div>
            <div style={{ display: 'flex', marginTop: 6 }}>
              <ItemSaleDateBold>신규상품 오픈 당시 금액</ItemSaleDateBold>
              <ItemSaleDateTiny>
                {numeral(item.recruitmentAmount).format('0,0')} 원
              </ItemSaleDateTiny>
            </div>
            <div style={{ display: 'flex', marginTop: 6 }}>
              <ItemSaleDateBold>매각가격</ItemSaleDateBold>
              <ItemSaleDateTiny>
                {numeral(item.sellingPrice ? item.sellingPrice : '0').format(
                  '0,0',
                )}{' '}
                원{' '}
                {parseInt(item.finalReturn as string, 10) > 0
                  ? `(+${item.finalReturn ? item.finalReturn : '-'}%)`
                  : `(-${item.finalReturn ? item.finalReturn : '-'}%)`}
              </ItemSaleDateTiny>
            </div>
          </div>
        </AppLink>
      </ItemWrapper>
    </>
  );
};

export default DisposalComponent;
