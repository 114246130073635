import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useIsMobile } from '../../utils/customHook/useIsMobile';
import { ReactComponent as ConfirmationCheck } from '../../assets/images/confirmationCheck.svg';
import CustomModal from '../Modal/CustomModal';
import FullPageModal from '../Modal/FullPageModal';
import { DynamicButton } from '../../pages/PortfolioCuration/CopyTradingOrderSummaryModal';
import AppLink from '../AppLink';
import { NAVIGATION_TABS } from '@treasurer/common';

type IsMobileProps = {
  isMobile: boolean;
};

const Content = styled.div<IsMobileProps>`
  padding: 20px;
  max-width: 400px;
  margin: auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ isMobile }) => isMobile && `height: 100%;`}
  padding-bottom: 76px;
  z-index: 1000;
`;

const TitleSection = styled.div`
  margin: 32px 0;
  color: #353c49;
  text-align: center;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
`;

const Detail = styled.div`
  background: #f6f6f7;
  border-radius: 10px;
  padding: 20px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #484f61;
  word-break: keep-all;
  text-align: center;
`;

type OrderCompleteModalContentProps = {
  orderType: 'BID' | 'ASK';
  redirectTo?: string;
  isMobile: boolean;
};

const OrderCompleteModalContent = ({
  orderType,
  redirectTo,
  isMobile,
}: OrderCompleteModalContentProps) => {
  const history = useHistory();

  const handleButton = () => {
    history.push(redirectTo || '/my');
  };

  const isRN = (window as any).isRNWebView || false;

  return (
    <>
      <Content isMobile={isMobile}>
        <ConfirmationCheck />

        <TitleSection>
          <Title> {orderType === 'BID' ? '구매' : '판매'}를 완료했어요</Title>
        </TitleSection>

        <Detail>
          {orderType === 'BID' &&
            '㈜트레져러에셋이 발행한 e금 상품권을 수령하셨습니다.\n\n'}
          {orderType === 'BID'
            ? '구매한 상품은 내자산 〉 보유조각에서, 구매내역은 내자산 〉 주문내역에서 확인할 수 있어요.'
            : '판매한 내역은 내자산 〉 주문내역에서 확인할 수 있어요.'}
        </Detail>
      </Content>

      {isRN ? (
        <AppLink to={NAVIGATION_TABS.MY} notItemPage>
          <DynamicButton isMobile={isMobile}>확인</DynamicButton>
        </AppLink>
      ) : (
        <DynamicButton onClick={handleButton} isMobile={isMobile}>
          확인
        </DynamicButton>
      )}
    </>
  );
};

type OrderCompleteModalProps = {
  isConfirmationModalOpen: boolean;
  setIsConfirmationModalOpen: any;
  orderType: 'BID' | 'ASK';
  redirectTo?: string;
};

const OrderCompleteModal = ({
  isConfirmationModalOpen,
  setIsConfirmationModalOpen,
  orderType,
  redirectTo,
}: OrderCompleteModalProps) => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <FullPageModal modalOpen={isConfirmationModalOpen}>
      <OrderCompleteModalContent
        orderType={orderType}
        redirectTo={redirectTo}
        isMobile={isMobile}
      />
    </FullPageModal>
  ) : (
    <CustomModal
      modalOpen={isConfirmationModalOpen}
      onRequestClose={() => setIsConfirmationModalOpen(false)}
      customWidth="386px"
      customHeight="480px"
    >
      <OrderCompleteModalContent
        orderType={orderType}
        redirectTo={redirectTo}
        isMobile={isMobile}
      />
    </CustomModal>
  );
};

export default OrderCompleteModal;
