import axios from './axios-instance';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUserInfo: () =>
    axios.get('/api/treasurerWeb/user/info').then((data) => data.data),

  getUserBalance: () =>
    axios
      .get(`/api/treasurerWeb/action/depositAndWithdrawal`)
      .then((data) => data.data.data),

  getUserItemOwnership: (tradeItemId) =>
    axios.get(`/api/treasurerWeb/action/ownership/${tradeItemId}`),

  buyPiece: (amount, tradeItemId, quantity) =>
    axios.post(`/api/treasurerWeb/buy`, {
      amount,
      tradeItemId,
      quantity,
    }),

  buyPieceWithCardFlag: (message) =>
    axios.post(`/api/treasurerWeb/buy/withIamPortFlag`, { message }),

  buyPieceWithCard: (userId, tradeItemId, quantity, amount, impUid) =>
    axios.post(`/api/treasurerWeb/buy/withIamPort`, {
      userId,
      tradeItemId,
      quantity,
      amount,
      imp_uid: impUid,
    }),

  getUserOwnership: () =>
    axios
      .get(`/api/treasurerWeb/user/ownerships`)
      .then((data) => data.data.data),

  getUserOwnershipDetail: (tradeItemId) =>
    axios.post(`/api/treasurerWeb/user/ownership/detail`, {
      tradeItemId,
    }),

  saveUserBank: (bankInfo) =>
    axios.post(`/api/treasurerWeb/user/save/bank`, { bankInfo }),
  createAccount: (bankData) =>
    axios.post(`/v2/api/treasurer/user/bank/create`, bankData),
  updateAccount: (bankData) =>
    axios.post(`/v2/api/treasurer/user/bank/update`, bankData),
  deleteAccount: (bankData) =>
    axios.get(`/v2/api/treasurer/user/bank/delete`, bankData),

  // 매수 주문
  pieceBuyRequest: (tradeItemId, quantity, unitPrice) =>
    axios.post(`/v2/api/treasurer/orderbook/${tradeItemId}/bid`, {
      quantity,
      unitPrice,
    }),

  // 매도 주문
  pieceSellRequest: (tradeItemId, quantity, unitPrice) =>
    axios.post(`/v2/api/treasurer/orderbook/${tradeItemId}/ask`, {
      quantity,
      unitPrice,
    }),

  // 매수 채결 (판매)
  orderSellPiece: (tradeOrderId) =>
    axios.post(`/v2/api/treasurer/order/sell`, { tradeOrderId }),
  // 매도 채결 (구매)
  orderBuyPiece: (tradeOrderId) =>
    axios.post(`/v2/api/treasurer/order/buy`, { tradeOrderId }),
  // 주문 가능한 조각, 원화 조회
  ownBalanceInfo: (tradeItemId) =>
    axios.get(`/v2/api/treasurer/orderbook/${tradeItemId}/owned/information`),

  userOrderList: (tradeItemId, phase) =>
    axios.get(`/v2/api/treasurer/order/${tradeItemId}/${phase}`),

  userOrderHistoryList: (tradeItemId) =>
    axios.get(`/v2/api/treasurer/orderbook/${tradeItemId}/my/order`),

  // userOrderHistoryAllList: () =>
  //   axios
  //     .get(`/v2/api/treasurer/orderbook/my/order/all`)
  //     .then((data) => data.data.data),

  userCancelOrderRequest: (tradeOrderIdList) =>
    axios.post(`/v2/api/treasurer/orderbook/cancel`, {
      tradeOrderIdList,
    }),
  // pieceSellRequest: (tradeOrderId) => {
  //   return axios.post(`/v2/api/treasurer/order/sell`, {tradeOrderId})
  // },

  // klip prepare
  klipPrepare: () => axios.get(`v2/api/nft/treasurer/klaytn/prepare`),

  // klip result
  klipResult: (key) =>
    axios.post(`v2/api/nft/treasurer/klaytn/result`, { key }),

  // marketing permission
  marketingPress: (marketing) =>
    axios.post(`/api/treasurerWeb/user/marketing/permission`, {
      marketing,
    }),
};
