import { CreateCryptoMasterRequest } from '@treasurer/common';
import { useMutation } from 'react-query';
import client from '@/apis/axios-instance';

export const useCreateCryptoMaster = () =>
  useMutation('create/master', async (params: CreateCryptoMasterRequest) => {
    const { data } = await client.post('/v2/crypto/masters', {
      ...params,
    });
    return data;
  });
