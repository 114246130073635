import palette from '@/config/styles/color/Color';
import React from 'react';
import styled from 'styled-components';
import CustomModal from './CustomModal';

interface SignUpAppOnlyModalProps {
  isModalOpen: boolean;
  onModalClose: any;
}

const SignUpAppOnlyModal = ({
  isModalOpen,
  onModalClose,
}: SignUpAppOnlyModalProps) => {
  return (
    <CustomModal modalOpen={isModalOpen} onRequestClose={onModalClose}>
      <ModalTitle>회원 가입 안내</ModalTitle>
      <ModalContent>
        현재 트레져러 앱에서만 회원가입을 할 수 있어요.
      </ModalContent>
      <ButtonContainer>
        <Button onClick={onModalClose}>취소</Button>
        <AppButton
          onClick={() => (location.href = 'https://treasurer.page.link/NLtk')}
        >
          앱으로 이동
        </AppButton>
      </ButtonContainer>
    </CustomModal>
  );
};

export default SignUpAppOnlyModal;

const ModalTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
`;

const ModalContent = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 12px;
`;

const Button = styled.div`
  padding: 11px 0;
  border: 1px solid #ff8a22;
  border-radius: 8px;
  width: 50%;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
`;

const AppButton = styled(Button)`
  color: #fff;
  background-color: ${palette.orangeB[0]};
`;
