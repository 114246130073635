import LabelValueList from '@/Components/ui/LabelValueList';
import { usePurchase } from '@/contexts/PurchaseContext';
import {
  DashedDivider,
  LineDivider,
  SpaceBetween,
} from '@/styles/CommonStyledComponents';
import { formattedNumberWithPlusOrMinusSign } from '@treasurer/common';
import styled from 'styled-components';
import {
  HighlightedText,
  MainGrayText,
  MainText,
  SectionTitle,
} from '../PaymentStyles';
import { POINT_SYSTEMS } from '../PaymentUtils';

function OrderReceiptSection() {
  const { state } = usePurchase();
  const {
    isMetal,
    orderSummary,
    selectedPointSystem,
    krwBudget,
    pointBudget,
    // krwUseAmount,
    // pointUseAmount,
  } = state;

  // const feeDisplay = (fee: number) =>
  //   fee ? `${fee.toLocaleString()}원` : '무료';

  const { aggregatedResult, paymentList } = orderSummary;
  const { aggregatedPrice, aggregatedQuantity, aggregatedWeight } =
    aggregatedResult;

  const krwBreakdownData = paymentList?.krw;

  const pointBreakdownData = paymentList?.[selectedPointSystem] || null;

  const activePointSystemLabel =
    POINT_SYSTEMS.find((system) => system.id === selectedPointSystem)?.label ||
    selectedPointSystem;

  const activePointSystemFeePercentage =
    POINT_SYSTEMS.find((system) => system.id === selectedPointSystem)
      ?.feePercentage || 0;

  // TODO: add to server
  const treasurerFeePercentage = isMetal ? 0.7 : 0;

  const krwPreFeeAmount = krwBreakdownData?.amount - krwBreakdownData?.totalFee;
  const pointPreFeeAmount =
    pointBreakdownData?.amount - pointBreakdownData?.totalFee;

  const krwUsageBreakdownArray = krwBreakdownData
    ? [
        {
          label: <ReceiptText>트레져러 예치금</ReceiptText>,
          value: (
            <ReceiptText>{krwPreFeeAmount.toLocaleString()}원</ReceiptText>
          ),
        },
        {
          label: <ReceiptText>수수료</ReceiptText>,
          value: (
            <ReceiptText>
              {formattedNumberWithPlusOrMinusSign(krwBreakdownData.totalFee)}원
            </ReceiptText>
          ),
        },
        {
          label: (
            <BreakdownText>
              ㄴ트레져러 거래 수수료
              {!!treasurerFeePercentage && ` (${treasurerFeePercentage}%)`}
            </BreakdownText>
          ),
          value: (
            <BreakdownText>
              {treasurerFeePercentage
                ? `${krwBreakdownData.totalFee.toLocaleString()}원`
                : '무료'}
              {/* {feeDisplay(krwBreakdownData.totalFee)} */}
            </BreakdownText>
          ),
        },
      ]
    : null;

  const pointUsageBreakdownArray = pointBreakdownData
    ? [
        {
          label: <ReceiptText>포인트 사용금액</ReceiptText>,
          value: (
            <ReceiptText>{pointPreFeeAmount.toLocaleString()}원</ReceiptText>
          ),
        },
        {
          label: <ReceiptText>수수료</ReceiptText>,
          value: (
            <ReceiptText>
              {formattedNumberWithPlusOrMinusSign(pointBreakdownData.totalFee)}
              원
            </ReceiptText>
          ),
        },
        {
          label: (
            <BreakdownText>
              ㄴ트레져러 거래 수수료
              {!!treasurerFeePercentage && ` (${treasurerFeePercentage}%)`}
            </BreakdownText>
          ),
          value: (
            <BreakdownText>
              {treasurerFeePercentage
                ? `${pointBreakdownData.treasurerFee.toLocaleString()}원`
                : '무료'}
              {/* {feeDisplay(pointBreakdownData.treasurerFee)} */}
            </BreakdownText>
          ),
        },
        {
          label: (
            <BreakdownText>
              ㄴ포인트 수수료 [{activePointSystemLabel}]
              {!!activePointSystemFeePercentage &&
                ` (${activePointSystemFeePercentage}%)`}
            </BreakdownText>
          ),
          value: (
            <BreakdownText>
              {activePointSystemFeePercentage
                ? `${pointBreakdownData.pointFee}원`
                : '무료'}
            </BreakdownText>
          ),
        },
      ]
    : null;

  const totalPriceArray = [
    // {
    //   label: <MainGrayText>현재 입력된 금액</MainGrayText>,
    //   value: (
    //     <MainGrayText>
    //       {(krwBudget + pointBudget).toLocaleString()}원
    //       {/* {(krwUseAmount + pointUseAmount).toLocaleString()}원 */}
    //     </MainGrayText>
    //   ),
    // },
    {
      label: <MainText>최종 결제 금액</MainText>,
      value: (
        <HighlightedText>{aggregatedPrice.toLocaleString()}원</HighlightedText>
      ),
    },
  ];

  // const TreasurerFeeInfo = (fee?: number) => (
  //   <SpaceBetween>
  //     <span>트레져러 거래 수수료</span>
  //     <span>{fee ? `${fee}원` : '무료'}</span>
  //   </SpaceBetween>
  // );

  return (
    <Container>
      <SectionTitle>결제 금액</SectionTitle>

      <LineDivider />

      {isMetal ? (
        <SpaceBetween>
          <MainText>최종 구매 중량</MainText>
          <HighlightedText>{aggregatedWeight}g</HighlightedText>
        </SpaceBetween>
      ) : (
        <SpaceBetween>
          <MainText>조각 구매 수량</MainText>
          <HighlightedText>
            {aggregatedQuantity.toLocaleString()}조각
          </HighlightedText>
        </SpaceBetween>
      )}

      {krwUsageBreakdownArray && (
        <>
          <DashedDivider />
          <LabelValueList items={krwUsageBreakdownArray} />
        </>
      )}

      {pointUsageBreakdownArray && (
        <>
          <DashedDivider />
          <LabelValueList items={pointUsageBreakdownArray} />
        </>
      )}

      <LineDivider />

      <LabelValueList items={totalPriceArray} />
    </Container>
  );
}

export default OrderReceiptSection;

const Container = styled.div`
  padding: 20px 20px 0 20px;
`;

const ReceiptText = styled.span`
  font-size: 15px;
  font-weight: 700;
`;

const BreakdownText = styled.span`
  font-size: 14px;
  color: #979797;
`;
