import styled from '@emotion/styled';
import { ReactComponent as DeleteArrow } from '../../../../assets/images/DeleteArrow.svg';
import palette from '../../../../config/styles/color/Color';

interface NumberPadProps {
  onClear: () => void;
  onChangeNumPadBtn: (num: number) => void;
  onChangeNumPadDelete: () => void;
}

const NumberPadContainer = styled.div`
  width: 100%;
  display: grid;
  padding: 18px 0px 20px 0px;
  column-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
`;

const NumberPadCan = styled.button`
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  color: #353c49;

  :nth-of-type(3n - 1) {
    text-align: center;
  }

  :nth-of-type(3n) {
    text-align: right;
  }

  div {
    width: 20px;
  }

  span {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
  }

  &:active {
    background: ${palette.ghost[3]};
  }
`;

const Number = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const NumberPad = ({
  onClear,
  onChangeNumPadBtn,
  onChangeNumPadDelete,
}: NumberPadProps) => (
  <NumberPadContainer>
    {Number.map((v) => (
      <NumberPadCan
        key={`numpad-btn-${v}`}
        onClick={() => onChangeNumPadBtn(v)}
      >
        <div>
          <span>{v}</span>
        </div>
      </NumberPadCan>
    ))}
    <NumberPadCan onClick={onClear}>
      <span
        style={{
          fontSize: 14,
        }}
      >
        전체 삭제
      </span>
    </NumberPadCan>
    <NumberPadCan onClick={() => onChangeNumPadBtn(0)}>
      <span>0</span>
    </NumberPadCan>
    <NumberPadCan onClick={onChangeNumPadDelete}>
      <DeleteArrow />
    </NumberPadCan>
  </NumberPadContainer>
);

export default NumberPad;
