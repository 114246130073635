import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { Link, withRouter, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useUserInfo } from '@/Queries/userQueries';
import { STALE_TEN_SECONDS } from '@/Queries/QueriesConst';
import { localGetItem } from '../utils/handleStorage';

import LogoTiny from '../assets/images/header/logo_tiny.png';
import NotificationEmpty from '../assets/images/notificationEmpty.svg';
import NotificationNew from '../assets/images/notificationNew.svg';
import AppOnlyModal from './Modal/AppOnlyModal';
import AppLink from './AppLink';
import { checkUserHasUnreadNotification } from '@/fetcher/userQueries';
import SignUpAppOnlyModal from './Modal/SignUpAppOnlyModal';

const MainHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  overflow-y: ${({ menu, windowWidth }) =>
    menu && windowWidth ? 'hidden' : 'none'};
  justify-content: center;
  background: #fff;
  z-index: 999999;
  box-sizing: border-box;
  align-items: center;
  display: flex;

  @media (max-width: 1024px) {
    height: ${({ menu, windowWidth }) =>
      menu && windowWidth ? '100vh' : '50px'};
  }

  @media (max-width: 768px) {
    position: absolute;
  }
`;
const HeaderContainer = styled.div`
  width: 1000px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const HeaderLeftSideContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const HeaderRightSideContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderServiceRouteSection = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;

  &:hover {
    .dim {
      display: block;
      opacity: 1;
    }

    .dropdown {
      opacity: 1;
      display: flex;
      top: 51px;
    }
  }

  li {
    position: relative;
    margin-right: 20px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* display: flex; */

    display: ${({ menu, windowWidth }) =>
      menu && windowWidth ? 'flex' : 'none'};

    li {
      text-align: center;
      padding: 20px 0px;
    }
  }
`;

const HeaderUserRouteSection = styled.ul`
  display: flex;
  align-items: center;

  li {
    border-right: 1px solid #c4c4c4;

    &:is(:last-child) {
      border-right: 0px;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const HeaderUserSection = styled.ul`
  display: flex;
  align-items: center;

  li {
    border-right: 0px;

    &:is(:nth-of-type(1)) {
      border-right: 1px solid #c4c4c4;

      @media (max-width: 1024px) {
        border: none;
      }
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    li {
      min-width: 250px;
      height: 40px;
      background-color: black;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;

      &:is(:last-child) {
        background-color: white;
        color: black;
        border: 1px solid;
        margin-bottom: 0px;
      }
    }
  }
`;

const BoldTextLink = styled(Link)`
  font-size: 16px;
  font-weight: bold;
  padding: 0 12px;
  color: ${({ current }) => (current ? `#FF8A22` : 'black')};
  @media (max-width: 1024px) {
    font-size: 24px;
  }
`;

const UserText = styled.span`
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 0 12px;
  @media (max-width: 1024px) {
    font-size: 16px;
    font-weight: 400;
  }
`;

const SLink = styled(Link)`
  padding: 12px 0px;
  color: ${({ current }) => (current ? `#FF8A22` : 'black')};
  font-size: 15px;
`;

const SAtag = styled.a`
  padding: 12px 0px;
  font-size: 15px;
`;

const SDiv = styled.div`
  padding: 12px 0px;
  color: ${({ current }) => (current ? `#FF8A22` : 'black')};
`;

const UserRouteLink = styled(Link)`
  margin: 12px 0px;
  padding: 0px 10px;
  color: ${({ current }) => (current ? `#FF8A22` : 'black')};
  @media (max-width: 1024px) {
    border: none;
  }
`;

const DropdownContainer = styled.div`
  flex-direction: column;
  white-space: nowrap;
  position: absolute;
  top: 0px;
  left: 15%;
  text-align: left;
  opacity: 0;
`;

const Dim = styled.div`
  display: none;
  opacity: 0;

  position: absolute;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ff800c;
  border-bottom: 1px solid #eaeaea;
  height: 10rem;
  top: 60px;
  right: 0;
  left: 0;
  z-index: -1;
`;

const AngleBracket = styled.div`
  font-weight: bold;
  margin-left: 10px;
`;

const TitleButton = styled.div``;

const inValidShowPaths = new Set(['contract']);

const isRN = window.isRNWebView;

const NotificationLogo = () => {
  const [isAppOnlyModalOpen, setIsAppOnlyModalOpen] = useState(false);
  const { data: hasUnreadData, isLoading: hasUnreadLoading } =
    checkUserHasUnreadNotification();

  if (hasUnreadLoading) {
    return <div></div>;
  }

  const hasUnread = hasUnreadData?.hasUnread;
  return isRN ? (
    <AppLink to={'NotificationScreen'} notItemPage>
      <div style={{ marginLeft: 'auto', marginRight: 16 }}>
        <img src={hasUnread ? NotificationNew : NotificationEmpty} />
      </div>
    </AppLink>
  ) : (
    <>
      <TitleButton onClick={() => setIsAppOnlyModalOpen(true)}>
        <div style={{ marginLeft: 'auto', marginRight: 16 }}>
          <img src={hasUnread ? NotificationNew : NotificationEmpty} />
        </div>
      </TitleButton>
      {isAppOnlyModalOpen && (
        <AppOnlyModal
          featureName="알림센터"
          isModalOpen={isAppOnlyModalOpen}
          onModalClose={() => setIsAppOnlyModalOpen(false)}
        />
      )}
    </>
  );
};

export default withRouter(({ location: { pathname } }) => {
  const session = localGetItem('session');
  const history = useHistory();
  const [mobileBurgerMenu, setMobileBurgerMenu] = useState(false);
  const [isSignUpAppOnlyModalOpen, setIsSignUpAppOnlyModalOpen] =
    useState(false);

  const { data: userInfoData, isLoading: userInfoLoading } = useUserInfo({
    onSuccess: (result) => {
      if (!result) {
        // console.log('sucessssss');
        history.push('/signin');
      }
    },
    retry: false,
    staleTime: STALE_TEN_SECONDS,
  });

  const userName = userInfoData?.data.name;

  const showMobileThumbNail = useMediaQuery('(max-width: 1024px)');

  const HeaderLogo = () => (
    <div style={{ marginRight: 30 }}>
      <Link to="/">
        <img width="100px" height="15px" src={LogoTiny} alt="트레져러 로고" />
      </Link>
    </div>
  );

  const location = useLocation();
  if (inValidShowPaths.has(location.pathname.split('/')[1])) return null;

  if (showMobileThumbNail && location.pathname !== '/') {
    return <div style={{ marginTop: '-32px' }} />;
  }

  return (
    <MainHeader menu={mobileBurgerMenu} windowWidth={showMobileThumbNail}>
      <HeaderContainer
        menu={mobileBurgerMenu}
        windowWidth={showMobileThumbNail}
      >
        <HeaderLeftSideContainer
          menu={mobileBurgerMenu}
          windowWidth={showMobileThumbNail}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {showMobileThumbNail && <div style={{ marginLeft: 16 }} />}
            <HeaderLogo onClick={() => history.push('/')} />
          </div>
          {/* {showMobileThumbNail && <NotificationLogo />} */}
          <HeaderServiceRouteSection
            menu={mobileBurgerMenu}
            windowWidth={showMobileThumbNail}
          >
            <li>
              <SDiv>
                <BoldTextLink current={pathname === '/about'} to="/">
                  트레져러
                </BoldTextLink>
              </SDiv>
              <DropdownContainer className="dropdown">
                <SLink current={pathname === '/about'} to="/about">
                  트레져러 소개
                </SLink>
                <SLink current={pathname === '/notice'} to="/notice">
                  공지사항
                </SLink>
                <SAtag
                  href="http://pf.kakao.com/_gPRxjs"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  1:1 문의
                </SAtag>
                <SAtag
                  href="https://notice.treasurer.co.kr/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  블로그
                </SAtag>
              </DropdownContainer>
            </li>
            <li>
              <SDiv>
                <BoldTextLink current={pathname === '/piece'} to="/piece">
                  신규상품
                </BoldTextLink>
              </SDiv>
              <DropdownContainer className="dropdown">
                <SLink current={pathname === '/soldout'} to="/soldout">
                  매각현황
                </SLink>
              </DropdownContainer>
            </li>
            <li>
              <SDiv>
                <BoldTextLink current={pathname === '/market'} to="/market">
                  마켓
                </BoldTextLink>
              </SDiv>
            </li>
            <li>
              <SDiv>
                <BoldTextLink current={pathname === '/sell'} to="/sell">
                  매입
                </BoldTextLink>
              </SDiv>
              <DropdownContainer className="dropdown">
                <SLink current={pathname === '/sell'} to="/sell">
                  매입문의
                </SLink>
                <SLink current={pathname === '/watch'} to="/watch">
                  시계 시세 조회
                </SLink>
              </DropdownContainer>
            </li>
            <li>
              <SDiv>
                <a
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: '0px 12px',
                  }}
                  href="https://notice.treasurer.co.kr/recruit"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  인재채용
                </a>
              </SDiv>
            </li>
            <Dim className="dim" />
          </HeaderServiceRouteSection>
          {mobileBurgerMenu && showMobileThumbNail && (
            <div>
              <HeaderUserSection>
                {session ? (
                  <>
                    <li>
                      <UserRouteLink style={{ color: 'white' }} to="/my">
                        <UserText>마이페이지</UserText>
                      </UserRouteLink>
                    </li>
                    <li>
                      <UserRouteLink
                        style={{
                          paddingRight: '0',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        to="/profile"
                      >
                        <UserText
                          style={{ paddingRight: '0', display: 'flex' }}
                        >
                          {userName} 님<AngleBracket>〉</AngleBracket>
                        </UserText>
                      </UserRouteLink>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <UserRouteLink style={{ color: 'white' }} to="/signin">
                        <UserText>로그인</UserText>
                      </UserRouteLink>
                    </li>
                    <li
                      style={{
                        backgroundColor: 'white',
                        color: 'black',
                        border: '1px solid',
                        marginBottom: 0,
                      }}
                    >
                      <UserRouteLink to="/signup">
                        <UserText>회원가입</UserText>
                      </UserRouteLink>
                    </li>
                  </>
                )}
              </HeaderUserSection>
            </div>
          )}
        </HeaderLeftSideContainer>
        <HeaderRightSideContainer>
          {showMobileThumbNail && <NotificationLogo />}

          <HeaderUserRouteSection>
            <li>
              <HeaderUserSection>
                {session ? (
                  <>
                    <li>
                      <UserRouteLink current={pathname === '/my'} to="/my">
                        <UserText>내자산</UserText>
                      </UserRouteLink>
                    </li>
                    <li>
                      <UserRouteLink
                        style={{
                          paddingRight: '0',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        to="/profile"
                      >
                        <UserText
                          style={{ paddingRight: '0', display: 'flex' }}
                        >
                          {userName} 님<AngleBracket>〉</AngleBracket>
                        </UserText>
                      </UserRouteLink>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <UserRouteLink
                        current={pathname === '/signin'}
                        to="/signin"
                      >
                        <UserText>로그인</UserText>
                      </UserRouteLink>
                    </li>
                    <li>
                      <UserText
                        style={{ paddingRight: '0' }}
                        onClick={() => {
                          setIsSignUpAppOnlyModalOpen(true);
                        }}
                      >
                        회원가입
                      </UserText>
                    </li>
                    {isSignUpAppOnlyModalOpen && (
                      <SignUpAppOnlyModal
                        isModalOpen={isSignUpAppOnlyModalOpen}
                        onModalClose={() => setIsSignUpAppOnlyModalOpen(false)}
                      ></SignUpAppOnlyModal>
                    )}
                  </>
                )}
              </HeaderUserSection>
            </li>
          </HeaderUserRouteSection>
        </HeaderRightSideContainer>
      </HeaderContainer>
    </MainHeader>
  );
});
