import { usePurchase } from '@/contexts/PurchaseContext';
import {
  checkUserKbPoints,
  checkUserOcbPoints,
  checkUserTreasurerPoints,
  getUserInfo,
} from '@/fetcher/userQueries';
import {
  CommonContainer,
  SectionDivider,
} from '@/styles/CommonStyledComponents';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import FixedCtaContainer from './components/FixedCtaContainer';
import KrwInputSection from './components/KrwInputSection';
import OrderReceiptSection from './components/OrderReceiptSection';
import OrderSummarySection from './components/OrderSummarySection';
import PointInputSection from './components/PointInputSection';
import PurchaseFineprint from './components/PurchaseFineprint';

function PaymentPageTest() {
  const { state, dispatch } = usePurchase();
  const history = useHistory();
  const { tradeItemId } = useParams();

  const {
    item,
    selectedPointSystem,
    pointUseAmount,
    krwUseAmount,
    krwBalance,
    pointBalance,
    txBudget,
    krwBudget,
    pointBudget,
    totalPrice,
    orderSummary,
  } = state;

  const { data: userInfo } = getUserInfo();

  const { data: userTreasurerPoints } = checkUserTreasurerPoints(
    selectedPointSystem === 'treasurerPoint' ? 'UNIVERSAL' : 'METAL',
    {
      enabled:
        selectedPointSystem === 'treasurerPoint' ||
        selectedPointSystem === 'treasurerMetalPoint',
    },
  );

  const { data: userKbPoints } = checkUserKbPoints({
    enabled: selectedPointSystem === 'kbPoint',
  });

  const { data: userOcbPoints } = checkUserOcbPoints(totalPrice, {
    enabled:
      !!userInfo &&
      userInfo.ocbAgree === 'Y' &&
      selectedPointSystem === 'ocbPoint',
  });

  useEffect(() => {
    if (
      userTreasurerPoints &&
      (selectedPointSystem === 'treasurerPoint' ||
        selectedPointSystem === 'treasurerMetalPoint')
    ) {
      dispatch({
        type: 'SET_POINT_BALANCE',
        payload: userTreasurerPoints.usablePointAmount,
      });
    } else if (userKbPoints && selectedPointSystem === 'kbPoint') {
      dispatch({
        type: 'SET_POINT_BALANCE',
        payload: userKbPoints.kbPointree,
      });
    } else if (userOcbPoints && selectedPointSystem === 'ocbPoint') {
      dispatch({
        type: 'SET_POINT_BALANCE',
        payload: userOcbPoints.ocbPoint,
      });
    }
  }, [
    selectedPointSystem,
    userTreasurerPoints,
    userKbPoints,
    userOcbPoints,
    dispatch,
  ]);

  // Reset state on unmount
  useEffect(() => {
    return () => {
      dispatch({ type: 'RESET_ORDER' });
    };
  }, [dispatch]);

  useEffect(() => {
    if (!item) {
      history.push(`/item/${tradeItemId}/test`);
    }
  }, [item, history]);

  const insufficientFunds =
    pointBalance < pointUseAmount || krwBalance < krwUseAmount;
  const invalidInputSum = pointUseAmount + krwUseAmount < totalPrice;
  const belowTxBudget = krwBudget + pointBudget < txBudget;
  const isDisabled = insufficientFunds || invalidInputSum || belowTxBudget;

  if (!item) return null;

  return (
    <CommonContainer>
      <ContentArea>
        <OrderSummarySection />
        <SectionDivider />

        <KrwInputSection />
        <SectionDivider />

        <PointInputSection />
        <SectionDivider />

        {orderSummary && !belowTxBudget && <OrderReceiptSection />}

        <PurchaseFineprint />
      </ContentArea>

      <FixedCtaContainer
        isDisabled={isDisabled}
        mctTrToken={userOcbPoints?.mctTrToken || ''}
        availablePointree={userKbPoints?.kbPointree || 0}
      />
    </CommonContainer>
  );
}

export default PaymentPageTest;

const ContentArea = styled.div`
  margin-bottom: 240px;
`;
