import styled from '@emotion/styled';

import { Link } from 'react-router-dom';
import { ReactComponent as NotFoundCat } from '../assets/images/Error404.svg';
import ReactHelmet from '../Components/ReactHelmet';

export const NotFoundContainer = styled.section`
  width: 100%;
  height: 100vh;
  position: relative;
`;

export const NotFoundWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 262px;
  text-align: center;
`;

export const NotFoundTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const NotFoundDesc = styled.div`
  color: #484f61;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  white-space: pre-line;
  word-break: keep-all;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const NotFoundButton = styled(Link)`
  padding: 7px 22px;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-top: 37px;
  border: 1px solid #484f61;
  border-radius: 28px;
  width: fit-content;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const NotFoundButtonA = styled.button`
  padding: 7px 22px;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-top: 37px;
  border: 1px solid #484f61;
  border-radius: 28px;
  width: fit-content;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const NotFound = () => {
  const metaData = {
    title: '404 - 트레져러',
    image:
      'https://treasurer-source.s3.ap-northeast-2.amazonaws.com/trade-item-image/og_image.png',
  };

  return (
    <NotFoundContainer>
      <ReactHelmet data={metaData} />
      <NotFoundWrapper>
        <NotFoundTitle style={{ marginBottom: 5 }}>앗! 아무것도</NotFoundTitle>
        <NotFoundTitle>찾을 수 없어요</NotFoundTitle>
        <NotFoundCat width="262" height="132" />
        <NotFoundDesc style={{ marginTop: 44 }}>
          페이지가 존재하지 않거나
        </NotFoundDesc>
        <NotFoundDesc>주소가 변경 혹은 삭제됐을 수 있어요.</NotFoundDesc>
        <NotFoundDesc>입력하신 주소를 다시 확인해 주세요!</NotFoundDesc>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <NotFoundButton to="/">홈으로 돌아가기</NotFoundButton>
        </div>
      </NotFoundWrapper>
    </NotFoundContainer>
  );
};

export default NotFound;
