import { ReactNode, useEffect } from 'react';
import styled from 'styled-components';

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  height: 100vh;
  z-index: 999;
  overflow-y: auto;
`;

type FullPageModalProps = {
  children: ReactNode;
  modalOpen: boolean;
};

const FullPageModal = ({ children, modalOpen }: FullPageModalProps) => {
  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modalOpen]);
  return <>{modalOpen && <ModalContainer>{children}</ModalContainer>}</>;
};

export default FullPageModal;
