import {
  CURRENT_API_VERSION,
  KbUserPointreeResponse,
  UserPushNotificationResponse,
} from '@treasurer/common';
import axiosLib, { AxiosError } from 'axios';

import axios from '../apis/axios-instance';
import { UserBalanceInfoTypes } from '../types/AssetTypes';
import { ApiResult } from '../types/Home/Types';
import {
  BasedOn,
  userBalanceHistoryTypes,
  UserTypes,
} from '../types/UserTypes';

export interface VirtualAccountTypes {
  bankCode: string;
  accountNumber: string;
}

const getUserOwnershipTotalFetchers = async (tradeItemId: string) => {
  const { data } = await axios.get<ApiResult<{ ownershipTotal: number }>>(
    `/api/treasurerWeb/action/ownership/${tradeItemId}`,
  );
  return data.data;
};

const getMyAssetFetchers = async (basedOn: BasedOn) => {
  const { data } = await axios.get<
    ApiResult<{
      totalAsset: number;
      deposit: number;
      userCurrentOwnerships: any[];
      rateOfReturn: number;
      profitOrLoss: number;
      totalMarketValue: number;
      totalPurchasePrice: number;
    }>
  >(`/v2/api/treasurer/user/assets/total?basedOn=${basedOn}`);
  return data.data;
};

const getDisposedItemsFetchers = async () => {
  const { data } = await axios.get('/v2/api/treasurer/user/disposal');
  return data.data;
};

const getUserInfoFetchers = async () => {
  const { data } = await axios.get<ApiResult<UserTypes>>(
    '/api/treasurerWeb/user/info',
  );
  return data.data;
};

const getUserBalanceInfoFetchers = async (tradeItemId: string) => {
  const { data } = await axios.get<ApiResult<UserBalanceInfoTypes>>(
    `/v2/api/treasurer/orderbook/${tradeItemId}/owned/information`,
  );
  return data.data;
};

// New userBalance API
const getUserBalanceFetchers = async () => {
  const { data } = await axios.get(`v2/api/treasurer/user/assets/balance`);

  return data.data;
};

// const getUserBalanceFetchers = async () => {
//   const { data } = await axios.get<ApiResult<userBalanceTypes>>(
//     `/api/treasurerWeb/action/depositAndWithdrawal`,
//   );

//   return data.data;
// };

const getDawHistoryFetchers = async () => {
  const { data } = await axios.get<ApiResult<userBalanceHistoryTypes[]>>(
    `/api/treasurerWeb/action/depositAndWithdrawal/history`,
  );

  return data.data;
};

// NEW 주문 내역 API
const getMyItemOrderHistoryFetchers = async () => {
  const { data } = await axios.get(`/v2/api/treasurer/user/orders/history`);
  return data.data;
};

// NEW 입출금 내역 API
const getMyMoneyTransactionHistoryFetchers = async () => {
  const { data } = await axios.get(`/v2/api/transactions/history`);
  return data.data;
};

const getUserPointUseHistoryFetchers = async () => {
  const { data } = await axios.get(`/v2/api/transactions/history/points`);
  return data.data;
};

const getVirtualAccountFetchers = async (body: VirtualAccountTypes) => {
  try {
    const { data } = await axios.post(`/v2/api/nh/createVirtualAccount`, body);

    return data;
  } catch (err: any | AxiosError) {
    if (axiosLib.isAxiosError(err) && err.response?.data?.code === 60001) {
      return err.response?.data;
    }
    throw err;
  }
};

const updateVirtualAccountFetchers = async (body: VirtualAccountTypes) => {
  try {
    const { data } = await axios.post(`/v2/api/nh/updateVirtualAccount`, body);

    return data;
  } catch (err: any | AxiosError) {
    if (axiosLib.isAxiosError(err) && err.response?.data?.code === 60001) {
      return err.response?.data;
    }
    throw err;
  }
};

const getRequestWithdrawFetchers = async (amount: number) => {
  try {
    const { data } = await axios.post(`/v2/api/transactions/withdraw`, {
      amount,
    });
    return data.data;
  } catch (error) {
    if (axiosLib.isAxiosError(error)) {
      throw new Error(error.response?.data?.message);
    }
    throw error;
  }
};

const cancelItemOrderFetchers = async (orderId: number) => {
  const { data } = await axios.post(`/v2/api/treasurer/user/orders/cancel`, {
    orderId,
  });
  return data;
};

const getUserNotificationFetchers = async () => {
  const { data } = await axios.get<UserPushNotificationResponse>(
    `${CURRENT_API_VERSION}/api/treasurer/user/noti`,
  );

  return data.data;
};

const checkUserHasUnreadNotificationFetchers = async () => {
  const { data } = await axios.get(
    `${CURRENT_API_VERSION}/api/treasurer/user/noti/unread`,
  );

  return data.data;
};

const markNotificationAsReadFetchers = async (messageId: number) => {
  try {
    const { data } = await axios.post(
      `${CURRENT_API_VERSION}/api/treasurer/user/noti/${messageId}`,
    );
    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const getUserDepositRequestAuthFetchers = async (uuid: string) => {
  try {
    const { data } = await axios.get(
      `/api/treasurerWeb/action/request/deposit/${uuid}`,
    );
    return data.data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const checkUserKbPointsFetchers = async () => {
  const { data } = await axios.post<KbUserPointreeResponse>(
    `/api/kb/treasurer/user/pointree`,
  );
  return data.data;
};

const checkUserOcbPointsFetchers = async (mctAmount: number) => {
  const { data } = await axios.post<ApiResult<any>>(
    `/api/skp/treasurer/ocb/search/point`,
    {
      mctAmount,
    },
  );
  return data.data;
};

const checkUserTreasurerPointsFetchers = async (
  pointType: 'UNIVERSAL' | 'METAL',
) => {
  const { data } = await axios.get<ApiResult<any>>(
    `v2/api/treasurer/treasurerPoint/sum?pointType=${pointType}`,
  );
  return data.data;
};

export type BANNER_TYPES = 'popup' | 'web' | 'mobile';
const getHomeBannerFetchers = async (type?: BANNER_TYPES) => {
  const query = type ? `?type=${type}` : '';
  const { data } = await axios.get(
    `${CURRENT_API_VERSION}/api/treasurer/app/banner${query}`,
  );
  return data.data;
};

export {
  getUserOwnershipTotalFetchers,
  getMyAssetFetchers,
  getDisposedItemsFetchers,
  getUserInfoFetchers,
  getUserBalanceInfoFetchers,
  getUserBalanceFetchers,
  getDawHistoryFetchers,
  getMyItemOrderHistoryFetchers,
  getMyMoneyTransactionHistoryFetchers,
  getUserPointUseHistoryFetchers,
  getVirtualAccountFetchers,
  updateVirtualAccountFetchers,
  getRequestWithdrawFetchers,
  cancelItemOrderFetchers,
  getUserNotificationFetchers,
  checkUserHasUnreadNotificationFetchers,
  markNotificationAsReadFetchers,
  getUserDepositRequestAuthFetchers,
  checkUserKbPointsFetchers,
  checkUserOcbPointsFetchers,
  checkUserTreasurerPointsFetchers,
  getHomeBannerFetchers,
};
