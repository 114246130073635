import {
  UserPortfolioResponse,
  UserPortfolioDetailResponse,
  BulkOrderResponse,
  ORDER_TYPE,
  BulkOrderRequest,
} from '@treasurer/common';
import axios from '../apis/axios-instance';

export const getUserPortfolioFetcher = async () => {
  const { data } = await axios.get<UserPortfolioResponse>(
    `/v2/treasurer/social/userPortfolios`,
  );
  return data.data;
};

export const getIndividualUserPortfolioFetcher = async (userId: number) => {
  const { data } = await axios.get<UserPortfolioDetailResponse>(
    `/v2/treasurer/social/userPortfolios/${userId}`,
  );
  return data.data;
};

export const bulkOrderRequestFetcher = async (
  body: BulkOrderRequest,
  orderType: keyof typeof ORDER_TYPE,
) => {
  try {
    const { data } = await axios.post<BulkOrderResponse>(
      `/v2/api/treasurer/app/item/singlePriceMarket/bulk/${orderType}`,
      body,
    );
    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};
