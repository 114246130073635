import { getHomeBanners } from '@/fetcher/userQueries';
import { useMemo } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

interface EventItem {
  id: number;
  image: string;
  url: string;
  // * 1: active
  active: number;
  createdAt: string;
  updatedAt: string;
  // detail Image
  content1: string;
}

const EVENT_JOIN_BUTTON_TEXT = '이벤트 참여하기';

function DefaultEventLandingPage() {
  const { data: eventItems } = getHomeBanners('popup');

  const parsed = queryString.parse(location.search);
  const targetId = Number(parsed.id);

  const targetEventItem = useMemo(() => {
    return (
      eventItems &&
      eventItems.find((eventItem: EventItem) => {
        return eventItem.id === targetId;
      })
    );
  }, [eventItems]);

  return (
    <Container>
      {targetEventItem && (
        <EventImageWrapper>
          <img src={targetEventItem.content1} />
          <Link to={targetEventItem.url}>
            <EventJoinButton>{EVENT_JOIN_BUTTON_TEXT}</EventJoinButton>
          </Link>
        </EventImageWrapper>
      )}
    </Container>
  );
}

export default DefaultEventLandingPage;

const EventJoinButton = styled.button`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  background: rgb(255, 217, 62);
  font-size: 20px;
  font-weight: 700;
  border-radius: 5px;
  padding: 25px 0;
  text-align: center;
  width: 89%;
  max-width: 490px;
  cursor: pointer;
`;

const EventImageWrapper = styled.div`
  max-width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;
