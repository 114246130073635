import styled from 'styled-components';
import { useState, useEffect } from 'react';
import palette from '@/config/styles/color/Color';
import { useIsTablet } from '@/utils/customHook/useIsMobile';
import MarketPageHeader from './MarketPageHeader';
import { TRADE_ITEM_STATUS, MarketItemResponseData } from '@treasurer/common';
import TabMenu from './TabMenu';
import { getMarketItemList } from '@/fetcher/ItemQueries';
import ButtonFilter from '@/Components/Filter/ButtonFilter';
import MarketItemTable from './MarketItemTable';

const itemCategories = [
  { category: 'all', label: '전체' },
  { category: 'recruitment', label: '신규상품' },
  { category: 'metal', label: '원자재' },
  { category: 'bag', label: '가방' },
  { category: 'watch', label: '시계' },
  { category: 'wine', label: '와인' },
  { category: 'other', label: '기타' },
];

const investmentStyles = [
  { category: 'all', label: '전체조각' },
  { category: 'aiRecommend', label: 'AI추천' },
  { category: 'stability', label: '안정적' },
  { category: 'volatility', label: '공격적' },
  { category: 'earlySale', label: '조기매각' },
  { category: 'longInvest', label: '장기소유' },
];

const statusPriority: { [index: number]: number } = {
  101: 1,
  201: 2,
  102: 3,
  202: 4,
  302: 5,
};

const MarketPage = () => {
  const [selectedItemCategory, setSelectedItemCategory] = useState(
    itemCategories[0].category,
  );

  const [selectedInvestmentStyle, setSelectedInvestmentStyle] = useState(
    investmentStyles[0].category,
  );

  useEffect(() => {
    setSelectedInvestmentStyle(investmentStyles[0].category);
  }, [selectedItemCategory]);

  const { data: marketItemData } = getMarketItemList();

  if (!marketItemData) return <div style={{ height: '100vh' }} />;

  const categoryFilteredItems = marketItemData?.filter(
    (item: MarketItemResponseData) => {
      if (item.status === TRADE_ITEM_STATUS.LIQUIDATION_PROCESS) {
        return false;
      }

      if (selectedItemCategory === 'all') {
        return true;
      }
      if (selectedItemCategory === 'recruitment') {
        return item.status === TRADE_ITEM_STATUS.ON_SALE;
      }
      return item.category === selectedItemCategory;
    },
  );

  const fullyFilteredItems = categoryFilteredItems
    ?.filter((item: MarketItemResponseData) => {
      if (selectedInvestmentStyle === 'all') {
        return true;
      }
      return item[selectedInvestmentStyle] === 1;
    })
    .sort((a: MarketItemResponseData, b: MarketItemResponseData) => {
      // compare status
      const statusComparison =
        statusPriority[a.status] - statusPriority[b.status];

      if (statusComparison !== 0) {
        // if the status values are different, use statusPriority for sorting
        return statusComparison;
      } else {
        // if the status values are the same, use createdAt for sorting
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      }
    });

  return (
    <>
      <MarketPageHeader />
      <Container>
        <HeaderSection>
          <TabMenu
            tabMenuComposition={itemCategories}
            selectedCategory={selectedItemCategory}
            setSelectedCategory={setSelectedItemCategory}
          />
          <ButtonFilter
            buttonFilterComposition={investmentStyles}
            selectedFilter={selectedInvestmentStyle}
            setSelectedFilter={setSelectedInvestmentStyle}
          />
        </HeaderSection>

        {fullyFilteredItems?.length ? (
          <MarketItemTable marketItems={fullyFilteredItems} />
        ) : (
          <EmptyCase>선택한 필터에 해당되는 상품이 없습니다.</EmptyCase>
        )}
      </Container>
    </>
  );
};

export default MarketPage;

const Container = styled.div`
  max-width: 792px;
  margin: auto;
  width: 100%;
`;

export const HeaderSection = styled.div`
  padding: 0 16px;
`;

export const EmptyCase = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${palette.gray[2]};
  padding: 0 16px;
`;
