import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import {
  getHomeDataFetcher,
  getNoticeFetcher,
  getNewsFetcher,
  getItemCompListFetcher,
  getDisposalListFetcher,
} from './HomeFetchers';
import { STALE_ONE_MINIUTES } from '../Queries/QueriesConst';
import {
  DisposalList,
  ItemCompListMap,
  HomeData,
  News,
  Notice,
} from '../types/Home/Types';

const getHomeData = (
  options?: UseQueryOptions<HomeData, AxiosError, HomeData, ['homeData']>,
) =>
  useQuery(['homeData'], getHomeDataFetcher, {
    staleTime: STALE_ONE_MINIUTES,
    ...options,
  });

const getNotice = (
  options?: UseQueryOptions<Notice[], AxiosError, Notice[], ['homeNotice']>,
) =>
  useQuery(['homeNotice'], getNoticeFetcher, {
    staleTime: STALE_ONE_MINIUTES,
    ...options,
  });
const getNews = (
  options?: UseQueryOptions<News[], AxiosError, News[], ['homeNews']>,
) =>
  useQuery(['homeNews'], getNewsFetcher, {
    staleTime: STALE_ONE_MINIUTES,
    ...options,
  });

const getItemCompList = (
  options?: UseQueryOptions<
    ItemCompListMap,
    AxiosError,
    ItemCompListMap,
    ['itemComp']
  >,
) =>
  useQuery(['itemComp'], getItemCompListFetcher, {
    staleTime: STALE_ONE_MINIUTES,
    ...options,
  });

const getDisposalList = (
  options?: UseQueryOptions<
    DisposalList,
    AxiosError,
    DisposalList,
    ['getDisposalItem']
  >,
) =>
  useQuery(['getDisposalItem'], getDisposalListFetcher, {
    staleTime: STALE_ONE_MINIUTES,
    ...options,
  });
const GetMarketItemsAll = (
  options?: UseQueryOptions<{}[], AxiosError, {}[], ['getDisposalItem']>,
) => {};
export default {};
export {
  getHomeData,
  getNotice,
  getNews,
  getItemCompList,
  getDisposalList,
  GetMarketItemsAll,
};

// 'UseQueryResult<GetHomeData>, AxiosError<any>>' 형은
// 'UseQueryResult<HomeData, AxiosError<any>>' 형식에 할당할 수 없습니다.
