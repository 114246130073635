export const STALE_TEN_SECONDS = 10 * 1000;
export const STALE_THIRTY_SECONDS = 30 * 1000;
export const STALE_ONE_MINIUTES = 60 * 1000;
export const STALE_TEN_MINIUTES = 60 * 1000 * 10;

export const ITEM_COMP_DUMMY = {
  id: 0,
  brand: '',
  model: '',
  ticker: '',
  image1: '',
  backgroundImage: '',
  thumbnail: '',
  status: 0,
  recruitmentAmount: 0,
  minimumAmount: 0,
  soldAmount: 0,
  startDate: '',
  time: 0,
  endDate: '',
  priority: 0,
  lastTradePrice: 0,
  aiRecommend: 0,
  stability: 0,
  volatility: 0,
  earlySale: 0,
  longInvest: 0,
  partial: '',
  divergence: '',
  createdAt: '',
  appraisalPrice: 0,
  currentSingleUnitPrice: 0,
  totalPieceCount: 0,
};
