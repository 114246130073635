import styled from '@emotion/styled';
import { useMediaQuery } from '@material-ui/core';

import { Dispatch } from 'react';
import CustomBottomModal from '../Modal/CustomBottomModal';
import { banks, BankName } from '../../config/banks';
import CustomModal from '../Modal/CustomModal';

import BankIconSheet from '../../assets/images/BankIcons.png';

interface BankSelectionModalProps {
  registerModalOpen: boolean;
  onClose?: () => void;
  setBankName: Dispatch<string | null>;
  setBankCode: Dispatch<string | null>;
  setNumpadModalOpen: Dispatch<boolean>;
}

const Title = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  padding-top: 16px;
  padding-bottom: 36px;
`;

const ButtonList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px;
`;

const Button = styled.button`
  background-color: #f6f6f7;
  border-radius: 5px;
  padding: 16px;
  color: #000000;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 16px;

  width: calc(50% - 8px);
  height: 50px;

  &:nth-of-type(2n) {
    margin-right: 0;
  }

  &:hover {
    background-color: #ff8a22;
    color: #ffff;
  }

  display: flex;
  align-items: center;
`;

const SpriteIcon = styled.div`
  background: url(${BankIconSheet});
  background-size: 230px;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-right: 20px;

  &.카카오뱅크 {
    background-position: -3px -1px;
  }

  &.NH농협은행 {
    background-position: -40px -1px;
  }

  &.신한은행 {
    background-position: -80px -1px;
  }

  &.제주은행 {
    background-position: -80px -1px;
  }

  &.IBK기업은행 {
    background-position: -120px -1px;
  }

  &.우리은행 {
    background-position: -158px -1px;
  }

  &.SC제일은행 {
    background-position: -198px -1px;
  }

  &.대구은행 {
    background-position: -3px -40px;
  }

  &.부산은행 {
    background-position: -40px -41px;
  }

  &.경남은행 {
    background-position: -40px -41px;
  }

  &.광주은행 {
    background-position: -80px -40px;
  }

  &.전북은행 {
    background-position: -80px -40px;
  }

  &.새마을금고 {
    background-position: -118px -40px;
  }

  &.산업은행 {
    background-position: -158px -40px;
  }

  &.우체국 {
    background-position: -1px -79px;
  }

  &.신협은행 {
    background-position: -40px -79px;
  }

  &.수협은행 {
    background-position: -79px -79px;
  }

  &.씨티은행 {
    background-position: -119px -80px;
  }

  &.케이뱅크 {
    background-position: -40px -119px;
  }

  &.하나은행 {
    background-position: -157px -80px;
  }

  &.국민은행 {
    background-position: -196px -80px;
  }

  &.토스뱅크 {
    background-position: -196px -41px;
  }
`;

const BankSelectionModal = ({
  registerModalOpen,
  onClose,
  setBankName,
  setBankCode,
  setNumpadModalOpen,
}: BankSelectionModalProps) => {
  const options = Object.keys(banks) as unknown as BankName[];

  const isMobile = useMediaQuery('(max-width: 768px)');

  const onSelect = (selectedOption: BankName) => {
    setBankName(selectedOption);
    setBankCode(banks[selectedOption]);
    setNumpadModalOpen(true);
  };

  const BankSelectionModalContent = () => (
    <>
      <Title>계좌를 연결할 은행을 선택해 주세요.</Title>
      <ButtonList>
        {options.map((option: BankName) => (
          <Button
            key={`bankaccount-${option}`}
            type="button"
            onClick={() => {
              onSelect(option);
              onClose?.();
            }}
          >
            <SpriteIcon className={option} />
            <div className="kakao" />
            {option}
          </Button>
        ))}
      </ButtonList>
    </>
  );

  return (
    <>
      {isMobile ? (
        <CustomBottomModal
          modalOpen={registerModalOpen}
          onRequestClose={onClose}
        >
          <BankSelectionModalContent />
        </CustomBottomModal>
      ) : (
        <CustomModal
          modalOpen={registerModalOpen}
          onRequestClose={onClose}
          customWidth="500px"
        >
          <BankSelectionModalContent />
        </CustomModal>
      )}
    </>
  );
};

export default BankSelectionModal;
