import axios from './axios-instance';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  signUp: (email, password, phoneNumber, refereeCode, source, marketing) =>
    axios.post('api/treasurerWeb/sign/signup', {
      email,
      password,
      phoneNumber,
      refereeCode,
      source,
      marketing,
    }),
  login: (email, password) =>
    axios.post('api/treasurerWeb/sign/login', { email, password }),
  reSendEmailUser: (email) =>
    axios.post('api/treasurerWeb/sign/resend/email', { email }),
  requestAuthCode: (phoneNumber) => {
    const replacePhoneNumber = phoneNumber.replace(/-/gi, '');

    return axios.get(
      `/api/treasurerWeb/sign/phoneNumberLogin/${replacePhoneNumber}`,
    );
  },
  phoneNumberLogin: (phoneNumber, code) =>
    axios.post(`/api/treasurerWeb/sign/phoneNumberLogin`, {
      phoneNumber,
      code,
    }),
  reSendEmailConfirm: (email) =>
    axios.post(`/api/treasurerWeb/sign/reEmailConfirm`, { email }),
  logout: (accessToken) =>
    // 임시 api
    axios.post('/logout', { accessToken }),
  userKycRequest: (name, jumin, mobileCo, phoneNumber) =>
    // 임시 api
    axios.get(
      `/api/treasurerWeb/kyc/ci?name=${name}&jumin=${jumin}&mobileCo=${mobileCo}&phoneNumber=${phoneNumber}`,
    ),

  confirmKycCode: (authNo, phoneNumber) =>
    // 임시 api
    axios.post(`/api/treasurerWeb/kyc/ci`, { authNo, phoneNumber }),
};
