import { useState } from 'react';
import styled from 'styled-components';
import AppLink from '@/Components/AppLink';
import AppOnlyModal from '@/Components/Modal/AppOnlyModal';

const isRN = (window as any).isRNWebView;

interface CtaButtonProps {
  eventName: string;
  link: string;
  isAppOnlyEvent: boolean;
  buttonText: string;
  buttonColor: string;
  buttonTextColor: string;
}

const CtaButton = ({
  eventName,
  link,
  isAppOnlyEvent,
  buttonText,
  buttonColor,
  buttonTextColor,
}: CtaButtonProps) => {
  const [isAppOnlyModalOpen, setIsAppOnlyModalOpen] = useState(false);

  const onClickBannerInBrowser = () => {
    if (!isRN) setIsAppOnlyModalOpen(true);
  };

  return isRN || !isAppOnlyEvent ? (
    <AppLink to={link} notItemPage={link.includes('/item/') ? false : true}>
      <FloatingButton
        buttonColor={buttonColor}
        buttonTextColor={buttonTextColor}
      >
        {buttonText}
      </FloatingButton>
    </AppLink>
  ) : (
    <>
      <FloatingButton
        onClick={onClickBannerInBrowser}
        buttonColor={buttonColor}
        buttonTextColor={buttonTextColor}
      >
        {buttonText}
      </FloatingButton>

      {isAppOnlyModalOpen && (
        <AppOnlyModal
          featureName={eventName}
          isModalOpen={isAppOnlyModalOpen}
          onModalClose={() => setIsAppOnlyModalOpen(false)}
        />
      )}
    </>
  );
};

interface DefaultEventLandingPageProps {
  eventDetailImageSrc: string;
  eventName: string;
  link: string;
  isAppOnlyEvent?: boolean;
  buttonText?: string;
  buttonColor?: string;
  buttonTextColor?: string;
}

const DefaultEventLandingPage = ({
  eventDetailImageSrc,
  eventName,
  link,
  isAppOnlyEvent = false,
  buttonText = '이벤트 참여하기',
  buttonColor = '#FD7103',
  buttonTextColor = '#fff',
}: DefaultEventLandingPageProps) => {
  return (
    <Container>
      <img src={eventDetailImageSrc} alt={eventName} />
      <CtaButton
        eventName={eventName}
        link={link}
        isAppOnlyEvent={isAppOnlyEvent}
        buttonText={buttonText}
        buttonColor={buttonColor}
        buttonTextColor={buttonTextColor}
      />
    </Container>
  );
};

export default DefaultEventLandingPage;

const Container = styled.div`
  max-width: 550px;
  margin: 0 auto;
  padding-top: 17px;
`;

const FloatingButton = styled.div<{
  buttonColor: string;
  buttonTextColor: string;
}>`
  background: ${(props) => props.buttonColor};
  color: ${(props) => props.buttonTextColor};
  font-size: 20px;
  font-weight: 700;
  border-radius: 5px;
  padding: 25px 0;
  text-align: center;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  width: 89%;
  max-width: 490px;
  cursor: pointer;
`;
