import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { ArrowForwardIos } from '@material-ui/icons';

const LittleBannerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 1036px;
  height: 50px;
  margin-top: 36px;
  position: relative;
  border: 1px solid #e5e5e5;
  border-radius: 40px;
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.17);
  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 90%;
    margin-top: 12px;
    height: 40px;
  }
`;
const LittleBannerContainerSkeleton = styled(Skeleton)`
  display: flex;
  align-items: center;
  width: 1036px;
  height: 50px;
  margin-top: 36px;
  position: relative;
  border: 1px solid #e5e5e5;
  border-radius: 40px;
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.17);
  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 90%;
    margin-top: 18px;
    height: 40px;
  }
`;
const LittleBannerWrapper = styled.a`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const ArrowForwardIosStyled = styled(ArrowForwardIos)`
  font-size: 16;
  color: #ff8a22;
`;

const BookmarkIconStyled = styled(BookmarkIcon)`
  color: #ff8a22;
  position: absolute;
  top: -18px;
  left: 8%;
`;

const TabletGoTo = styled.p`
  display: flex;
  align-items: center;
  color: #ff8a22;
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;
export default {};
export {
  LittleBannerContainer,
  LittleBannerContainerSkeleton,
  LittleBannerWrapper,
  BookmarkIconStyled,
  ArrowForwardIosStyled,
  TabletGoTo,
  FlexDiv,
};
