import { useCreateCryptoSlave } from '@/Queries/useCreateCryptoSlave';
import { useGetCryptoMasterList } from '@/Queries/useGetCryptoMasterLIst';
import { CreateCryptoSlaveRequest } from '@treasurer/common';

export default function CryptoMasterList() {
  const { data, isLoading } = useGetCryptoMasterList();
  const { mutate: requestCreateSlave } = useCreateCryptoSlave();

  const onClick = (params: CreateCryptoSlaveRequest) => {
    requestCreateSlave(params);
  };

  return !isLoading ? (
    <div>
      <br />
      <strong>현재 등록된 마스터</strong>
      {data.data.map(
        (item: {
          masterListId: number;
          userId: number;
          username: string;
          exchangeName: string;
        }) => {
          return (
            <div>
              <p>{item.username}</p>
              <p>{item.exchangeName}</p>
              <button
                onClick={() =>
                  onClick({
                    masterListId: item.masterListId,
                  })
                }
                style={{
                  backgroundColor: 'red',
                  padding: '4px',
                }}
              >
                Copy Order
              </button>
            </div>
          );
        },
      )}
    </div>
  ) : (
    <div />
  );
}
