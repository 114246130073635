import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import AppRouter from './Router';
import GlobalStyles from './GlobalStyles';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
} from 'chart.js';

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init('141402401294739', null, options);

const tagManagerArgs = {
  gtmId: 'GTM-P8QFBQQ',
};

TagManager.initialize(tagManagerArgs);
ChartJS.register(LinearScale, PointElement, LineElement, Legend);
const queryClient = new QueryClient();

const App = () => (
  <div>
    <QueryClientProvider client={queryClient}>
      <GlobalStyles />
      <AppRouter />
      <ReactQueryDevtools
        panelProps={{ style: { color: 'red', backgroundColor: 'black' } }}
      />
    </QueryClientProvider>
  </div>
);

export default App;
