import styled from 'styled-components';
import { FlexColumn, FlexRowCentered } from '@/styles/CommonStyledComponents';
import ImageWrapper from './ImageWrapper';

const ItemProfileLayout = ({
  imgSrc,
  firstRowText,
  secondRowText,
  alt,
  grayBackground,
}: {
  imgSrc: string;
  firstRowText: React.ReactNode;
  secondRowText: React.ReactNode;
  alt?: string;
  grayBackground?: boolean;
}) => {
  return (
    <ProfileContainer>
      <ImageWrapper
        src={imgSrc}
        alt={alt}
        width="52px"
        height="52px"
        padding="7px"
        backgroundColor={grayBackground ? '#f9f9f9' : undefined}
      />

      <ProfileTextContainer>
        <ProfileText>{firstRowText}</ProfileText>
        <ProfileText>{secondRowText}</ProfileText>
      </ProfileTextContainer>
    </ProfileContainer>
  );
};

export default ItemProfileLayout;

const ProfileContainer = styled(FlexRowCentered)`
  gap: 12px;
  width: 100%;
`;

const ProfileTextContainer = styled(FlexColumn)`
  gap: 5px;
  overflow: hidden;
  justify-content: space-between;
`;

const ProfileText = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
