import { BankCode } from '@/config/banks';
import { getUserInfo } from '@/fetcher/userQueries';
import { getBankLogo, getBankNameByCode } from '@/utils/bankHandler';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';
import { ReactComponent as PrecautionIcon } from '../../assets/images/precautionIcon.svg';

const LinkedVirtualAccountDepositPage = () => {
  const [copied, setCopied] = useState(false);

  const { data: userInfo, isLoading: isUserInfoLoading } = getUserInfo();

  if (isUserInfoLoading) return null;

  const userName = userInfo?.name;
  const virtualAccountNumber = userInfo?.virtualAccountNumber;
  const personalAccountBankCode = userInfo?.bankCode as BankCode;
  const personalAccountNumber = userInfo?.account;

  const personalAccountBankName = getBankNameByCode(personalAccountBankCode);

  const isSuccessCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
      // setDepositNumberModalOpen(false); // automatically close modal after copying account number
    }, 1500);
  };

  const VirtualAccountDepositModalContent = () => (
    <>
      <VirtualAccountInfo>
        <Title>트레져러 입금 계좌번호</Title>

        <VirtualAccountNumber>
          <BankLogo>{getBankLogo({ bankCode: '011' })}</BankLogo>

          <div>
            NH 농협은행
            <br />
            {virtualAccountNumber}
            <p>예금주: 트레져{userName}</p>
          </div>
        </VirtualAccountNumber>

        <CopyToClipboard
          text={`NH농협은행 ${virtualAccountNumber}`}
          onCopy={isSuccessCopy}
        >
          <CopyButton>계좌번호 복사</CopyButton>
        </CopyToClipboard>
      </VirtualAccountInfo>

      <PersonalAccountInfo>
        <Title>{userName}님의 인증 완료 계좌</Title>

        <PersonalAccountNumber>
          <BankLogo>
            {getBankLogo({ bankCode: personalAccountBankCode })}
          </BankLogo>

          <div>
            {personalAccountBankName}
            <p>
              {personalAccountBankName} · {personalAccountNumber}
            </p>
          </div>
        </PersonalAccountNumber>

        <Precaution>
          <PrecautionIcon />
          반드시 연결한 출금계좌 은행 앱에서 NH농협은행 입금계좌로 희망금액을
          이체해주세요.
        </Precaution>

        <FinePrint>
          <p>입금 계좌 안내 및 유의사항</p>
          <ul>
            <li>
              2022년 12월 14일부터 입금계좌가 NH농협 가상계좌로 전환되었습니다.
              기존 트레져러 계좌로 예치금 입금이 불가합니다.
            </li>
            <li>
              예치금 입금 후 24시간이 지나지 않은 금액에 대한 출금 건은
              보이스피싱 예방 및 자금 세탁 방지를 위해 출금 취소 처리 됩니다.
            </li>
            <li>
              은행 전산 시스템 상태에 따라 최소 10분 내외의 시간이 걸릴 수
              있습니다.
            </li>
          </ul>
        </FinePrint>
      </PersonalAccountInfo>

      {copied && (
        <CopySuccessMessage>계좌번호가 복사되었습니다.</CopySuccessMessage>
      )}
    </>
  );

  return <VirtualAccountDepositModalContent />;
};

export default LinkedVirtualAccountDepositPage;

const VirtualAccountInfo = styled.div`
  margin-top: 30px;
  padding: 11px 20px 20px 20px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #080808;
  margin-bottom: 12px;
  margin-left: 4px;
`;

const AccountNumber = styled.div`
  border-radius: 8px;
  padding: 16px;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  display: flex;
  align-items: center;

  p {
    font-weight: 400;
    color: #888888;
  }
`;

const VirtualAccountNumber = styled(AccountNumber)`
  border: 1px solid #e5e5e5;

  p {
    font-size: 13px;
  }
`;

const BankLogo = styled.div`
  margin-right: 16px;
  width: 44px;
  height: 44px;
`;

const Button = styled.button`
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  padding: 18px 0;
  width: 100%;
`;

const CopyButton = styled(Button)`
  border: 1px solid #080808;
  color: #080808;
  margin-top: 12px;
`;

const ConfirmButton = styled(Button)`
  background-color: #fe8824;
  color: #ffffff;
`;

const PersonalAccountInfo = styled.div`
  padding: 20px;
  background: #f7f7f7;
`;

const PersonalAccountNumber = styled(AccountNumber)`
  background: #ffffff;

  p {
    font-size: 11px;
  }
`;

const Precaution = styled.div`
  display: flex;
  gap: 4px;
  color: #e44340;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  word-break: keep-all;
  margin-top: 12px;
  margin-bottom: 40px;
`;

const FinePrint = styled.div`
  border-radius: 10px;
  color: #363b48;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  word-break: keep-all;
  margin-bottom: 54px;

  p {
    font-weight: 700;
  }

  li {
    list-style-type: disc;
    list-style-position: outside;
    margin-left: 16px;
  }
`;

const CopySuccessMessage = styled.div`
  font-size: 12px;
  background: #484f61;
  color: #ffffff;
  backdrop-filter: blur(9.5px);
  border-radius: 10px;
  padding: 20px 0;
  text-align: center;
  position: absolute;
  bottom: 90px;
  width: calc(100% - 40px);
  margin: 20px;
  max-width: 630px;
`;
