import AppLink from '@/Components/AppLink';
import styled from '@emotion/styled';
import { Snackbar } from '@mui/material';
import { Link } from 'react-router-dom';

type status = {
  backgroundColor: string;
  colors: string;
};

type isVoteDetail = {
  isVoteDetail: boolean;
};

type vote = {
  vote?: boolean;
};

export const ItemStatusBack = styled.h1`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    margin-top: 12px;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
  }
`;

export const VoteContainer = styled.div`
  width: 100%;
  padding: 20px;
  min-height: 100vh;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    padding: 20px 16px;
  }
`;

export const VoteImg = styled.img`
  width: 65px;
  height: 65px;
  aspect-ratio: 1/1;
`;

export const VoteTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
`;

export const VoteKorTitle = styled.h3`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
`;

export const VoteDate = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #484f61;
`;

export const VoteCard = styled.div<isVoteDetail>`
  width: 100%;
  max-width: 750px;
  height: ${(props) => (props.isVoteDetail ? 'auto' : '183px')};
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: ${(props) => (props.isVoteDetail ? '0px' : '20px 16px')};
  backdrop-filter: blur(19px);
  box-shadow: ${(props) =>
    props.isVoteDetail ? 'none' : '1px 2px 16px rgba(72, 79, 97, 0.1)'};
  border-radius: 10px;
  margin-bottom: 20px;
`;

export const VoteStatus = styled.div<status>`
  max-width: max-content;
  padding: 4px 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  border-radius: 3px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.colors};
`;

export const VoteCriteria = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #484f61;
`;

export const VoteRate = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  margin-top: 4px;
  color: #ffa41b;

  span {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const DisplayColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LargeGrayTxt = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #484f61;
`;

export const MiddleGrayTxt = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #484f61;
`;

export const SmallGrayTxt = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #484f61;
`;

export const BigBoldTxt = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
`;

export const LargeBoldTxt = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #353c49;
`;

export const MiddleTxt = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

export const VoteProgress = styled.div`
  min-width: 4px;
  height: 24px;
  border-radius: 0px 4px 4px 0px;
`;

export const VoteDescWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  background: rgba(72, 79, 97, 0.03);
  border-radius: 4px;
  padding: 20px 16px 0px 16px;

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #ff8a22;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const VoteButtonWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px 16px;
  background-color: #fff;
  z-index: 999;
`;

export const VoteButton = styled.button<vote>`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.vote ? '#a3a7b0' : '#ff8a22')};
  border-radius: 5px;
  filter: ${(props) =>
    props.vote
      ? 'drop-shadow(0px 4px 16px rgba(72, 79, 97, 0.2))'
      : 'drop-shadow(0px 4px 16px rgba(255, 138, 34, 0.4))'};

  &:disabled {
    background: #a3a7b0;
    filter: drop-shadow(0px 4px 16px rgba(72, 79, 97, 0.2));
    span {
      color: rgba(255, 255, 255, 0.5);
    }
  }
`;

export const VoteOXBtn = styled.button`
  height: 188px;
  width: 100%;
  background: rgba(33, 62, 133, 0.2);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const VoteTabButton = styled.button`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ff8a22;
  border-radius: 5px;

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
`;

export const VoteResultContainer = styled.div`
  width: 100%;
  /* height: 100px; */
  padding: 20px 16px;
  background: rgba(255, 138, 34, 0.1);
  border-radius: 5px;
  font-size: 14px;
  line-height: 18px;

  span {
    font-weight: 500;
  }

  p {
    margin-top: 6px;
    font-weight: 400;
  }
`;

export const VoteResfesh = styled.button`
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }

  .refresh {
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: running;
  }

  padding: 0px;
  margin-left: 7px;
`;

export const StyledSnackbar = styled(Snackbar)`
  &.MuiSnackbar-root {
    bottom: 85px;
  }

  .MuiSnackbarContent-root {
    height: 54px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(19px);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 999999;

    @media (max-width: 768px) {
      max-width: 350px;
    }
  }

  .MuiSnackbarContent-message {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    text-align: center;
    color: #353c49;
  }
`;
