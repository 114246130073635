import styled from 'styled-components';
import ReactLoading from 'react-loading';

interface FullLoadingProps {
  backgroundDark?: boolean;
}

const SpinnerBox = styled.div<FullLoadingProps>`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999;

  background-color: ${(backgroundDark) =>
    backgroundDark ? 'rgba(29, 29, 29, 0.5)' : 'transparent'};
`;

const FullLoading = ({ backgroundDark }: FullLoadingProps) => (
  <>
    <SpinnerBox backgroundDark={backgroundDark}>
      <ReactLoading type="spin" color="#FF8A22" height={50} width={50} />
    </SpinnerBox>
  </>
);

export default FullLoading;
