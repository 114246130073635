import { isNil, last } from 'lodash';
import { Link, LinkProps } from 'react-router-dom';
import { NAVIGATION_TABS } from '@treasurer/common';

interface BaseLinkProps extends LinkProps {
  status?: number;
  notItemPage?: boolean;
  title?: string;
  isLogout?: boolean;
}

const AppLink = (baseLinkProps: BaseLinkProps) => {
  const { to, style, children, status, title, isLogout } = baseLinkProps;
  let { notItemPage } = baseLinkProps;
  notItemPage ||= false;

  const id = last(to.toString().split('|'));

  const isRN = (window as any).isRNWebView || false;
  const isHomeV2 = (window as any).homeV2 || false;
  const isWithinEmbeddedWebView = (window as any).isEmbeddedWebView || false;
  const isNavTab = (link: string) =>
    Object.values(NAVIGATION_TABS).includes(link);

  const postNotItemMessage = () => {
    if (isNavTab(to.toString())) {
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({
          to: to.toString(),
          type: 'NAVTAB_EVENT',
          isHomeV2,
        }),
      );
    } else if (to.toString().startsWith('PortfolioProfileScreen')) {
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({
          to: to.toString().split('|')[0],
          type: 'APPLINK_EVENT',
          isHomeV2,
          userId: id,
        }),
      );
    } else if (to.toString().startsWith('SellPriceProposalVoteScreen')) {
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({
          to: to.toString().split('|')[0],
          type: 'APPLINK_EVENT',
          isHomeV2,
          proposalId: id,
        }),
      );
    } else {
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({
          to: to.toString().split('|')[0],
          type: 'APPLINK_EVENT',
          isHomeV2,
          tradeItemId: id,
        }),
      );
    }
    return;
  };

  const postItemMessage = () => {
    if (isWithinEmbeddedWebView) {
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({ to, type: 'ITEMPAGE_EVENT', isHomeV2 }),
      );
      return;
    } else if (isNil(title)) {
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({ to, type: 'ITEMPAGE_EVENT', isHomeV2 }),
      );
    } else if (isLogout) {
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({ to, type: 'LOGOUT_EVENT', isHomeV2 }),
      );
    } else {
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({ to, type: 'GENERAL_EVENT', isHomeV2, title }),
      );
    }
  };

  if (!isRN) {
    return (
      <Link {...baseLinkProps} style={style}>
        {children}
      </Link>
    );
  }

  if (isWithinEmbeddedWebView) {
    return (
      <div style={style} onClick={postItemMessage}>
        {children}
      </div>
    );
  }

  if (isRN && notItemPage) {
    return (
      <div style={style} onClick={postNotItemMessage}>
        {children}
      </div>
    );
  }

  if (isRN && isHomeV2) {
    return (
      <div style={style} onClick={postItemMessage}>
        {children}
      </div>
    );
  }

  return (
    <Link {...baseLinkProps} style={style}>
      {children}
    </Link>
  );
};

export default AppLink;
