import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const globalStyles = createGlobalStyle`
    /* spoqaHanSansNeo */

    @font-face {
        font-family: 'Spoqa Han Sans Neo', 'sans-serif';
        font-style: 'bold';
        font-weight: 700;
        src: url('../assets/fonts/SpoqaHanSansNeo-Bold.woff2') format('woff2');
        src: url('../assets/fonts/SpoqaHanSansNeo-Bold.woff') format('woff');
        src: url('../assets/fonts/SpoqaHanSansNeo-Bold.ttf') format('truetype');
    }

    @font-face {
        font-family: 'Spoqa Han Sans Neo', 'sans-serif';
        font-style: 'normal';
        font-weight: 500;
        src: url('../assets/fonts/SpoqaHanSansNeo-Medium.woff2') format('woff2');
        src: url('../assets/fonts/SpoqaHanSansNeo-Medium.woff') format('woff');
        src: url('../assets/fonts/SpoqaHanSansNeo-Medium.ttf') format('truetype');
    }

    @font-face {
        font-family: 'Spoqa Han Sans Neo', 'sans-serif';
        font-style: 'normal';
        font-weight: 400;
        src: url('../assets/fonts/SpoqaHanSansNeo-Regular.woff2') format('woff2');
        src: url('../assets/fonts/SpoqaHanSansNeo-Regular.woff') format('woff');
        src: url('../assets/fonts/SpoqaHanSansNeo-Regular.ttf') format('truetype');
    }

    @font-face {
        font-family: 'Spoqa Han Sans Neo', 'sans-serif';
        font-style: 'normal';
        font-weight: 300;
        src: url('../assets/fonts/SpoqaHanSansNeo-Light.woff2') format('woff2');
        src: url('../assets/fonts/SpoqaHanSansNeo-Light.woff') format('woff');
        src: url('../assets/fonts/SpoqaHanSansNeo-Light.ttf') format('truetype');
    }

    @font-face {
        font-family: 'Spoqa Han Sans Neo', 'sans-serif';
        font-style: 'normal';
        font-weight: 100;
        src: url('../assets/fonts/SpoqaHanSansNeo-Thin.woff2') format('woff2');
        src: url('../assets/fonts/SpoqaHanSansNeo-Thin.woff') format('woff');
        src: url('../assets/fonts/SpoqaHanSansNeo-Thin.ttf') format('truetype');
    }


    ${reset};
    a {
        text-decoration: none;
        color: inherit;
    }
    * {
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        /* padding: 0px;
        margin: 0px; */
    }
    html,
    body {
        -ms-text-size-adjust: none;
        -moz-text-size-adjust: none;
        -webkit-text-size-adjust: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        -webkit-overflow-scrolling: touch;
    }
    html {
        min-height: 100vh;
        /* height: 100%; */
    }
    body {
        font-family: 'Spoqa Han Sans Neo', -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Spoqa Han Sans Neo",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        /* font-family: 'Spoqa Han Sans Neo', 'sans-serif'; */
        min-height: 100vh;
        /* height: 100%; */
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 14px;
        padding-top: 60px;
        overflow-x: hidden;
        color: #353C49;
        -ms-overflow-style: none;
        -webkit-tap-highlight-color: transparent;
        /* -moz-osx-font-smoothing: auto; */
        @media (max-width: 1024px) {
            padding-top: 15px;
        }

        /* 개발환경에서 간혹 버튼 클릭 안 되는 현상 해결 */
        iframe {
            pointer-events: none;
        }
    }

    /* html, body { overflow-x: hidden; } */

    h1,
    h2,
    b {
        font-weight: 600;
    }
    
    input,
    button {
        border: none;
        &:focus {
            outline: none;
        }
    }

    input {
        /* -webkit-appearance: none; */
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* input[type=button] {
        -webkit-appearance: none;   
    } */

    /* input[type=submit] {-webkit-appearance:none;} */
    
    button {
        cursor: pointer;
        background-color: transparent;
    }

    li {
        list-style: none;
    }

    .ReactModal__Overlay,
    .ReactModal__Overlay--after-open {
        z-index: 9999 !important;
    }

    .swiper-wrapper {
        div {
            &:is(:last-child) {
                /* display: none; */
            }
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        background-color: hsla(0,0%,100%,.2) !important;
        width: 50px;
        height: 50px;
        border-radius: 30px;
        color: black;
        
        ::after {
            font-size: 24px !important;
        }
    }
    /* input, textarea, button { appearance: none; -moz-appearance: none; -webkit-appearance: none; border-radius: 0; -webkit-border-radius: 0; -moz-border-radius: 0; } */

    button {
        -webkit-appearance: none;
    }
    ::-webkit-scrollbar { display: none; }
`;

export default globalStyles;
