import styled from 'styled-components';
import { FlexColumn, SpaceBetween } from '@/styles/CommonStyledComponents';

const Label = styled.div``;
const Value = styled.div``;

interface LabelValueRowProps {
  label: string | React.ReactNode;
  value: string | React.ReactNode;
  labelStyles?: React.CSSProperties;
  valueStyles?: React.CSSProperties;
}

const LabelValueRow: React.FC<LabelValueRowProps> = ({
  label,
  value,
  labelStyles,
  valueStyles,
}) => (
  <SpaceBetween>
    <Label style={labelStyles}>{label}</Label>
    <Value style={valueStyles}>{value}</Value>
  </SpaceBetween>
);

interface LabelValueListProps {
  items: LabelValueRowProps[];
  rowGap?: string;
  labelStyles?: React.CSSProperties;
  valueStyles?: React.CSSProperties;
}

function LabelValueList({
  items,
  rowGap = '16px',
  labelStyles,
  valueStyles,
}: LabelValueListProps) {
  return (
    <FlexColumn gap={rowGap}>
      {items.map((item, index) => (
        <LabelValueRow
          key={index}
          label={item.label}
          value={item.value}
          labelStyles={labelStyles}
          valueStyles={valueStyles}
        />
      ))}
    </FlexColumn>
  );
}

export default LabelValueList;
