import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ArrowBack } from '../assets/images/ArrowBack.svg';
import FaqComponent from '../Components/FaqComponent';
import NestedWebViewHeader from '@/Components/Common/NestedWebViewHeader';

const CustomerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  margin-bottom: 20px;
`;

const CustomrtWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 22px;
  border-bottom: 1px solid rgba(72, 79, 97, 0.2);
`;

const CustomerTitle = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  padding-bottom: 10px;
`;

const CustomerText = styled.span`
  font-size: 14px;
  line-height: 18px;
`;

const CustomerButtonWrapper = styled.div`
  padding: 20px 0px 18px 0px;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const CustomerButton = styled.a`
  padding: 0px;
  background: rgba(72, 79, 97, 0.1);
  border: 1px solid rgba(72, 79, 97, 0.3);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 100%;
`;

const CustomerService = () => {
  const history = useHistory();
  return (
    <CustomerContainer>
      <NestedWebViewHeader
        title="고객 센터"
        redirectTo="/more"
        style={{ marginLeft: '-20px' }}
      />

      <CustomrtWrapper>
        <CustomerTitle>궁금한 것이 있나요?</CustomerTitle>
        <CustomerText>친절하고 빠르게 답변해 드립니다.</CustomerText>
        <CustomerButtonWrapper>
          <CustomerButton href="http://pf.kakao.com/_gPRxjs/chat">
            <CustomerText>1:1 채팅 문의</CustomerText>
          </CustomerButton>
        </CustomerButtonWrapper>
        <CustomerText style={{ color: '#484F61' }}>
          운영시간: 평일 10:00 ~ 17:00
        </CustomerText>
        <CustomerText style={{ color: '#484F61' }}>
          주말 및 공휴일은 휴무입니다
        </CustomerText>
      </CustomrtWrapper>
      <FaqComponent contentTitle="자주 물어보는 질문" />
    </CustomerContainer>
  );
};

export default CustomerService;
