import { useCreateCryptoMaster } from '@/Queries/useCreateCryptoMaster';
import { CreateCryptoMasterRequest } from '@treasurer/common';
import styled from 'styled-components';
import CryptoMasterList from './CryptoMasterList';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export default function CryptoPage() {
  const { mutate: requestCreateCryptoMaster, isLoading } =
    useCreateCryptoMaster();
  const onClick = () => {
    window.location.href =
      'https://coinone.co.kr/account/login/?app_id=3eeaabe8-fb99-4911-b760-fd4e95f50905&next=https://treasurer.co.kr/coinone';
  };
  const onClickCreateMaster = (params: CreateCryptoMasterRequest) => {
    requestCreateCryptoMaster(params);
  };
  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <p>crypto</p>
        <button onClick={onClick}>코인원 로그인하기</button>
        <button
          onClick={() => onClickCreateMaster({ exchangeName: 'coinone' })}
        >
          마스터 등록하기
        </button>
      </div>
      <CryptoMasterList />
    </Container>
  );
}
