import styled from 'styled-components';
import FinePrint from '@/Components/Common/FinePrint';
import { usePurchase } from '@/contexts/PurchaseContext';
import { TRADE_ITEM_STATUS } from '@treasurer/common';

const METAL_PURCHASE_FINEPRINT = [
  '총 구매금액은 수수료 합산 금액이며, 최소 구매 단위를 고려해 자동으로 계산된 금액입니다.',
  '거래에 대한 환불이 어렵습니다. 환불을 원할 시, 고객센터로 문의 바랍니다.',
  '거래에 따른 책임은 모두 본인에게 있으며, 이로 발생한 수익과 손실은 모두 본인에게 귀속됩니다.',
];
const RECRUITMENT_PURCHASE_FINEPRINT = [
  '분할소유권 신규상품 오픈 기간 종료 후 최소구매조각에 미달하여 해당 상품에 대한 판매가 완료되지 않는 경우, 7일 이내 구매한 조각이 예치금으로 자동 환불됩니다.',
  '신규상품 오픈이 종료되기 전까지 구매 취소, 환불이 가능합니다. 조각이 모두 판매되어 신규상품 구매가 마감되거나, 오픈 기간이 지난 경우에는 취소가 불가합니다.',
  '거래에 따른 책임은 모두 본인에게 있으며, 이로 발생한 수익과 손실은 모두 본인에게 귀속됩니다.',
];
const SINGLE_PRICE_TX_PURCHASE_FINEPRINT = [
  '단일가로 주문한 조각은 즉시 체결되므로 거래에 대한 취소는 어렵습니다.',
  '단일가 구매 시 수수료는 면제됩니다.',
  '거래에 따른 책임은 모두 본인에게 있으며, 이로 발생한 수익과 손실은 모두 본인에게 귀속됩니다.',
];

function PurchaseFineprint() {
  const { state } = usePurchase();
  const { isMetal, item } = state;

  if (isMetal) {
    return (
      <Wrapper>
        <FinePrint
          title="금·은 서비스 이용 약관"
          listItems={METAL_PURCHASE_FINEPRINT}
        />
      </Wrapper>
    );
  } else if (item.status === TRADE_ITEM_STATUS.ON_SALE) {
    return (
      <Wrapper>
        <FinePrint
          title="신규상품 공동 구매 약관"
          listItems={RECRUITMENT_PURCHASE_FINEPRINT}
        />
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <FinePrint
          title="단일가 공동 구매 약관"
          listItems={SINGLE_PRICE_TX_PURCHASE_FINEPRINT}
        />
      </Wrapper>
    );
  }
}

export default PurchaseFineprint;

const Wrapper = styled.div`
  margin: 20px;
`;
