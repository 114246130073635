/* eslint-disable */

/**
 * OK캐쉬백 화면 호출 함수 ( 되도록 아래 함수를 그대로 이용하시기 바랍니다. )
 * @param mctTrToken           거래 토큰
 * @param resultFunc        결과 리턴 : function( name, authResult )
 */
export default function ocbPointView(mctTrToken, resultFunc) {
  const okCashBackScript = document.createElement('script');
  okCashBackScript.type = 'text/javascript';

  const passwordView = document.querySelector('body');
  window.scrollTo(0, 0);

  const BaseUrl = process.env.REACT_APP_OCB_HOST;
  /**
   * dev-pay.okcashbag.com : 개발 서버
   * stg-pay.okcashbag.com : 스테이지 서버
   * pay.okcashbag.com     : 상용서버
   */
  okCashBackScript.src = `https://${BaseUrl}/js/okcashbagpwdapi.js?${Math.floor(
    Math.random() * 100000 + 1,
  )}`;

  let loaded = false;
  const okCashbagLoaded = function () {
    if (loaded) return; // IE 하위버전 중복 호출 예외 처리.
    loaded = true;

    const ocbClient = new OKcashbagPwdAPI(mctTrToken);

    if (passwordView) {
      passwordView.style.overflow = 'hidden';
    }
    ocbClient.on((name, data) => {
      if (name === 'POINTS_AUTHENTICATED' || name === 'POINTS_AUTH_FAILED') {
        okCashBackScript &&
          okCashBackScript.removeNode &&
          okCashBackScript.removeNode(true);
        okCashBackScript &&
          okCashBackScript.remove &&
          okCashBackScript.remove();
        passwordView.style = '';
      }
      resultFunc && resultFunc(name, data);
    });
    ocbClient.show();

    // 안드로이드 단말기 또는 브라우저 뒤로가기 버튼 눌렀을 때 페이지 이동 대신 OCB 비밀번호 창 닫기
    window.history.pushState(null, '', window.location.pathname);
    window.onpopstate = function (e) {
      ocbClient.forceClose();
    };
  };
  okCashBackScript.onreadystatechange = function () {
    // IE 8~9 처리
    if (
      okCashBackScript.readyState === 'loaded' ||
      okCashBackScript.readyState === 'complete'
    ) {
      okCashbagLoaded();
    }
  };
  okCashBackScript.onload = okCashbagLoaded;
  okCashBackScript.onerror = function () {
    okCashBackScript &&
      okCashBackScript.removeNode &&
      okCashBackScript.removeNode(true);
    okCashBackScript && okCashBackScript.remove && okCashBackScript.remove();
    resultFunc &&
      resultFunc('ERROR', {
        errorObject: { errorCode: -100, errorMessage: '스크립트 로딩 실패' },
      });
  };

  const head = document.head || document.getElementsByTagName('head').item(0);
  head.appendChild(okCashBackScript);
}
