import { useState } from 'react';
import styled from 'styled-components';
import ReactLoading from 'react-loading';
import FullPageModal from '../../Components/Modal/FullPageModal';
import CustomModal from '../../Components/Modal/CustomModal';
import { useIsMobile } from '../../utils/customHook/useIsMobile';
import {
  ModalTitle,
  Row,
  HorizontalLine,
  TotalPrice,
  FinePrint,
  SpinnerBox,
} from '../../Components/Metal/OrderSummaryModalContent';
import HeaderWithCloseModalButton from '../../Components/Common/HeaderWithCloseModalButton';
import { useBulkOrderRequest } from '../../fetcher/socialQueries';
import { ItemOrderInfoProps } from './CopyTradingOrderPage';
import ConfirmModal from '../../Components/ConfirmModal';
import OrderCompleteModal from '../../Components/Order/OrderCompleteModal';

const Content = styled.div`
  padding: 20px;
`;

type IsMobileProps = {
  isMobile: boolean;
};

export const DynamicButton = styled.button<IsMobileProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff8a22;
  color: white;
  font-weight: 700;
  cursor: pointer;
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? '76px' : '50px')};
  font-size: ${({ isMobile }) => (isMobile ? '16px' : '18px')};
  border-radius: ${({ isMobile }) => (isMobile ? '0px' : '5px')};
  ${({ isMobile }) => isMobile && `position: fixed; bottom: 0; `}
`;

type calculatedOrderSummaryProps = {
  sumOrderPrice: number;
  sumOrderFee: number;
  sumTotalPriceWithFee: number;
  nonGoldSumQuantity: number;
  selectedGoldGrams: number;
};

type CopyTradingOrderSummaryModalContentProps = {
  isMobile: boolean;
  selectedItems: ItemOrderInfoProps[];
  requestedAmount: number;
  username: string;
  calculatedOrderSummary: calculatedOrderSummaryProps;
  onRequestClose: () => void;
};

const CopyTradingOrderSummaryModalContent = ({
  username,
  selectedItems,
  requestedAmount,
  calculatedOrderSummary,
  isMobile,
  onRequestClose,
}: CopyTradingOrderSummaryModalContentProps) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const {
    sumOrderPrice,
    sumOrderFee,
    sumTotalPriceWithFee,
    nonGoldSumQuantity,
    selectedGoldGrams,
  } = calculatedOrderSummary;

  const processedSelectedItems = selectedItems.map((item) => ({
    tradeItemId: item.tradeItemId,
    appraisalPrice: item.appraisalPrice,
    quantity: item.quantity,
    totalTransactionPrice: item.totalPriceWithFee,
  }));

  const {
    mutate: requestOrder,
    isLoading: isLoadingRequestOrder,
    error,
  } = useBulkOrderRequest(processedSelectedItems, requestedAmount, 'BID', {
    onSuccess: (result: any) => {
      setIsConfirmationModalOpen(true);
    },
    onError: async (result: any | { result: string }) => {
      setErrorMsg(result.message);
      setErrorModal(true);
    },
  });

  const handleButtonClick = () => {
    setIsButtonDisabled(true);

    requestOrder();
  };

  return (
    <>
      <Content>
        <HeaderWithCloseModalButton
          onRequestClose={onRequestClose}
          isMobile={isMobile}
        />

        <ModalTitle>
          {username} 님 포트폴리오
          <br />
          따라살게요
        </ModalTitle>

        <Row>
          <div>거래조각 {selectedGoldGrams !== 0 && '| 중량'}</div>
          <div>
            {nonGoldSumQuantity.toLocaleString()}개{' '}
            {selectedGoldGrams !== 0 && `| ${selectedGoldGrams}g`}
          </div>
        </Row>

        <Row>
          <div>거래금액</div>
          <div>{sumOrderPrice.toLocaleString()}원</div>
        </Row>

        <Row>
          <div>수수료</div>
          <div>{sumOrderFee?.toLocaleString()}원</div>
        </Row>

        <HorizontalLine />

        <TotalPrice>
          <div>총 거래금액</div>
          <div style={{ color: '#ff8a22' }}>
            {sumTotalPriceWithFee?.toLocaleString()}원
          </div>
        </TotalPrice>

        <FinePrint isMobile={isMobile}>
          <p>단일가 공동구매, 금 서비스 이용 약관</p>
          <ul>
            <li>
              단일가로 주문한 조각은 즉시 체결되므로 거래에 대한 취소는
              어렵습니다.
            </li>
            <li>
              총 구매금액은 수수료 합산 금액<span>(조각 면제, 금 0.7%)</span>
              이며, 최소 구매 단위를 고려해 자동으로 계산된 금액 입니다.
            </li>
            <li>
              금 거래에 대한 환불이 어렵습니다. 환불을 원할 시, 고객센터로
              문의바랍니다.
            </li>
            <li>
              거래에 따른 책임은 모두 본인에게 있으며, 이로 발생한 수익과 손실은
              모두 본인에게 귀속됩니다.
            </li>
          </ul>
        </FinePrint>
      </Content>

      <DynamicButton
        onClick={handleButtonClick}
        disabled={isButtonDisabled}
        isMobile={isMobile}
      >
        {!isLoadingRequestOrder ? (
          '약관 동의 후 구매하기'
        ) : (
          <SpinnerBox>
            <ReactLoading type="spin" color="#ffff" height={35} width={35} />
          </SpinnerBox>
        )}
      </DynamicButton>

      <OrderCompleteModal
        isConfirmationModalOpen={isConfirmationModalOpen}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        orderType="BID"
        redirectTo="/portfolio-curation"
      />

      <ConfirmModal
        modalOpen={errorModal}
        onRequestClose={() => setErrorModal(false)}
        title="따라사기 주문이 정상적으로 처리되지 않았습니다."
        content={errorMsg}
        confirmBtnText="닫기"
        confirmBtnAction={() => {
          setErrorModal(false);
          window.location.reload();
        }}
      />
    </>
  );
};

type CopyTradingOrderSummaryModal = {
  isOrderSummaryModalOpen: boolean;
  setIsOrderSummaryModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  username: string;
  selectedItems: ItemOrderInfoProps[];
  calculatedOrderSummary: calculatedOrderSummaryProps;
  requestedAmount: number;
};

const CopyTradingOrderSummaryModal = ({
  isOrderSummaryModalOpen,
  setIsOrderSummaryModalOpen,
  username,
  selectedItems,
  calculatedOrderSummary,
  requestedAmount,
}: CopyTradingOrderSummaryModal) => {
  const isMobile = useIsMobile();

  return (
    <>
      {isMobile ? (
        <FullPageModal modalOpen={isOrderSummaryModalOpen}>
          <CopyTradingOrderSummaryModalContent
            username={username}
            selectedItems={selectedItems}
            calculatedOrderSummary={calculatedOrderSummary}
            requestedAmount={requestedAmount}
            isMobile={isMobile}
            onRequestClose={() => setIsOrderSummaryModalOpen(false)}
          />
        </FullPageModal>
      ) : (
        <CustomModal
          modalOpen={isOrderSummaryModalOpen}
          onRequestClose={() => setIsOrderSummaryModalOpen(false)}
          customWidth="500px"
          customHeight="700px"
        >
          <CopyTradingOrderSummaryModalContent
            username={username}
            selectedItems={selectedItems}
            calculatedOrderSummary={calculatedOrderSummary}
            requestedAmount={requestedAmount}
            isMobile={isMobile}
            onRequestClose={() => setIsOrderSummaryModalOpen(false)}
          />
        </CustomModal>
      )}
    </>
  );
};

export default CopyTradingOrderSummaryModal;
