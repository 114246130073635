import { useCreateCryptoApiKey } from '@/Queries/useCreateCryptoApiKey';
import { isNil } from 'lodash';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function CoinoneRedirectPage() {
  const parsedResult = queryString.parse(window.location.search);
  const { mutate: requestAccessToken, isLoading } = useCreateCryptoApiKey();
  const history = useHistory();

  useEffect(() => {
    if (
      !isNil(parsedResult.request_token) &&
      typeof parsedResult.request_token === 'string'
    )
      requestAccessToken({
        request_token: parsedResult.request_token,
      });
    history.replace('/crypto-copy');
    return;
  }, []);
  return (
    <div>
      {isLoading && (
        <div>
          <p>요청 진행중입니다.</p>
        </div>
      )}
    </div>
  );
}
