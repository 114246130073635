import { combineReducers } from 'redux';
import user, * as fromUser from './userReducer';
import main, * as fromMain from './mainReducer';

export const getUserAuth = (state) => fromUser.getUserAuth(state.user);
export const getUserInfo = (state) => fromUser.getUserInfo(state.user);

export const getExercises = (state) => fromMain.getMarketList(state.marketList);

export default combineReducers({
  user,
  main,
});
