import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import reducers from '../reducers';

export const history = createBrowserHistory();

export default function initStore() {
  const store = createStore(reducers, applyMiddleware(thunk));

  return store;
}
