import { useState } from 'react';
import styled from 'styled-components';
import {
  FlexColumn,
  LineDivider,
  PaddedContainer,
} from '@/styles/CommonStyledComponents';
import { Disclaimer, SectionTitle } from '../PaymentStyles';
import { PointSystemId, POINT_SYSTEMS } from '../PaymentUtils';
import PaymentInputComponent from './PaymentInputComponent';
import { usePurchase } from '@/contexts/PurchaseContext';
import { ReactComponent as DisclaimerIcon } from '../../../assets/images/icon_exclamation_mark.svg';
import CustomModal from '@/Components/Modal/CustomModal';
import { BulletedList } from '@/Components/Common/FinePrint';

const OptionsContainer = styled(FlexColumn)`
  gap: 16px;
`;

const PointOptionLabel = styled.label`
  display: flex;
  align-items: center;
  color: #424242;
  font-size: 14px;
  font-weight: 700;

  input {
    margin-right: 12px;
  }
`;

const DisclaimerBox = styled(Disclaimer)`
  background: #f5f6f8;
  padding: 12px;
  border-radius: 4px;

  span {
    color: #e44340;
  }
`;

const PointUseItem = ({ item, onOptionChange, onInputChange }: any) => {
  const { state } = usePurchase();
  const { selectedPointSystem } = state;

  const isActive = selectedPointSystem === item.id;
  const showPointInput = isActive && item.id !== 'none';

  return (
    <>
      <PointOptionLabel>
        <input
          type="radio"
          id={item.id}
          name="pointSystem"
          value={item.id}
          checked={isActive}
          onChange={onOptionChange}
        />
        <span>{item.label}</span>
      </PointOptionLabel>

      {showPointInput && (
        <>
          <PaymentInputComponent onInputChange={onInputChange} />
          <DisclaimerBox>
            <DisclaimerIcon />
            결제 시점에 입력한 포인트를 <span>우선 차감</span>합니다.
          </DisclaimerBox>
        </>
      )}
    </>
  );
};

function PointInputSection() {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const { dispatch } = usePurchase();

  const handlePointSystemChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch({
      type: 'SET_POINT_SYSTEM',
      payload: event.target.value as PointSystemId,
    });
  };

  const handlePointAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch({
      type: 'SET_POINT_BUDGET',
      payload: Number(event.target.value),
    });
  };

  return (
    <>
      <PaddedContainer>
        <SectionHeader>
          <SectionTitle>포인트 사용</SectionTitle>
          <HelpButton onClick={() => setIsHelpModalOpen(!isHelpModalOpen)}>
            <DisclaimerIcon />
          </HelpButton>
        </SectionHeader>

        <LineDivider />

        <OptionsContainer>
          {POINT_SYSTEMS.map((item) => (
            <PointUseItem
              key={`${item.id} Point Use Item`}
              item={item}
              onOptionChange={handlePointSystemChange}
              onInputChange={handlePointAmountChange}
            />
          ))}
        </OptionsContainer>
      </PaddedContainer>

      <CustomModal
        modalOpen={isHelpModalOpen}
        onRequestClose={() => setIsHelpModalOpen(false)}
        title="포인트 사용 안내사항"
        showCloseButton
      >
        <BulletedList
          items={[
            '최종 결제 금액에서 입력된 포인트가 우선하여 차감되고, 차액은 트레져러 예치금으로 자동 결제됩니다.',
            '제휴사별 포인트 사용 수수료가 상이합니다. 포인트 사용에 따라 최종 구매 중량 및 결제 금액을 포함한 주문 내용이 실시간으로 변경될 수 있습니다.',
          ]}
        />
      </CustomModal>
    </>
  );
}

export default PointInputSection;

const SectionHeader = styled.div`
  display: flex;
  gap: 4px;
`;

const HelpButton = styled.button``;
