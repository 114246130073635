import styled from 'styled-components';

interface ImageWrapperProps {
  src: string;
  width?: string;
  height?: string;
  padding?: string;
  backgroundColor?: string;
  borderRadius?: string;
  alt?: string;
}

function ImageWrapper({
  src,
  width = '100%',
  height = '100%',
  padding = '0',
  backgroundColor = 'transparent',
  borderRadius = '8px',
  alt = 'Image',
}: ImageWrapperProps) {
  return (
    <Wrapper
      width={width}
      height={height}
      padding={padding}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
    >
      <img src={src} alt={alt} />
    </Wrapper>
  );
}

export default ImageWrapper;

const Wrapper = styled.div<{
  width: string;
  height: string;
  padding: string;
  backgroundColor: string;
  borderRadius: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-width: ${({ width }) => width};
  min-height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ borderRadius }) => borderRadius};

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;
