import { useMyAssetQuery } from '@/fetcher/userQueries';
import CheckCircle from '@material-ui/icons/CheckCircle';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import FullLoading from '../../Components/Loading/FullLoading';
import CustomModal from '../../Components/Modal/CustomModal';
import { user } from '../../config/api';
import { ModalTitle, ModalWrapper, SpaceBetween } from '../../config/style';
import { localGetItem } from '../../utils/handleStorage';
import {
  ArrowFront,
  DeleteButton,
  DeleteContainer,
  DeleteDescWrapper,
  DeleteDivider,
  DeleteFAQWrapper,
  DeleteSuccessContainer,
  DeleteWrapper,
  DeleteWrapperDesc,
  DeleteWrapperTitle,
} from './DeleteStyle';
import DeleteSuccess from './DeleteSuccess';

interface DeleteConfirmProps {
  reason: string;
}

const DeleteConfirm = ({ reason }: DeleteConfirmProps) => {
  const queryClient = useQueryClient();
  const [confirm, setConfirm] = useState(false);
  const [agreeGiveup, setAgreeGiveup] = useState(false);
  const [balanceModalOpen, setBalaceModalOpen] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const token = localGetItem('session');
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      history.push('signin');
    }
  }, []);

  const { data: myAsset, isLoading: isMyAssetLoading } =
    useMyAssetQuery('PRODUCT');

  // eslint-disable-next-line consistent-return
  const handleDelete = async () => {
    try {
      if (!myAsset) {
        return null;
      }

      const result = await user.userDelete(reason);

      if (result.result === 'no user') {
        alert('이미 탈퇴한 유저입니다.');
        history.push('/');
      } else if (result.result === 'success') {
        localStorage.removeItem('session');
        localStorage.removeItem('device');
        queryClient.invalidateQueries();
        setDeleteSuccess(true);
      }
    } catch (e) {
      if (typeof window !== 'undefined') window.console.error(e);
    }
  };

  if (isMyAssetLoading) {
    return <FullLoading />;
  }

  if (!myAsset) {
    return (
      <DeleteSuccessContainer>
        <DeleteWrapperTitle>
          오류가 발생했습니다 다시 시도해주세요
        </DeleteWrapperTitle>
      </DeleteSuccessContainer>
    );
  }

  const totalOwnedPieceCount = myAsset.userCurrentOwnerships
    .filter((v) => v.id !== 1001)
    .reduce((acc, cur) => (acc += cur.quantity), 0);
  const totalMetalWeight = myAsset.userCurrentOwnerships
    .filter((v) => v.id === 1001)
    .reduce(
      (acc, cur) => (acc += cur.quantity / (cur.ownershipQuantityDivider ?? 1)),
      0,
    );
  return (
    <>
      {deleteSuccess ? (
        <DeleteSuccess />
      ) : (
        myAsset && (
          <DeleteContainer>
            <DeleteWrapper>
              <DeleteWrapperTitle>
                탈퇴 전 자산이 남아있다면{' '}
                <span style={{ color: '#FF8A22' }}>
                  조각은 모두 판매, 예치금은 출금 후{' '}
                </span>
                탈퇴를 진행해 주세요.
              </DeleteWrapperTitle>
              <DeleteDivider />
              <SpaceBetween style={{ marginTop: 34, marginBottom: 20 }}>
                <DeleteWrapperTitle>내자산 현황</DeleteWrapperTitle>
                <button onClick={() => history.push('/my')}>
                  <ArrowFront />
                </button>
              </SpaceBetween>
              <SpaceBetween style={{ marginTop: 34, marginBottom: 20 }}>
                <DeleteWrapperDesc>보유조각</DeleteWrapperDesc>
                <DeleteWrapperDesc>
                  {totalOwnedPieceCount.toLocaleString()}개
                </DeleteWrapperDesc>
              </SpaceBetween>
              <SpaceBetween style={{ marginTop: 34, marginBottom: 20 }}>
                <DeleteWrapperDesc>보유중량(금)</DeleteWrapperDesc>
                <DeleteWrapperDesc>{totalMetalWeight}g</DeleteWrapperDesc>
              </SpaceBetween>
              <SpaceBetween style={{ marginTop: 34, marginBottom: 20 }}>
                <DeleteWrapperDesc>예치금</DeleteWrapperDesc>
                <DeleteWrapperDesc>
                  {myAsset.deposit.toLocaleString()}원
                </DeleteWrapperDesc>
              </SpaceBetween>
              <SpaceBetween style={{ marginTop: 34, marginBottom: 20 }}>
                <DeleteWrapperTitle>총 보유자산</DeleteWrapperTitle>
                <DeleteWrapperTitle>
                  {Math.round(myAsset.totalAsset).toLocaleString()}원
                </DeleteWrapperTitle>
              </SpaceBetween>
              <DeleteDivider />
              <div
                style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}
              >
                <button
                  type="button"
                  onClick={() => setAgreeGiveup(!agreeGiveup)}
                  style={{ padding: 0 }}
                >
                  {agreeGiveup ? (
                    <CheckCircle style={{ color: '#FF8A22', fontSize: 24 }} />
                  ) : (
                    <RadioButtonUnchecked
                      style={{ color: '#FF8A22', fontSize: 24 }}
                    />
                  )}
                </button>
                <DeleteWrapperDesc style={{ marginLeft: 16 }}>
                  남은 자산을 포기하고 탈퇴하는데 동의합니다.
                </DeleteWrapperDesc>
              </div>
              <DeleteFAQWrapper></DeleteFAQWrapper>
              <DeleteDescWrapper style={{ paddingTop: 20 }}>
                <DeleteWrapperTitle>
                  현재 사용중인 계정의{' '}
                  <span style={{ color: '#FE8824' }}>
                    거래내역, 보유 쿠폰/포인트, 최근 본 상품 등 모든 정보가 삭제{' '}
                  </span>
                  되며 복구 할 수 없습니다.
                </DeleteWrapperTitle>
                <DeleteWrapperDesc>
                  단, [전자상거래 등에서의 소비자 보호에 관한 법률],
                  [통신비밀보호법]. [정보통신망이용촉진 및 정보보호에 관한 법률]
                  등 법률에 근거하여 거래 관련 권리의무 관계 확인 등을 이유로
                  일정기간 해당 정보를 보유 하여야 하는 경우 보관합니다.
                </DeleteWrapperDesc>
              </DeleteDescWrapper>
              <DeleteDescWrapper>
                <DeleteWrapperTitle>본인인증 회원인 경우</DeleteWrapperTitle>
                <DeleteWrapperDesc>
                  본인인증을 하신 회원의 경우{' '}
                  <span style={{ color: '#FE8824' }}>
                    탈퇴를 하시게 되면 30일간 이내 재가입 및 본인인증이 불가
                  </span>
                  합니다.
                </DeleteWrapperDesc>
                <DeleteWrapperDesc>
                  트레져러의 서비스를 정상적으로 이용하실 수 없습니다
                </DeleteWrapperDesc>
              </DeleteDescWrapper>
              <DeleteDescWrapper>
                <DeleteWrapperTitle>
                  회원 개인정보, 기록 삭제
                </DeleteWrapperTitle>
                <DeleteWrapperDesc>
                  탈퇴 시 트레져러에 등록된 회원님의 개인정보는 모두 삭제되며
                  데이터는 재가입시 복구되지 않습니다.
                </DeleteWrapperDesc>
              </DeleteDescWrapper>
              <div style={{ marginTop: 20 }}>
                <DeleteWrapperDesc>
                  탈퇴 과정에 어려움이 있다면{' '}
                  <button
                    type="button"
                    style={{
                      color: '#FF8A22',
                      textDecoration: 'underline',
                      padding: 0,
                      fontSize: 14,
                      fontWeight: 400,
                      lineHeight: '18px',
                    }}
                    onClick={() => history.push('/customer-service')}
                  >
                    고객센터
                  </button>
                  를 이용해 주세요
                </DeleteWrapperDesc>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', marginTop: 54 }}
              >
                <button
                  type="button"
                  onClick={() => setConfirm(!confirm)}
                  style={{ padding: 0 }}
                >
                  {confirm ? (
                    <CheckCircle style={{ color: '#FF8A22', fontSize: 24 }} />
                  ) : (
                    <RadioButtonUnchecked
                      style={{ color: '#FF8A22', fontSize: 24 }}
                    />
                  )}
                </button>
                <DeleteWrapperDesc style={{ marginLeft: 16 }}>
                  위의 모든 내용을 확인하고 동의합니다
                </DeleteWrapperDesc>
              </div>
              <DeleteButton
                disabled={!confirm || !agreeGiveup}
                style={{ marginTop: 20, marginBottom: 20 }}
                onClick={handleDelete}
              >
                트레져러 탈퇴
              </DeleteButton>
            </DeleteWrapper>
          </DeleteContainer>
        )
      )}
      <CustomModal
        modalOpen={balanceModalOpen}
        onRequestClose={() => setBalaceModalOpen(false)}
      >
        <ModalWrapper>
          <ModalTitle>탈퇴 처리 불가</ModalTitle>
          <DeleteWrapperDesc style={{ color: '#353c49', fontWeight: 500 }}>
            남은 조각 혹은 예치금이 있어 탈퇴처리가 불가합니다.
            <DeleteWrapperDesc>
              {' '}
              조각은 모두 판매, 예치금은 출금 후 탈퇴를 진행해주세요.
            </DeleteWrapperDesc>
          </DeleteWrapperDesc>

          <DeleteButton
            style={{ marginTop: 20 }}
            onClick={() => setBalaceModalOpen(false)}
          >
            확인
          </DeleteButton>
        </ModalWrapper>
      </CustomModal>
    </>
  );
};

export default DeleteConfirm;
