import Modal from 'react-modal';
import styled from 'styled-components';
import { V2_INACTIVE_COLOR } from '../config/color';

interface ConfirmModalProps {
  modalOpen: boolean;
  title?: string;
  content?: string;
  confirmBtnText?: string;
  confirmBtnAction?: () => any;
  onRequestClose?: () => void;
  closeBtnText?: string;
  closeBtnAction?: () => any;
  orderType?: number;
}

type isClose = {
  close?: boolean;
};

const ButtonStyle = styled.button<isClose>`
  width: 100%;
  color: ${(props) => (props.close ? '#fff' : '#484F61')};
  background-color: ${(props) => (props.close ? '#484F61' : '#fff')};
  height: 34px;
  font-size: 16px;
  border: 1px solid #484f61;
  border-radius: 5px;

  &:disabled {
    background-color: ${V2_INACTIVE_COLOR};
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const BoldText = styled.span`
  font-size: 20px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const RegularText = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: #484f61;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ModalContainer = styled.div`
  width: 567px;
  padding: 20px 16px;

  @media (max-width: 1024px) {
    max-width: 100%;
    width: 100%;
    max-height: 70vh;
    overflow-y: auto;
    scroll-behavior: smooth;
    background-color: white;
  }

  @media (max-width: 768px) {
    max-width: 80vw;
    width: 80vw;
  }
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 'none',
    boxShadow: '3px 4px 16px rgba(72, 79, 97, 0.2)',
    borderRadius: '10px',
  },
};

const ConfirmModal = ({
  modalOpen,
  title,
  content,
  confirmBtnText,
  confirmBtnAction,
  onRequestClose,
  closeBtnText,
  closeBtnAction,
}: ConfirmModalProps) => {
  let subtitle: any;

  const afterOpenModal = () => {
    subtitle.style.color = '#000';
  };

  return (
    <Modal
      isOpen={modalOpen}
      onAfterOpen={() => afterOpenModal()}
      style={customStyles}
      onRequestClose={onRequestClose}
    >
      <ModalContainer>
        <div style={{ marginBottom: 20 }}>
          <BoldText ref={(_subtitle) => (subtitle = _subtitle)}>
            {title}
          </BoldText>
        </div>
        <div style={{ marginBottom: 20 }}>
          <RegularText style={{ whiteSpace: 'pre-line', lineHeight: '24px' }}>
            {content}
          </RegularText>
        </div>
        <div style={{ display: 'flex' }}>
          {closeBtnText && (
            <ButtonStyle style={{ marginRight: 10 }} onClick={closeBtnAction}>
              {closeBtnText}
            </ButtonStyle>
          )}
          <ButtonStyle onClick={confirmBtnAction} close={Boolean(closeBtnText)}>
            {confirmBtnText}
          </ButtonStyle>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default ConfirmModal;
