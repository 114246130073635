import { useMediaQuery } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import assets from '../config/assets';

import { ReactComponent as KakaoIcon } from '../assets/images/kakaotalk.svg';
import { ReactComponent as NaverBlogIcon } from '../assets/images/naver_blog.svg';
import { ReactComponent as InstaIcon } from '../assets/images/instagramSvg.svg';

const FooterWrap = styled.footer`
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #484f61;
  padding-bottom: 90px;
  border-top: 1px solid rgba(163, 167, 176, 0.5);
`;

const FooterContainer = styled.div`
  width: 1036px;
  height: 100%;
  border-top: 1px solid #a3a7b0;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 20px;
  }
`;

const FooterLogoImg = styled.img`
  width: 180px;
  height: 25px;
  margin: 32px 0px 32px -4px;

  @media (max-width: 1024px) {
    margin: 21px 0px 34px -4px;
  }
`;

const FooterRightSection = styled.div`
  width: 100%;
  /* padding: 54px 0 64px 0; */
  display: flex;
  flex-direction: column;
  color: #a3a7b0;
  border-top: 1px solid rgba(163, 167, 176, 0.5);
`;

const FooterCopySection = styled.div`
  display: flex;
  padding-bottom: 21px;
  flex-wrap: wrap;

  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
`;

const FooterCopyUl = styled.ul`
  width: 130px;

  :last-child {
    flex: 1;
  }

  li {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 11px;

    :first-child {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    a {
      font-size: 14px;
      font-weight: normal;
    }

    span {
      font-size: 14px;
      font-weight: normal;
    }
  }
`;

const FooterBottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RegularText = styled.span`
  font-weight: 400;
  font-size: 14px;

  @media (max-width: 1024px) {
    font-size: 10px;
  }
`;

const SnsLink = styled.a`
  margin-left: 34px;

  img {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1024px) {
    margin-left: 0;
    margin-right: 32px;
  }
`;

const FooterMobileSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledKakaoIcon = styled(KakaoIcon)`
  & path {
    fill: #a3a7b0;
  }
`;
const StyledNaverBlogIcon = styled(NaverBlogIcon)`
  & path {
    fill: #a3a7b0;
  }
`;

const StyledInstaIcon = styled(InstaIcon)`
  & path {
    fill: #a3a7b0;
  }
`;

const inValidShowPaths = new Set(['contract', 'event']);

const Footer = () => {
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 1024px)');

  const { userAgent } = navigator;
  const isWebView = userAgent.indexOf('treasurerApp');

  const bottomPathname = ['/wearetreasurer'];
  if (bottomPathname.includes(location.pathname)) return null;

  if (inValidShowPaths.has(location.pathname.split('/')[1])) return null;

  return (
    <>
      {isWebView !== -1 ? (
        <div style={{ marginBottom: 66 }} />
      ) : (
        <FooterWrap>
          <FooterContainer>
            <FooterLogoImg src={assets.logo.default} alt="logo" />
            <FooterCopySection>
              <FooterCopyUl>
                <li>트레져러</li>
                <li>
                  <Link to="/about">회사소개</Link>
                </li>
                <li>
                  <Link to="notice">공지사항</Link>
                </li>
                <li>
                  <a
                    href="https://notice.treasurer.co.kr/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    블로그
                  </a>
                </li>
                <li>
                  <a
                    href="https://notice.treasurer.co.kr/recruit"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    인재채용
                  </a>
                </li>
              </FooterCopyUl>
              <FooterCopyUl>
                <li>조각구매</li>
                <li>
                  <Link to="/market">조각거래</Link>
                </li>
                <li>
                  <Link to="/piece">신규상품</Link>
                </li>
                <li>
                  <Link to="/soldout">매각현황</Link>
                </li>
              </FooterCopyUl>
              <FooterCopyUl>
                <li>매입</li>
                <li>
                  <Link to="/sell">매입문의</Link>
                </li>
                {/* <li>
                  <Link to="/watch">시계 시세 조회</Link>
                </li> */}
              </FooterCopyUl>

              <FooterCopyUl>
                <li>이용약관</li>
                <li>
                  <Link
                    to={{
                      pathname: `/privacy`,
                      state: { num: 0 },
                    }}
                  >
                    서비스 이용약관
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `/privacy`,
                      state: { num: 1 },
                    }}
                  >
                    수수료 정책
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `/privacy`,
                      state: { num: 2 },
                    }}
                  >
                    공동구매 약관
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `/privacy`,
                      state: { num: 3 },
                    }}
                  >
                    금 서비스 이용약관
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `/privacy`,
                      state: { num: 4 },
                    }}
                  >
                    개인정보 처리방침
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `/privacy`,
                      state: { num: 5 },
                    }}
                  >
                    개인정보수집 이용동의
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `/privacy`,
                      state: { num: 6 },
                    }}
                  >
                    마케팅 정보 활용
                  </Link>
                </li>
              </FooterCopyUl>
              <FooterCopyUl>
                <li>고객센터</li>
                <li>
                  <span>전화: 1670-5842</span>
                </li>
                <li>
                  <span>contact@treasurer.co.kr</span>
                </li>
                <li>
                  <a
                    href={
                      isMobile
                        ? 'http://pf.kakao.com/_gPRxjs/chat'
                        : 'http://pf.kakao.com/_gPRxjs'
                    }
                  >
                    카카오톡 1:1 문의
                  </a>
                </li>
              </FooterCopyUl>
            </FooterCopySection>
            <FooterRightSection>
              <FooterBottomWrapper style={{ marginTop: isMobile ? 10 : 54 }}>
                <div>
                  <RegularText style={{ marginRight: 26 }}>
                    (주) 트레져러
                  </RegularText>
                  <RegularText style={{ marginRight: 30 }}>
                    대표: 김경태
                  </RegularText>
                </div>
              </FooterBottomWrapper>
              <FooterBottomWrapper style={{ marginTop: isMobile ? 10 : 16 }}>
                <div>
                  <RegularText style={{ marginRight: isMobile ? 10 : 30 }}>
                    서울 강남구 선릉로 90길 36, 3층 트레져러
                  </RegularText>
                </div>
                {!isMobile ? (
                  <div style={{ display: 'flex' }}>
                    <SnsLink
                      href="https://www.instagram.com/treasurer.co.kr"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <StyledInstaIcon />
                    </SnsLink>
                    <SnsLink
                      href={
                        isMobile
                          ? 'http://pf.kakao.com/_gPRxjs/chat'
                          : 'http://pf.kakao.com/_gPRxjs'
                      }
                    >
                      <StyledKakaoIcon />
                    </SnsLink>
                    <SnsLink
                      href="https://blog.naver.com/extend1082"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <StyledNaverBlogIcon />
                    </SnsLink>
                  </div>
                ) : null}
              </FooterBottomWrapper>
              <FooterBottomWrapper style={{ marginTop: 16 }}>
                <div>
                  <RegularText style={{ marginRight: isMobile ? 10 : 31 }}>
                    사업자 등록번호: 527-81-01503
                  </RegularText>
                  <RegularText>
                    통신판매업신고: 2021-서울강남-03837호
                  </RegularText>
                </div>
                {!isMobile ? (
                  <div>
                    <RegularText>
                      Copyright&copy;{new Date().getFullYear()} Treasurer. All
                      rights reserved.
                    </RegularText>
                  </div>
                ) : null}
              </FooterBottomWrapper>
            </FooterRightSection>
            {isMobile ? (
              <FooterMobileSection>
                <div style={{ marginTop: 50, display: 'flex' }}>
                  <SnsLink
                    href="https://www.instagram.com/treasurer.co.kr"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <StyledInstaIcon />
                  </SnsLink>
                  <SnsLink
                    href={
                      isMobile
                        ? 'http://pf.kakao.com/_gPRxjs/chat'
                        : 'http://pf.kakao.com/_gPRxjs'
                    }
                  >
                    <StyledKakaoIcon />
                  </SnsLink>
                  <SnsLink
                    href="https://blog.naver.com/extend1082"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <StyledNaverBlogIcon />
                  </SnsLink>
                </div>
                <div style={{ marginTop: 10 }}>
                  <RegularText>
                    Copyright&copy;{new Date().getFullYear()} Treasurer. All
                    rights reserved.
                  </RegularText>
                </div>
              </FooterMobileSection>
            ) : null}
          </FooterContainer>
          {/* <FooterFloatingLink onClick={() => isPushKakaoChannel()}>
        <p>
          <img src={assets.bubble.default} alt="bubble" />
        </p>
      </FooterFloatingLink> */}
        </FooterWrap>
      )}
    </>
  );
};

export default Footer;
