import {
  MarketItemResponseData,
  abbreviateNumber,
  TRADE_ITEM_STATUS,
  calculatePriceChangeDetails,
  formattedNumberWithPlusOrMinusSign,
  getDisplayedUnitPrice,
  getDisplayedMarketPrice,
  getDisplayedPriceChange,
} from '@treasurer/common';
import React, { useState } from 'react';
import styled from 'styled-components';
import palette from '@/config/styles/color/Color';
import {
  handleBackgroundColorType,
  handleValueColorType,
} from '@/utils/valueColorHandler';
import AppLink from '@/Components/AppLink';

const statusTagContent = (status: number) => {
  switch (status) {
    case 101:
      return '신규상품';
    case 102:
      return '마켓 거래 준비중';
    case 202:
      return '거래중단';
    case 302:
      return '매각완료';
    default:
      return '';
  }
};

const statusColor = (status: number) => {
  switch (status) {
    case 101:
      return {
        background: `${palette.orangeB[3]}`,
        font: `${palette.orangeB[0]}`,
      };
    case 102:
      return { background: `${palette.ghost[3]}`, font: `${palette.ghost[0]}` };
    case 202:
      return { background: `${palette.coral[3]}`, font: `${palette.coral[0]}` };
    default:
      return {
        background: '',
        font: '',
      };
  }
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 3fr) minmax(0, 1fr) minmax(0, 1fr);
  /* grid-template-columns: minmax(0, 3fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
      0,
      1.2fr
    ); */
  width: 100%;
`;

const GridHeader = styled.div`
  background-color: ${palette.ghost[4]};
  padding: 14px 0;
  font-weight: 400;
  font-size: 10px;
  color: ${palette.gray[2]};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;

  &:first-child {
    padding-left: 16px;
    justify-content: flex-start;
  }

  /* &:nth-child(4) { */
  &:nth-child(3) {
    padding-right: 16px;
  }
`;

const GridHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:first-child {
    justify-content: flex-start;
  }
`;

const SortingIndicator = styled.div`
  margin-left: 4px;
`;

const FlatTriangle = styled.div<{ isActive: boolean }>`
  position: relative;
  width: 0;
  height: 0;
  border-left: 2.5px solid transparent;
  border-right: 2.5px solid transparent;
  border-bottom: ${({ isActive }) =>
    isActive ? `4px solid ${palette.gray[2]}` : `4px solid ${palette.gray[4]}`};
`;

const InvertedFlatTriangle = styled(FlatTriangle)`
  transform: rotate(180deg);
  margin-top: 2px;
`;

const TableCell = styled.div`
  text-align: right;
  font-weight: 500;
  font-size: 10px;
  padding: 12px 0;

  &:first-child {
    text-align: left;
    max-width: 150px;
  }
`;

const ProductImage = styled.img`
  grid-area: 1 / 1 / 3 / 2;
  background: ${palette.ghost[3]};
  border-radius: 10px;
  padding: 4px;
  width: 40px;
  margin-right: 6px;

  @media (max-width: 360px) {
    display: none;
  }
`;

const ProductName = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;
  text-align: left;
  line-height: 12px;
  height: 24px; // to ensure only 2 lines get displayed
  font-size: 12px;
  font-weight: bold;
`;

const ProductDetail = styled.div`
  grid-area: 2 / 2 / 3 / 3;
  color: ${palette.gray[2]};
  font-size: 10px;
  display: flex;
  align-items: end;
  align-self: center;
`;

const StatusTag = styled.div<{ status: number }>`
  background: ${({ status }) => statusColor(status).background};
  color: ${({ status }) => statusColor(status).font};
  border-radius: 3px;
  padding: 2px 6px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
`;

const LastTableCell = styled(TableCell)`
  margin-right: 16px;
  font-weight: bold;
  position: relative;
`;

const FirstColumn = styled.div`
  display: grid;
  grid-template-columns: 46px 1fr auto;
  grid-template-rows: auto auto;
  padding: 12px 0 12px 16px;
  box-sizing: border-box;
  height: 100%;
  cursor: pointer;

  @media (max-width: 360px) {
    grid-template-columns: 1fr auto;

    /* Updating grid areas for the child elements */
    & > ${ProductName} {
      grid-area: 1 / 1 / 2 / 2;
    }

    & > ${ProductDetail} {
      grid-area: 2 / 1 / 2 / 2;
    }
  }
`;

const WhiteSpace = styled.span`
  &:after {
    content: '\\00a0\\00a0\\00a0';

    @media (max-width: 360px) {
      content: '';
    }
  }
`;

export const PriceChange = styled.span<{ priceChange: number }>`
  color: ${({ priceChange }) => handleValueColorType(priceChange)};
  background-color: ${({ priceChange }) =>
    handleBackgroundColorType(priceChange)};
  border-radius: 3px;
  padding: 2px 6px;
  font-weight: 400;
  font-size: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  white-space: nowrap;
  margin-bottom: 12px;
`;

export const ButtonSectionDivider = styled.span`
  border-left: 1px solid #ffff;
  opacity: 50%;
  margin: 1px 3px;
`;

type MarketItemTableProps = {
  marketItems: MarketItemResponseData[] | undefined;
};

type SortKey = keyof MarketItemResponseData;

type SortConfigType = {
  key: SortKey | null;
  direction: 'ascending' | 'descending' | null;
};

type HeaderCellProps = {
  sortKey: keyof MarketItemResponseData;
  title: string;
};

type TableRowProps = {
  item: MarketItemResponseData;
};

const TableRow = ({ item }: TableRowProps) => {
  const { currentPrice, priceChange, priceChangePercentage } =
    calculatePriceChangeDetails(item);

  // 금은 마켓 테이블에서 조각 가격을 1g이 아닌 100g당 가격으로 표시하고 있기 때문. (metal이 아닌 일반 조각은 displayQuantityDivider가 NULL임)
  const displayedUnitPrice = getDisplayedUnitPrice(item);
  const displayedPriceChange = getDisplayedPriceChange(item);
  const displayedMarketPrice = abbreviateNumber(getDisplayedMarketPrice(item));

  return (
    <React.Fragment key={item.id}>
      <AppLink to={`/item/${item.id}`}>
        <FirstColumn>
          <ProductImage src={item.thumbnail} alt={item.modelKR} />
          <ProductName>
            {item.brandKR} {item.modelKR}
          </ProductName>
          <ProductDetail>
            {item.ticker}
            {item.status !== TRADE_ITEM_STATUS.AVAILABLE_TRADING && (
              <StatusTag status={item.status}>
                {statusTagContent(item.status)}
              </StatusTag>
            )}
          </ProductDetail>
        </FirstColumn>
      </AppLink>

      <TableCell>
        {displayedMarketPrice}
        <WhiteSpace />
      </TableCell>
      {/* <TableCell>
        {abbreviateNumber(item.tradeVolume)}
        <WhiteSpace />
      </TableCell> */}
      <LastTableCell>
        {displayedUnitPrice.toLocaleString()}원
        <WhiteSpace />
        {item.status !== TRADE_ITEM_STATUS.ON_SALE && (
          <PriceChange priceChange={priceChange}>
            {formattedNumberWithPlusOrMinusSign(displayedPriceChange)}
            원
            <ButtonSectionDivider />
            {formattedNumberWithPlusOrMinusSign(priceChangePercentage)}%
          </PriceChange>
        )}
      </LastTableCell>
    </React.Fragment>
  );
};

const MarketItemTable = ({ marketItems }: MarketItemTableProps) => {
  const [sortConfig, setSortConfig] = useState<SortConfigType>({
    key: null,
    direction: null,
  });

  const onSort = (key: SortKey) => {
    let direction: SortConfigType['direction'] = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    } else if (
      sortConfig.key === key &&
      sortConfig.direction === 'descending'
    ) {
      direction = null;
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableData = marketItems?.map((item) => ({
      ...item,
      price: item.appraisalPrice || item.recruitmentAmount,
    }));
    if (sortConfig.key && sortConfig.direction) {
      sortableData?.sort(
        (a: MarketItemResponseData, b: MarketItemResponseData) => {
          if (a[sortConfig.key!] < b[sortConfig.key!]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key!] > b[sortConfig.key!]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        },
      );
    }
    return sortableData;
  }, [marketItems, sortConfig]);

  const HeaderCell = ({ sortKey, title }: HeaderCellProps) => {
    return (
      <GridHeader onClick={() => onSort(sortKey)}>
        <GridHeaderContent>
          {title}
          <SortingIndicator>
            <FlatTriangle
              isActive={
                sortConfig.key === sortKey &&
                sortConfig.direction === 'ascending'
              }
            />
            <InvertedFlatTriangle
              isActive={
                sortConfig.key === sortKey &&
                sortConfig.direction === 'descending'
              }
            />
          </SortingIndicator>
        </GridHeaderContent>
      </GridHeader>
    );
  };

  return (
    <Grid>
      <HeaderCell sortKey="brandKR" title="조각상품명" />
      <HeaderCell sortKey="price" title="시장가격" />
      {/* <HeaderCell sortKey="tradeVolume" title="누적거래" /> */}
      <HeaderCell sortKey="lastTradePrice" title="조각가격" />

      {sortedData?.map((item) => (
        <TableRow item={item} key={item.id} />
      ))}
    </Grid>
  );
};

export default MarketItemTable;
