import { useState } from 'react';
import { UserTypes } from '../../types/UserTypes';
import ConfirmModal from '../ConfirmModal';
import BankSelectionModal from '../VirtualAccount/BankSelectionModal';
import BankAccountNumberModal from '../VirtualAccount/BankAccountNumberModal';

interface DepositButtonProps {
  userInfo: UserTypes;
  registerModalOpen: boolean;
  setRegisterModalOpen: any;
}

const VirtualAccountRegistrationModals = ({
  userInfo,
  registerModalOpen,
  setRegisterModalOpen,
}: DepositButtonProps) => {
  const [numpadModalOpen, setNumpadModalOpen] = useState(false);

  const [bankName, setBankName] = useState<string | null>(null);
  const [bankCode, setBankCode] = useState<string | null>(null);

  const [statusMsgModal, setStatusMsgModal] = useState(false);
  const [statusTitle, setStatusTitle] = useState('계좌 연결 완료');
  const [statusMsg, setStatusMsg] = useState(
    '출금 계좌가 성공적으로 연결됐어요. 입출금을 다시 시작해주세요',
  );

  return (
    <>
      <BankSelectionModal
        registerModalOpen={registerModalOpen}
        setBankName={setBankName}
        setBankCode={setBankCode}
        onClose={() => setRegisterModalOpen(false)}
        setNumpadModalOpen={setNumpadModalOpen}
      />

      <BankAccountNumberModal
        numpadModalOpen={numpadModalOpen}
        setRegisterModalOpen={setRegisterModalOpen}
        bankName={bankName}
        bankCode={bankCode}
        onClose={() => setNumpadModalOpen(false)}
        setStatusMsgModal={setStatusMsgModal}
        setStatusTitle={setStatusTitle}
        setStatusMsg={setStatusMsg}
        migrationCheck={Boolean(userInfo.virtualAccountNumber)}
      />

      <ConfirmModal
        modalOpen={statusMsgModal}
        onRequestClose={() => setStatusMsgModal(false)}
        title={statusTitle}
        content={statusMsg}
        confirmBtnText="확인"
        confirmBtnAction={() => {
          setStatusMsgModal(false);
          window.location.reload();
        }}
      />
    </>
  );
};

export default VirtualAccountRegistrationModals;
