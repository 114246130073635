import axios from 'axios';
import { localGetItem } from '../utils/handleStorage';
import CONST from '../config/const';

// 옵션 우선순위 : 3위. 인스턴스 생성시 설정 / 2위. 인스턴스 default 설정 / 1위. 인스턴스 메소드 호출시 옵션
export const instance = axios.create({
  baseURL: CONST.HOST,
});

// axios.defaults.baseURL = 'https://api.example.com'; // set axios global default config
// instance.defaults.baseURL = 'https://api.example.com'; // set instance default config after instance created

// https://github.com/axios/axios#using-applicationx-www-form-urlencoded-format
// By default, axios serializes JavaScript objects to JSON.
// To send data in the application/x-www-form-urlencoded format instead, you can use one of the following options.
instance.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

instance.interceptors.request.use(
  async (response) => {
    // go then()
    // const token = await getItem('session');
    // const token = await localStorage.getItem('session');
    const token = await localGetItem('session');

    // if (_.isNil(token)) {
    //   // 로그인으로 보내던 하면된다
    //   return Promise.reject(new Error('no user accessToken'));
    // }

    response.headers.Authorization = `Bearer ${token}`;
    return response;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

const isAxiosError = (value: any) => {
  if (axios.isAxiosError(value)) throw new Error(value.message);
  else if (typeof window !== 'undefined') window.console.error(value);
};
export default instance;
export { isAxiosError };
