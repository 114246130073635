import { ReactNode, useEffect } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';

type customHeights = {
  customHeight?: string;
};

interface BottomSheetProps {
  children: ReactNode;
  modalOpen: boolean;
  onRequestClose?: () => void;
  title?: string;
  hideCloseButton?: boolean;
  customHeight?: string;
}

const CustomBottomModal = ({
  modalOpen,
  onRequestClose,
  children,
  title,
  hideCloseButton = false,
  customHeight,
}: BottomSheetProps) => {
  useEffect(() => {
    if (modalOpen) {
      document.body.style.cssText = `
      position: fixed;
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    }
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, [modalOpen]);

  return (
    <ModalOverlay
      isOpen={modalOpen}
      style={modalStyle}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
    >
      <ModalContainer customHeight={customHeight}>
        {(title || !hideCloseButton) && (
          <ModalHeader>
            {!hideCloseButton && <Spacer />}
            {title && <h1>{title}</h1>}
            {!hideCloseButton && <CloseButton onClick={onRequestClose} />}
          </ModalHeader>
        )}

        {children}
      </ModalContainer>
    </ModalOverlay>
  );
};

export default CustomBottomModal;

const ModalContainer = styled.div<customHeights>`
  @keyframes slidein {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0%);
    }
  }
  min-height: ${(props) => props.customHeight || 'auto'};
  max-height: 90vh;
  padding: 20px 16px;
  border-radius: 20px 20px 0px 0px;
  animation-name: slidein;
  animation-duration: 0.5s;
  transition: all 0.5s;
  background-color: #fff;

  @media (max-width: 1024px) {
    width: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;
  }
`;

const ModalOverlay = styled(Modal)`
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
  right: 0;
  flex-direction: column;
  justify-content: flex-end;
`;

const ModalHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: inherit;
  display: flex;
  align-items: center;
  padding: 20px 0;

  h1 {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    flex-grow: 1;
  }
`;

const CloseButton = styled(CloseIcon)`
  cursor: pointer;
`;

const Spacer = styled.div`
  width: 24px;
  visibility: hidden;
`;

const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(29, 29, 29, 0.8)',
    zIndex: 9999,
  },
};
