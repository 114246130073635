import { RECEIVE_USERINFO } from '../constants/ActionTypes';

const initialState = {
  userAuth: {},
  userInfo: {},
};

// 여기서 state는 state.userInfo 들어온다
const userInfo = (state = initialState.userInfo, action) => {
  switch (action.type) {
    case RECEIVE_USERINFO:
      return action.userInfo;
    default:
      return state;
  }
};

export const getUserAuth = (state) => state.userAuth;
export const getUserInfo = (state) => state.userInfo;

const user = (state = initialState, action) => {
  switch (action.type) {
    default:
      return {
        userInfo: userInfo(state.userInfo, action),
      };
  }
};

export default user;
