/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowDown } from '../assets/images/common/arrow/ArrowDownS.svg';
import { SpaceBetween } from '../config/style';

const FaqComponentContainer = styled.div`
  width: 100%;
  /* margin-top: 20px; */
  font: inherit !important;
`;

const ExtraBoldText = styled.h3`
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 20px;
`;

const QnaContainer = styled.div`
  margin-bottom: 70px;
  margin-top: 20px;
`;

const BoldText = styled.h4`
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 20px;
`;

const RegularText = styled.span`
  /* font-size: 14px; */
  font-weight: 400;
  color: #6e757c;
  line-height: 26px;
  display: inline-block;
  text-align: left;
`;

const FAQWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const FAQStateContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
`;

const FAQStateButton = styled.button`
  flex: 1;
  padding: 8px 0;
  background: rgba(72, 79, 97, 0.1);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #484f61;
  word-break: keep-all;

  &.is-active {
    background: #ff8a22;
    color: #fff;
  }
`;

const FAQContentWrapper = styled.div``;

const FAQContentButton = styled.button`
  width: 100%;

  padding: 10px 0px;
  text-align: left;
  border-bottom: 1px solid rgba(72, 79, 97, 0.2);
  color: #353c49;

  /* @supports (-webkit-touch-callout: none) {
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #353C49;
    }
  } */

  -webkit-appearance: none;

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #353c49;
  }

  &.act {
    border-bottom: none;

    .arrow {
      transform: rotate(180deg);
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;

const FAQContent = styled.div`
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 0vh;
  /* display: none; */
  /* padding: 10px 0px; */
  transition: all 0.3s;
  font-size: 14px;
  line-height: 22px;
  color: #484f61;
  white-space: pre-line;

  &.act {
    visibility: visible;
    opacity: 1;
    /* display: block; */
    height: auto;
    padding: 10px 0px;
    border-bottom: 1px solid rgba(72, 79, 97, 0.2);
  }
`;

const ContactCSButton = styled.a`
  display: block;
  text-align: center;
  background: #ededef;
  border-radius: 10px;
  padding: 20px;
  margin: 16px 0;
  width: 100%;
  color: #484f61;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }
`;

type FaqList = FaqItem[];

type FaqItem = {
  tab: string;
  content: FaqContent[];
};

type FaqContent = {
  question: string;
  answer: string;
};

const faqList: FaqList = [
  {
    tab: '조각 구매',
    content: [
      {
        question: '트레져러는 어떤 회사인가요?',
        answer: `희소가치가 높은 상품 (시계, 위스키, 와인, 아트토이, 명품 등)을 온라인에서 거래할 수 있는 플랫폼입니다. 개인으로 거래하기 어려운 고가 상품들의 조각 소유 구매, 판매, 매입, 대출 등 다양한 방법으로 구매하거나 현금화할 수 있는 서비스를 제공하고 있습니다. 모든 거래는 블록체인상 기록되어 트레져러에서 물품 구매시 해당 제품의 소유권을 증명하는 NFT 카드도 발급받아 거래할 수 있습니다. `,
      },
      {
        question: '트레져러 조각 구매란?',
        answer: `평소에 가격이 높아 구매가 어려운 희소가치 높은 상품의 소유권을 원하는 만큼 분할하여 구매할 수 있습니다. 실물은 트레져러에서 관리 및 보관 후 1년 뒤 또는 구매자들의 과반수가 희망하는 시기에 상품을 판매 후 이익을 각 소유권의 비율에 맞게 원금과 함께 지급합니다. 구매한 소유권은 언제든지 트레져러 마켓에서 직거래가 가능합니다.`,
      },
      {
        question: '어떻게 구매 하나요?',
        answer: `1. 내자산에 접속 후 구매를 희망하는 금액만큼의 예치금을 가상계좌 입금을 통해 예치합니다.
              2. 현재 판매 중인 트레져러 조각 상품을 클릭합니다.
              3. 희망하는 조각수를 선택 후 구매하기를 클릭합니다.
              4. 예치금으로 결제 후 구매한 수만큼의 조각이 내자산에 보여집니다.
              5. 정해진 판매 시기까지 기다린 후 트레져러를 통해 판매하여 현금화 하거나 트레져러 마켓에서 구매한 조각을 직접 판매 후 현금화 할 수 있습니다.`,
      },
      {
        question: '인당 구매 한도가 있나요?',
        answer: `신규상품의 구매 한도는 통상적으로 전체 조각의 30%의 수량과 10,000 조각 중 더 높은 수량으로 정해집니다. 가능한 여러 고객이 구매하실 수 있도록 구매 제한을 둔 점 이용에 참고하시기 바랍니다.`,
      },
      {
        question:
          '조각 판매 상품이 100% 다 판매되지 않은 경우는 어떻게 되나요?',
        answer: `신규상품의 경우, 오픈 기간 동안 50% 미만의 조각이 판매된 상품의 경우 자동으로 구매 취소되며 전액 환불 처리됩니다. 50% 이상의 조각이 판매되었으나 100%에 못 미치는 경우 나머지 조각은 트레져러에서 구매하고, 구매하신 조각은 유지됩니다.`,
      },
    ],
  },
  {
    tab: '금',
    content: [
      {
        question: '트레져러에서 금은 어디서 매입해오나요?',
        answer: `트레져러 금은 KRX금 현물 거래소에서 매입해오는 금으로, 실물 금은 KRX금 현물거래소에서 보관하고 있습니다.`,
      },
      {
        question: '금 구매 후 환불이 가능한가요?',
        answer: `거래에 대한 환불이 어렵습니다. 환불을 원할 시, 고객센터로 문의바랍니다.`,
      },
      {
        question: '금을 원하는 가격으로 주문할 수 있나요?',
        answer: `트레져러에서는 가격을 지정 하지 않는 단일가 거래로만 거래가 가능합니다. 현 시점의 시세로 즉시 거래 체결됩니다.`,
      },
      {
        question: '금을 실물로 교환할 수 있나요?',
        answer: `- 수령시점: 출고 신청 후 영업일 기준 이틀 후 수령이 가능합니다.
        * 트레져러에서 앱에서 인출 신청 후 트레져러에서 운영하는 ‘볼트제로’(서울시 강남구 선릉로 90길 36 3층)에서 본인이 신분증 지참 후 직접 수령 가능하며, 배송은 불가합니다.
        - 단위: 금 1000g 단위, 미니 금 100g 단위로 인출가능합니다.
        - 수수료: 출고 수수료와 인출 신청 당시 평가금액의 10% 부가세가 발생합니다.
        * 출고 수수료와 부가세가 부과되므로, 계좌의 출금가능금액을 반드시 확인 해주시길 바랍니다.(출금 가능금액 금액 부족 시 출고처리 불가)
        * 금 런칭 기념 출고 수수료가 2023년 3분기까지 면제됩니다.`,
      },
      {
        question: '실물자산 인출 신청은 어떻게 하나요?',
        answer: `트레져러 1:1 고객센터 문의를 통해 자세한 내용을 문의 바랍니다.`,
      },
      {
        question: '최소 거래 금액과 최대 거래 금액이 제한되어 있나요?',
        answer: `최소 구매 금액은 1천원, 최소 판매 중량은 0.01g입니다. 1회 최대 거래금액은 1천만원, 1일 최대 거래 금액은 1억원입니다(구매, 판매 별도).`,
      },
      {
        question: '거래단위(중량)가 정해져 있나요?',
        answer: `거래 가능 단위는 0.0001g 입니다.`,
      },
    ],
  },
  {
    tab: '은',
    content: [
      {
        question: '트레져러에서 은은 어디서 매입해오나요?',
        answer: `트레져러 은은 한국조폐공사에서 매입해오는 현물 은으로, 강남구 선릉로 90길 36, 3층에 위치한 트레져러 쇼룸의 금고 안에 안전하게 보관됩니다.`,
      },
      {
        question: '은 구매 후 환불이 가능한가요?',
        answer: `거래에 대한 환불이 어렵습니다. 환불을 원할 시, 고객센터로 문의바랍니다.`,
      },
      {
        question: '은을 원하는 가격으로 주문할 수 있나요?',
        answer: `트레져러에서는 가격을 지정 하지 않는 단일가 거래로만 거래가 가능합니다. 현 시점의 시세로 즉시 거래 체결됩니다.`,
      },
      {
        question: '은을 실물로 교환할 수 있나요?',
        answer: `- 수령시점: 출고 신청 후 영업일 기준 이틀 후 수령이 가능합니다.
        * 트레져러에서 앱에서 인출 신청 후 트레져러에서 운영하는 ‘볼트제로’(서울시 강남구 선릉로 90길 36 3층)에서 본인이 신분증 지참 후 직접 수령 가능하며, 배송은 불가합니다.
        - 단위: 은 1000g 단위로 인출가능합니다.
        - 수수료: 출고 수수료와 인출 신청 당시 평가금액의 10% 부가세가 발생합니다.
        * 출고 수수료와 부가세가 부과되므로, 계좌의 출금가능금액을 반드시 확인 해주시길 바랍니다.(출금 가능금액 금액 부족 시 출고처리 불가)
        * 은 런칭 기념 출고 수수료가 2023년 3분기까지 면제됩니다.`,
      },
      {
        question: '실물자산 인출 신청은 어떻게 하나요?',
        answer: `트레져러 1:1 고객센터 문의를 통해 자세한 내용을 문의 바랍니다.`,
      },
      {
        question: '최소 거래 금액과 최대 거래 금액이 제한되어 있나요?',
        answer: `최소 구매 금액은 1천원, 최소 판매 중량은 1g입니다. 1회 최대 거래금액은 1천만원, 1일 최대 거래 금액은 1억원입니다(구매, 판매 별도).`,
      },
      {
        question: '거래단위(중량)가 정해져 있나요?',
        answer: `거래 가능 단위는 0.01g 입니다.`,
      },
    ],
  },
  {
    tab: '상 품',
    content: [
      {
        question: '구매한 물품의 실물은 어떻게 보관되나요?',
        answer: `해외에서 파트너사를 통해 구매 및 보관되는 상품들을 제외한 나머지 국내 구매 및 보관 상품들은 강남구 선릉로 90길 36, 3층에 위치한 트레져러 쇼룸의 금고안에 안전하게 보관됩니다. 해당 공간은 3중 보안문과 열감지 보안시설로 철저하게 관리되고 있습니다.`,
      },
      {
        question: '구매한 물품을 직접 보거나 사용해볼 수 있나요?',
        answer: `물품을 20조각이상 보유하신 고객에 한해서 1주일전 카카오톡 '트레져러' 채널을 통해 날짜와 시간 예약 신청 후 물품을 보실 수 있습니다. 물건을 현장에서 사용 또는 시착하시는 경우 물품 상태 보존을 위해 준비된 장갑을 착용하신 상태에서만 가능합니다.`,
      },
      {
        question: '조각을 100% 보유한 경우 물건을 양도 받을 수 있나요?',
        answer: `100% 조각을 구매하시는 경우 양도가 가능합니다. 다만, 현재 트레져러의 조각 소유 상품은 공동 구매 용도로 제공하고 있으며, 아직까지 100% 조각을 모두 판매하고 있지 않습니다.`,
      },
    ],
  },
  {
    tab: `수 익`,
    content: [
      {
        question: '조각 구매의 예상 수익률은 보장되나요?',
        answer: `아니요. 트레져러에서 판매하는 대부분의 물품은 트레져러 리소스팀에서 엄선한 과거에 많은 수익이 발생한 희소가치가 있는 물품들입니다. 다만, 각 물품의 과거 수익률을 기준으로 책정된 예상 수익률이 보장되지 않으며 물품의 가치가 하락하여 손실이 발생할 수 있습니다. 이 점 이용에 꼭 참고해 주시기 바랍니다.`,
      },
      {
        question: '수익 상환은 어느 시점에 이루어지나요?',
        answer: ` 각 조각 소유 상품의 매각 시기는 조각 판매가 완료된 후로부터 12개월 후 이지만, 소유권을 가진 분들의 투표를 통해 매각 시기는 변동될 수 있습니다.`,
      },
      {
        question: '수익금은 어떻게 계산되나요?',
        answer: `상품의 처분에 따라 발생한 원금과 수익은 총 판매 대금에서 판매 시 발생한 비용 및 상품 관리시 발생한 비용 및 위탁 수수료(매각 대금의 2%)를 제외한 후 조각소유자에게 그 소유한 조각에 비례하여 귀속됩니다.`,
      },
      {
        question: '수익금의 상환 절차는?',
        answer: `판매가 완료된 시점으로부터 국내 판매 기준 7영업일내로 해외 판매 기준 30영업일내로 트레져러 예치금 형태로 수익금이 입금됩니다. 입금된 예치금은 본인 계좌 연동 후 언제든지 연동된 계좌로 출금이 가능합니다. `,
      },
    ],
  },
];

const isGold = window.location.pathname.split('/item/')[1] === '1001';
const isSilver = window.location.pathname.split('/item/')[1] === '1002';
type FaqProps = {
  title?: string;
  firstQuestion?: string;
  firstAnswer?: string;
  secondQuestion?: string;
  secondAnswer?: string;
  contentTitle?: string;
};

const FaqComponent = (props: FaqProps) => {
  const {
    title,
    firstQuestion,
    firstAnswer,
    secondQuestion,
    secondAnswer,
    contentTitle,
  } = props;

  const [tabActiveId, setTabActiveId] = isGold
    ? useState(1)
    : isSilver
    ? useState(2)
    : useState(0);
  const [current, setCurrent] = useState(-1);

  // 탭 구현
  const tabHandler = (id: number) => {
    setTabActiveId(id);
  };

  const onShowCollpase = (i: number) => {
    if (current === i) {
      setCurrent(-1);
    } else {
      setCurrent(i);
    }
  };

  const renderQnaContent = (contents: FaqContent[]) => {
    return (
      <>
        {contents.map((item, i) => (
          <div key={i}>
            <FAQContentButton
              className={current === i ? 'act' : ''}
              onClick={() => onShowCollpase(i)}
            >
              <SpaceBetween>
                <span>{item.question}</span>
                <ArrowDown className="arrow" />
              </SpaceBetween>
            </FAQContentButton>
            <FAQContent className={current === i ? 'act' : ''}>
              {item.answer}
            </FAQContent>
          </div>
        ))}
      </>
    );
  };

  return (
    <FaqComponentContainer>
      {title && <ExtraBoldText>{title}</ExtraBoldText>}
      {firstQuestion && (
        <QnaContainer>
          <BoldText>{firstQuestion}</BoldText>
          <p>
            <RegularText>{firstAnswer}</RegularText>
          </p>
        </QnaContainer>
      )}
      {secondQuestion && (
        <QnaContainer>
          <BoldText>{secondQuestion}</BoldText>
          <p>
            <RegularText>{secondAnswer}</RegularText>
          </p>
        </QnaContainer>
      )}
      {faqList && (
        <>
          <ExtraBoldText>{contentTitle}</ExtraBoldText>
          <FAQWrapper>
            <FAQStateContainer>
              {faqList.map((content, index) => (
                <FAQStateButton
                  key={index}
                  className={tabActiveId === index ? 'is-active' : ''}
                  onClick={() => tabHandler(index)}
                >
                  {content.tab}
                </FAQStateButton>
              ))}
            </FAQStateContainer>

            <FAQContentWrapper>
              {faqList.map((content, index) => (
                <React.Fragment key={index}>
                  {tabActiveId === index && renderQnaContent(content.content)}
                </React.Fragment>
              ))}
            </FAQContentWrapper>
          </FAQWrapper>
        </>
      )}

      <ContactCSButton href="http://pf.kakao.com/_gPRxjs/chat">
        <p>원하는 답변이 없나요?</p>
        1:1 고객센터 문의하기
      </ContactCSButton>
    </FaqComponentContainer>
  );
};

export default FaqComponent;
