export const POINT_COLOR = 'rgba(81,14,234,1)';
export const GRAY_COLOR = '#F0F0F0';
export const BLACK_COLOR = '#000';
export const BACKGROUND_COLOR = 'rgb(33, 50, 91)';
export const BORDER_COLOR = '#A6A6A6';
export const WHITE_COLOR = '#FFF';
export const RED_COLOR = '#EB3322';
export const BLUE_COLOR = '#4CAEEA';

// V2 Design Color
export const V2_BACKGROUND_COLOR = '#EDEFF2';
export const V2_ACTIVE_COLOR = '#748FB5';
export const V2_POINT_COLOR = '#FF8A22';
export const V2_LOGO_COLOR = '#2A3137';
export const V2_BORDER_COLOR = '#969DB1';
export const V2_INACTIVE_BORDER_COLOR = '#E4E7EB';
export const V2_INACTIVE_COLOR = '#6E757C';
export const V2_BLACK_COLOR = '#272E35';

export const V2_INACTIVE_TEXT_COLOR = '#BAC3CA';
export const V2_BUTTON_COLOR = '#00253e';

// up & down
export const BID_COLOR = '#d60000';
export const ASK_COLOR = '#115dcb';
