import styled from 'styled-components';
import ReactLoading from 'react-loading';

const SpinnerBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: transparent;
`;

// const Container = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   z-index: 100;
//   background-color: ${V2_BACKGROUND_COLOR};
//   opacity: 0.9;
// `

const Loading = () => (
  <>
    <SpinnerBox>
      <ReactLoading type="spin" color="#FF8A22" height={50} width={50} />
    </SpinnerBox>

    {/* <Container></Container> */}
  </>
);

export default Loading;
