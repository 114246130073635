export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const RESEND_EMAIL = 'RESEND_EMAIL';
export const RECEIVE_USERINFO = 'RECEIVE_USERINFO';
export const RECEIVE_USER_BALANCE = 'RECEIVE_USER_BALANCE';
export const REQUEST_USER_DEPOSIT = 'REQUEST_USER_DEPOSIT';
export const REQUEST_USER_WITHDRAW = 'REQUEST_USER_WITHDRAW';
export const BUY_USER_PIECE = 'BUY_USER_PIECE';
export const BUY_USER_PIECE_WITH_CARD = 'BUY_USER_PIECE_WITH_CARD';
export const USER_OWNERSHIP = 'USER_OWNERSHIP';
export const REQUEST_KYC_CODE = 'REQUEST_KYC_CODE';
export const CONFIRM_KYC_CODE = 'CONFIRM_KYC_CODE';
export const OWNERSHIP_DETAIL = 'OWNERSHIP_DETAIL';
export const BUY_USER_PIECE_REQUEST = 'BUY_USER_PIECE_REQUEST';
export const SELL_USER_PIECE_REQUEST = 'SELL_USER_PIECE_REQUEST';
//
export const SELL_USER_ORDER = 'SELL_USER_ORDER';
export const BUY_USER_ORDER = 'BUY_USER_ORDER';
export const GET_OWN_BALANCE_INFOMATION = 'GET_OWN_BALANCE_INFOMATION';
export const GET_ORDER_LIST = 'GET_ORDER_LIST';
export const GET_ORDER_HISTORY_LIST = 'GET_ORDER_HISTORY_LIST';
export const GET_ORDER_HISTORY_ALL_LIST = 'GET_ORDER_HISTORY_ALL_LIST';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const RECEIVE_USER_AUTH = 'RECEIVE_USER_AUTH';

// main
export const GET_MARKET_LIST = 'MAIN_GET_MARKET_LIST';
export const GET_MARKET_TRANSACTION = 'MAIN_GET_MARKET_TRANSACTION';
export const GET_USER_ONSALE_LIST = 'MAIN_GET_USER_ONSALE_LIST';
