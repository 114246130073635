import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { MyUserDawHistoryStatusTypeTypes } from '@/Queries/userQueries';

import {
  getDawHistoryFetchers,
  getUserBalanceFetchers,
  getUserBalanceInfoFetchers,
  getUserInfoFetchers,
  getUserOwnershipTotalFetchers,
  getVirtualAccountFetchers,
  getRequestWithdrawFetchers,
  VirtualAccountTypes,
  updateVirtualAccountFetchers,
  getDisposedItemsFetchers,
  getMyAssetFetchers,
  getMyMoneyTransactionHistoryFetchers,
  getMyItemOrderHistoryFetchers,
  cancelItemOrderFetchers,
  getUserNotificationFetchers,
  markNotificationAsReadFetchers,
  checkUserHasUnreadNotificationFetchers,
  checkUserKbPointsFetchers,
  checkUserOcbPointsFetchers,
  getUserPointUseHistoryFetchers,
  checkUserTreasurerPointsFetchers,
  BANNER_TYPES,
  getHomeBannerFetchers,
} from './userFetchers';
import {
  BasedOn,
  userBalanceHistoryTypes,
  UserTypes,
} from '../types/UserTypes';
import { UserBalanceInfoTypes } from '../types/AssetTypes';
import { KbUserPointreeResponseData } from '@treasurer/common';

export interface MyUserDawHistoryTypes {
  krwActions: MyUserDawHistoryStatusTypeTypes[];
  marketList: MyUserDawHistoryStatusTypeTypes[];
  depositList: MyUserDawHistoryStatusTypeTypes[];
  widthrawList: MyUserDawHistoryStatusTypeTypes[];
  closingList: MyUserDawHistoryStatusTypeTypes[];
  eventList: MyUserDawHistoryStatusTypeTypes[];
}

const getUserOwnershipTotal = (
  tradeItemId: string,
  options?: UseQueryOptions<
    {
      ownershipTotal: number;
    },
    AxiosError,
    {
      ownershipTotal: number;
    },
    ['userOwnershipTotal', string]
  >,
) =>
  useQuery(
    ['userOwnershipTotal', tradeItemId],
    () => getUserOwnershipTotalFetchers(tradeItemId),
    {
      ...options,
    },
  );

export const useMyAssetQuery = (basedOn: BasedOn) =>
  useQuery({
    queryKey: ['getMyAsset', basedOn],
    queryFn: () => getMyAssetFetchers(basedOn),
  });

const getDisposedItems = () =>
  useQuery(['getDisposedItems'], () => getDisposedItemsFetchers());

const getUserInfo = (
  options?: UseQueryOptions<UserTypes, AxiosError, UserTypes, ['getUserInfo']>,
) =>
  useQuery(['getUserInfo'], () => getUserInfoFetchers(), {
    ...options,
  });

const getUserBalanceInfo = (
  tradeItemId: string,
  options?: UseQueryOptions<
    UserBalanceInfoTypes,
    AxiosError,
    UserBalanceInfoTypes,
    ['getUserBalanceInfo', string]
  >,
) =>
  useQuery(
    ['getUserBalanceInfo', tradeItemId],
    () => getUserBalanceInfoFetchers(tradeItemId),
    {
      ...options,
    },
  );

// New userBalance API
const useUserBalance = (options?: any) =>
  useQuery(['userBalance'], () => getUserBalanceFetchers(), options);

// const useUserBalance = (
//   options?: UseQueryOptions<
//     userBalanceTypes,
//     AxiosError,
//     userBalanceTypes,
//     ['userBalance']
//   >,
// ) =>
//   useQuery(['userBalance'], () => getUserBalanceFetchers(), {
//     ...options,
//   });

const useUserDawHistory = (
  options?: UseQueryOptions<
    userBalanceHistoryTypes[],
    AxiosError,
    MyUserDawHistoryTypes,
    ['userDawHistory']
  >,
) => useQuery(['userDawHistory'], () => getDawHistoryFetchers(), options);

// NEW 주문 내역 API
export const useMyItemOrderHistoryQuery = () =>
  useQuery(['myItemOrderHistory'], () => getMyItemOrderHistoryFetchers());

// NEW 입출금 내역 API
export const useMyMoneyTransactionHistoryQuery = () =>
  useQuery(['myMoneyTransactionHistory'], () =>
    getMyMoneyTransactionHistoryFetchers(),
  );

export const getUserPointUseHistory = () =>
  useQuery(['getUserPointUseHistory'], () => getUserPointUseHistoryFetchers());

const getVirtualAccount = (body: VirtualAccountTypes, options?: any) =>
  useQuery(['getVirtualAccount'], () => getVirtualAccountFetchers(body), {
    ...options,
  });

const updateVirtualAccount = (body: VirtualAccountTypes, options?: any) =>
  useMutation(
    ['updateVirtualAccount'],
    () => updateVirtualAccountFetchers(body),
    {
      ...options,
    },
  );

const requestWithdraw = (amount: number, options?: any) =>
  useMutation(['requestWithdraw'], () => getRequestWithdrawFetchers(amount), {
    ...options,
  });

const cancelItemOrder = (orderId: any) =>
  useMutation(['cancelItemOrder', orderId], () =>
    cancelItemOrderFetchers(orderId),
  );

const getUserNotification = () =>
  useQuery(['getUserNotification'], () => getUserNotificationFetchers());

const checkUserHasUnreadNotification = () =>
  useQuery(['checkUserHasUnreadNotification'], () =>
    checkUserHasUnreadNotificationFetchers(),
  );

const markNotificationAsRead = (messageId: number) =>
  useMutation(['markNotificationAsRead', messageId], () =>
    markNotificationAsReadFetchers(messageId),
  );

const checkUserKbPoints = (
  options?: UseQueryOptions<
    KbUserPointreeResponseData,
    AxiosError,
    KbUserPointreeResponseData,
    ['checkUserKbPoints']
  >,
) => {
  return useQuery({
    queryKey: ['checkUserKbPoints'],
    queryFn: () => checkUserKbPointsFetchers(),
    enabled: options?.enabled,
  });
};

const checkUserOcbPoints = (
  mctAmount?: number,
  options?: UseQueryOptions<any, AxiosError, any, ['checkUserOcbPoints']>,
) =>
  useQuery(
    ['checkUserOcbPoints'],
    () => checkUserOcbPointsFetchers(mctAmount || 0),
    {
      ...options,
    },
  );

const checkUserTreasurerPoints = (
  pointType: 'UNIVERSAL' | 'METAL',
  options?: UseQueryOptions<
    any,
    AxiosError,
    any,
    ['checkUserTreasurerPoints', 'UNIVERSAL' | 'METAL']
  >,
) =>
  useQuery(
    ['checkUserTreasurerPoints', pointType],
    () => checkUserTreasurerPointsFetchers(pointType),
    {
      ...options,
    },
  );

const getHomeBanners = (type?: BANNER_TYPES) =>
  useQuery({
    queryKey: ['getHomeBanners', type],
    queryFn: () => getHomeBannerFetchers(type),
  });

export {
  getUserOwnershipTotal,
  // getMyAsset,
  getDisposedItems,
  getUserInfo,
  getUserBalanceInfo,
  useUserBalance,
  useUserDawHistory,
  getVirtualAccount,
  updateVirtualAccount,
  requestWithdraw,
  cancelItemOrder,
  getUserNotification,
  checkUserHasUnreadNotification,
  markNotificationAsRead,
  checkUserKbPoints,
  checkUserOcbPoints,
  checkUserTreasurerPoints,
  getHomeBanners,
};
