import palette from '@/config/styles/color/Color';
import styled from 'styled-components';

const ButtonFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  margin: 16px 0;
`;

const FilterButton = styled.div<{ active: boolean }>`
  padding: 9px 16px;
  margin-right: 10px;
  border-radius: 50px;
  color: ${({ active }) => (active ? palette.gray[1] : palette.ghost[2])};
  border: ${({ active }) =>
    active ? `1px solid ${palette.gray[1]}` : `1px solid ${palette.ghost[2]}`};
  font-weight: ${({ active }) => (active ? 700 : 500)};
  cursor: pointer;
`;

type ButtonFilterProps = {
  buttonFilterComposition: { category: string; label: string }[];
  selectedFilter: string;
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
};

const ButtonFilter = ({
  buttonFilterComposition,
  selectedFilter,
  setSelectedFilter,
}: ButtonFilterProps) => {
  const handleFilterButtonClick = (selected: string) => {
    setSelectedFilter(selected);
  };

  return (
    <ButtonFilterContainer>
      {buttonFilterComposition.map(({ category, label }) => (
        <FilterButton
          key={category}
          active={selectedFilter === category}
          onClick={() => handleFilterButtonClick(category)}
        >
          {label}
        </FilterButton>
      ))}
    </ButtonFilterContainer>
  );
};

export default ButtonFilter;
