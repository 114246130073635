import { SpaceBetween } from '@/styles/CommonStyledComponents';
import { ReactNode, useEffect } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';

type customStyle = {
  customWidth?: string;
  customHeight?: string;
};

interface CustomModalProps {
  modalOpen: boolean;
  isOrderbook?: boolean;
  onRequestClose?: () => void;
  children: ReactNode;
  title?: string;
  showCloseButton?: boolean;
  customWidth?: string;
  customHeight?: string;
}

type ModalContainerProps = customStyle & Pick<CustomModalProps, 'isOrderbook'>;

const ModalContainer = styled.div<ModalContainerProps>`
  width: ${(props) => (props.customWidth ? props.customWidth : '328px')};
  ${(props) => props.customHeight && `height: ${props.customHeight};`}
  ${({ isOrderbook }) => !isOrderbook && 'padding: 20px 16px;'}
  max-height: 75vh;
  @media (max-width: 1024px) {
    max-width: 100%;
    /* width: 100%; */
    overflow-y: auto;
    scroll-behavior: smooth;
    background-color: white;
  }
`;

const ModalHeader = styled(SpaceBetween)`
  margin-bottom: 12px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

const CloseButton = styled.button`
  margin-left: auto;
  margin-right: 0;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(29, 29, 29, 0.8)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 'none',
    boxShadow: '3px 4px 16px rgba(72, 79, 97, 0.2)',
    borderRadius: '10px',
  },
};

const CustomModal = ({
  modalOpen,
  onRequestClose,
  children,
  title,
  showCloseButton,
  customWidth,
  customHeight,
  isOrderbook,
}: CustomModalProps) => {
  useEffect(() => {
    if (modalOpen) {
      document.body.style.cssText = `
    position: fixed;
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
    }
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, [modalOpen]);

  return (
    <Modal
      isOpen={modalOpen}
      style={customStyles}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
    >
      <ModalContainer
        customWidth={customWidth}
        customHeight={customHeight}
        isOrderbook={isOrderbook}
      >
        {(title || showCloseButton) && (
          <ModalHeader>
            {title && <Title>{title}</Title>}
            {showCloseButton && (
              <CloseButton onClick={onRequestClose}>
                <CloseIcon />
              </CloseButton>
            )}
          </ModalHeader>
        )}

        {children}
      </ModalContainer>
    </Modal>
  );
};

export default CustomModal;
