import palette from '@/config/styles/color/Color';
import { useState } from 'react';
import styled from 'styled-components';
import CustomModal from './CustomModal';

interface AppOnlyModalProps {
  featureName: string;
  isModalOpen: boolean;
  onModalClose: any;
}

const AppOnlyModal = ({
  featureName,
  isModalOpen,
  onModalClose,
}: AppOnlyModalProps) => {
  const [isPcModalOpen, setIsPcModalOpen] = useState(false);

  // TODO: util function
  const handleAppRedirect = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent) || /iPad|iPhone/.test(userAgent)) {
      location.href = 'https://treasurer.page.link/NLtk';
    } else {
      setIsPcModalOpen(true);
    }
  };

  return (
    <>
      <CustomModal modalOpen={isModalOpen} onRequestClose={onModalClose}>
        <ModalTitle>{featureName} 기능 안내</ModalTitle>
        <ModalContent>
          {featureName} 기능은 트레져러 앱에서만 열 수 있어요.
        </ModalContent>
        <ButtonContainer>
          <Button onClick={onModalClose}>취소</Button>
          <AppButton onClick={handleAppRedirect}>앱으로 이동</AppButton>
        </ButtonContainer>
      </CustomModal>

      <CustomModal
        modalOpen={isPcModalOpen}
        onRequestClose={() => setIsPcModalOpen(false)}
      >
        <IsPcMessage>
          PC에서는 앱이 지원되지 않습니다.
          <br />
          휴대폰 또는 타블렛을 이용해주세요.
        </IsPcMessage>
      </CustomModal>
    </>
  );
};

export default AppOnlyModal;

const ModalTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
`;

const ModalContent = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 12px;
`;

const Button = styled.div`
  padding: 11px 0;
  border: 1px solid #ff8a22;
  border-radius: 8px;
  width: 50%;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
`;

const AppButton = styled(Button)`
  color: #fff;
  background-color: ${palette.orangeB[0]};
`;

const IsPcMessage = styled.div`
  font-size: 14px;
  line-height: 18px;
`;
