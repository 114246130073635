import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';

// import bottom_navi from '../assets/images/bottom_navi.png';

import Home from '../assets/images/bottom_navi/home.svg';
import Piece from '../assets/images/bottom_navi/piece.svg';
import Invest from '../assets/images/bottom_navi/invest.svg';
import Asset from '../assets/images/bottom_navi/asset.svg';
import More from '../assets/images/bottom_navi/more.svg';

import Home_active from '../assets/images/bottom_navi/home_active.svg';
import Piece_active from '../assets/images/bottom_navi/piece_active.svg';
import Invest_active from '../assets/images/bottom_navi/invest_active.svg';
import Asset_active from '../assets/images/bottom_navi/asset_active.svg';
import More_active from '../assets/images/bottom_navi/more_active.svg';

const BottomNaviContainer = styled.ul`
  position: fixed;
  right: 50;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 66px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0 20px;
  border-top: 1px solid #d8d8d8;
  z-index: 9999;
`;

const BottomNaviContentWrapper = styled.li`
  margin: 0;
  width: 100%;
`;

const BottomLink = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 4px 0;
  height: 53px;
  color: #484f61;

  div {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: cover;
    &.ico_home {
      width: 24px;
      height: 24px;
      background: url(${Home});
    }
    &.ico_piece {
      width: 24px;
      height: 24px;
      background: url(${Piece});
    }
    &.ico_invest {
      width: 24px;
      height: 24px;
      background: url(${Invest});
    }
    &.ico_asset {
      width: 24px;
      height: 24px;
      background: url(${Asset});
    }
    &.ico_more {
      width: 24px;
      height: 24px;
      background-image: url(${More});
    }
  }

  &.activeHome {
    color: #ff8a22;
    .ico_home {
      width: 24px;
      height: 24px;
      background: url(${Home_active});
    }
  }

  &.activePiece {
    color: #ff8a22;
    .ico_piece {
      width: 24px;
      height: 24px;
      background: url(${Piece_active});
    }
  }

  &.activeInvest {
    color: #ff8a22;
    .ico_invest {
      width: 24px;
      height: 24px;
      background: url(${Invest_active});
    }
  }

  &.activeAsset {
    color: #ff8a22;
    .ico_asset {
      width: 24px;
      height: 24px;
      background: url(${Asset_active});
    }
  }

  &.activeMore {
    color: #ff8a22;
    .ico_more {
      width: 24px;
      height: 24px;
      background: url(${More_active});
    }
  }
`;

/// ///////////

const MobileBottomNavi = () => {
  const location = useLocation();

  const bottomPathname = ['/', '/piece', '/benefit', '/market', '/my', '/more'];
  if (
    !bottomPathname.includes(location.pathname) &&
    location.pathname.split('/')[1] !== 'market'
  )
    return null;

  return (
    <>
      <BottomNaviContainer>
        <BottomNaviContentWrapper>
          <BottomLink exact to="/" activeClassName="activeHome">
            <div className="ico_home" />
            <span style={{ fontSize: 12 }}>홈</span>
          </BottomLink>
        </BottomNaviContentWrapper>

        <BottomNaviContentWrapper>
          <BottomLink exact to="/market" activeClassName="activeInvest">
            <div className="ico_invest" />
            <span style={{ fontSize: 12 }}>조각마켓</span>
          </BottomLink>
        </BottomNaviContentWrapper>

        <BottomNaviContentWrapper>
          <BottomLink exact to="/benefit" activeClassName="activePiece">
            <div className="ico_piece" />
            <span style={{ fontSize: 12 }}>혜택</span>
          </BottomLink>
        </BottomNaviContentWrapper>

        <BottomNaviContentWrapper>
          <BottomLink to="/my" activeClassName="activeAsset">
            <div className="ico_asset" />
            <span style={{ fontSize: 12 }}>내자산</span>
          </BottomLink>
        </BottomNaviContentWrapper>
        <BottomNaviContentWrapper>
          <BottomLink exact to="/more" activeClassName="activeMore">
            <div className="ico_more" />
            <span style={{ fontSize: 12 }}>더보기</span>
          </BottomLink>
        </BottomNaviContentWrapper>
      </BottomNaviContainer>
    </>
  );
};

export default MobileBottomNavi;
