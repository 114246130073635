import { useMediaQuery } from '@material-ui/core';

function useIsMobile() {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return isMobile;
}

function useIsTablet() {
  const isTablet = useMediaQuery('(max-width: 1024px)');
  return isTablet;
}

export { useIsMobile, useIsTablet };
