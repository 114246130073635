import styled from 'styled-components';
import { FlexRowCentered, SpaceBetween } from '@/styles/CommonStyledComponents';
import { usePurchase } from '@/contexts/PurchaseContext';

const AvailableAmount = styled(SpaceBetween)`
  margin-bottom: 8px;
  font-size: 13px;
`;

const InputLabel = styled.label``;

const AvailableAmountLabel = styled.span`
  color: #95948f;
  font-weight: 400;
`;

const AvailableAmountValue = styled.span`
  color: #000;
  font-weight: 700;
`;

const InputContainer = styled(FlexRowCentered)<{ isReadOnly?: boolean }>`
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  background-color: ${({ isReadOnly }) =>
    isReadOnly ? '#F7F7F7' : 'transparent'};
`;

const StyledInput = styled.input`
  flex: 1;
  padding-left: 8px;
  background-color: transparent;
  color: ${({ readOnly }) => (readOnly ? '#979797' : 'inherit')};
  cursor: ${({ readOnly }) => (readOnly ? 'default' : 'inherit')};
`;

const UseAllButton = styled.button<{ isReadOnly?: boolean }>`
  background: #f5f6f8;
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 13px;
  margin-left: 5px;
  visibility: ${({ isReadOnly }) => (isReadOnly ? 'hidden' : 'visible')};
`;

const BreakdownContainer = styled.div`
  border-radius: 8px;
  background: #f7f7f7;
  padding: 20px 16px;
  margin-top: 16px;
`;

interface PaymentInputComponentProps {
  onInputChange?: any;
  isKrw?: boolean;
}

function PaymentInputComponent({
  onInputChange,
  isKrw,
}: PaymentInputComponentProps) {
  const { state, dispatch } = usePurchase();
  const { krwBalance, pointBalance, krwBudget, pointBudget } = state;
  // const { krwBalance, pointBalance, krwUseAmount, pointUseAmount } = state;

  const inputAmount = isKrw ? krwBudget : pointBudget;
  // const inputAmount = isKrw ? krwUseAmount : pointUseAmount;
  const disableInputField = isKrw || !pointBalance;

  const handleUseAllButton = () => {
    dispatch({
      type: 'SET_POINT_BUDGET',
      payload: pointBalance,
    });
  };

  return (
    <div>
      <InputLabel>
        <AvailableAmount>
          <AvailableAmountLabel>
            사용 가능 {isKrw ? '예치금' : '포인트'}
          </AvailableAmountLabel>
          <AvailableAmountValue>
            {isKrw
              ? `${krwBalance.toLocaleString()}원`
              : `${pointBalance.toLocaleString()}P`}
          </AvailableAmountValue>
        </AvailableAmount>

        <InputContainer isReadOnly={disableInputField}>
          <StyledInput
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            value={inputAmount || ''}
            onChange={onInputChange}
            placeholder={
              isKrw
                ? '예치금 사용 안 함'
                : pointBalance
                ? '포인트를 입력해 주세요'
                : '보유하신 포인트가 없습니다'
            }
            readOnly={disableInputField}
          />

          <UseAllButton
            onClick={handleUseAllButton}
            isReadOnly={disableInputField}
          >
            모두 사용
          </UseAllButton>
        </InputContainer>
      </InputLabel>
    </div>
  );
}

export default PaymentInputComponent;
