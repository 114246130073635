import PillButtonTabMenu from '@/Components/ui/PillButtonTabMenu';
import { getUserPointUseHistory } from '@/fetcher/userQueries';
import {
  CommonContainer,
  LineDivider,
  SpaceBetween,
} from '@/styles/CommonStyledComponents';
import { formattedNumberWithPlusOrMinusSign } from '@treasurer/common';
import { format } from 'date-fns';
import { useState } from 'react';
import styled from 'styled-components';
import { handleValueColorType } from '../../utils/valueColorHandler';

const FILTER_CATEGORY = [
  { key: 'all', label: '전체' },
  { key: 'redeemed', label: '적립' },
  { key: 'used', label: '사용' },
  { key: 'canceled', label: '취소' },
];

const PointUseItem = ({ item }: any) => {
  const { createdAt, label, pointAmount } = item;
  const formattedDate = format(new Date(createdAt), 'yyyy-MM-dd hh:mm aa');

  return (
    <div>
      <DateString>{formattedDate}</DateString>
      <SpaceBetween>
        <HistoryItemName>{label}</HistoryItemName>
        <PointAmount value={pointAmount}>
          {formattedNumberWithPlusOrMinusSign(pointAmount)}P
        </PointAmount>
      </SpaceBetween>

      <LineDivider />
    </div>
  );
};

const DateString = styled.div`
  color: #484f61;
  font-size: 14px;
  margin-bottom: 8px;
`;

const HistoryItemName = styled.span`
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 600;
`;

const PointAmount = styled.span<{ value: number }>`
  color: ${({ value }) => handleValueColorType(value)};
  font-size: 16px;
  font-weight: 700;
`;

function PointUseHistoryPage() {
  const [activeFilter, setActiveFilter] = useState('all');

  const { data: pointUseHistory } = getUserPointUseHistory();

  if (!pointUseHistory) return null;

  const { pointUseHistoryData, pointBalance } = pointUseHistory;

  const categoryFilteredItems = pointUseHistoryData?.filter((item: any) => {
    return activeFilter === 'all' || item.category === activeFilter;
  });

  return (
    <CommonContainer>
      <TopSection>
        <Summary>
          <Details>
            <div>금 포인트</div>
            <div>{pointBalance.metalPoint.toLocaleString()}P</div>
          </Details>
          <Details>
            <div>트레져러 포인트</div>
            <div>{pointBalance.treasurerPoint.toLocaleString()}P</div>
          </Details>
        </Summary>
      </TopSection>

      <MainContentContainer>
        <Title>포인트 내역</Title>

        <FilterMenuWrapper>
          <PillButtonTabMenu
            tabMenuComposition={FILTER_CATEGORY}
            selectedTab={activeFilter}
            setSelectedTab={setActiveFilter}
          />
        </FilterMenuWrapper>

        {categoryFilteredItems.length ? (
          categoryFilteredItems.map((item: any) => (
            <PointUseItem key={`point-use-history-${item.id}`} item={item} />
          ))
        ) : (
          <EmptyCase>카테고리에 해당되는 포인트 내역이 없습니다.</EmptyCase>
        )}
      </MainContentContainer>
    </CommonContainer>
  );
}

export default PointUseHistoryPage;

const TopSection = styled.div`
  padding: 24px 16px;
  background: #f6f6f7;
`;

const Summary = styled.div`
  background: #ffff;
  border-radius: 10px;
  padding: 20px 16px;

  div {
    font-weight: 600;
  }
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 16px;

  :last-child {
    padding-bottom: 0px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
`;

const FilterMenuWrapper = styled.div`
  margin: 16px 0;
  padding: 8px 0;
  position: sticky;
  top: 0;
  background: #fff;
`;

const MainContentContainer = styled.div`
  padding: 20px 16px;
`;

const EmptyCase = styled.div`
  padding: 120px 0;
  text-align: center;
  color: #484f61;
  word-break: keep-all;
  line-height: 20px;
`;
