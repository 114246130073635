/* eslint-disable */
export default {
  home_icon: {
    value: require('../assets/images/home_image/value.png'),
    provenance: require('../assets/images/home_image/provenance.png'),
    safe: require('../assets/images/home_image/safe.png'),
  },
  header: {
    profile: require('../assets/images/header/profile.png'),
    magnifier: require('../assets/images/header/magnifier.png'),
    burger: require('../assets/images/header/burger.png'),
    close: require('../assets/images/header/close.png'),
  },
  ulmaya: {
    ulmaya1: require('../assets/images/ulmaya1.png'),
    ulmaya2: require('../assets/images/ulmaya2.png'),
    ulmaya3: require('../assets/images/ulmaya3.png'),
    ulmayaBackground: require('../assets/images/ulmaya_background.png'),
    ulmaya_section_image: require('../assets/images/section_image.png'),
    ulmaya_step1: require('../assets/images/ulmaya_step1.png'),
    ulmaya_step2: require('../assets/images/ulmaya_step2.png'),
    ulmaya_step3: require('../assets/images/ulmaya_step3.png'),
    app_banner: require('../assets/images/app_banner.png'),
    android_banner: require('../assets/images/android_banner.png'),
  },
  long_arrow: require('../assets/images/long_arrow_right.png'),
  long_arrow_point: require('../assets/images/long_arrow_right_point.png'),
  down_arrow: require('../assets/images/down.png'),
  logo: require('../assets/images/Treasurer.svg'),
  white_logo: require('../assets/images/white_logo.png'),
  footer_logo: require('../assets/images/footer_logo.png'),
  circle_arrow: require('../assets/images/circle_arrow.png'),
  profile: require('../assets/images/profile.png'),
  edit: require('../assets/images/edit.png'),
  editSvg: require('../assets/images/editSvg.svg'),
  closeSvg: require('../assets/images/closeSvg.svg'),
  checkSvg: require('../assets/images/checkSvg.svg'),
  kakaotalkSvg: require('../assets/images/kakaotalk.svg'),
  naver_blogSvg: require('../assets/images/naver_blog.svg'),
  naver_blog_bannerSvg: require('../assets/images/naver_blog_banner.svg'),
  instagramSvg: require('../assets/images/instagramSvg.svg'),
  likeSvg: require('../assets/images/likeSvg.svg'),
  fillLikeSvg: require('../assets/images/fillLikeSvg.svg'),
  notiSvg: require('../assets/images/notiSvg.svg'),
  fillNotiSvg: require('../assets/images/fillNotiSvg.svg'),
  notification: require('../assets/images/notification.svg'),
  fillNotification: require('../assets/images/fillNotification.svg'),
  picesImage: require('../assets/images/pices.png'),
  bubble: require('../assets/images/speech-bubble.svg'),
  search: require('../assets/images/search.png'),
  rightArrow: require('../assets/images/right-arrow.png'),
  emptyImage: require('../assets/images/treasure_map.png'),
  more_logo: require('../assets/images/more_logo.svg'),
  more_blog: require('../assets/images/more_blog.svg'),
  more_notice: require('../assets/images/more_notice.svg'),
  more_question: require('../assets/images/more_question.svg'),
  more_sell: require('../assets/images/more_sell.svg'),
  more_watch: require('../assets/images/more_watch.svg'),
  more_news: require('../assets/images/more_news.svg'),
  depositIcon: require('../assets/images/deposit.svg'),
  withdrawIcon: require('../assets/images/withdraw.svg'),
  certification: require('../assets/images/certification.svg'),
  best_background: require('../assets/images/best_background.jpg'),
  myAccount: require('../assets/images/myAccount.svg'),
  myAsset: require('../assets/images/myAsset.svg'),
  myDaw: require('../assets/images/myDaw.svg'),
  myMarket: require('../assets/images/myMarket.svg'),
  myProfile: require('../assets/images/myProfile.svg'),

  // about
  about_header: require('../assets/images/about/about_header.png'),
  about_treasurer: require('../assets/images/about/about_treasurer.png'),
  about_piece: require('../assets/images/about/about_piece.png'),
  about_circle: require('../assets/images/about/about_circle.png'),
  about_phone: require('../assets/images/about/about_phone.png'),
  about_process: require('../assets/images/about/about_process.png'),
  about_process_1: require('../assets/images/about/about_process_1.png'),
  about_process_2: require('../assets/images/about/about_process_2.png'),
  about_process_3: require('../assets/images/about/about_process_3.png'),
  about_process_4: require('../assets/images/about/about_process_4.png'),
  about_blog_1: require('../assets/images/about/about_blog_1.png'),
  about_blog_2: require('../assets/images/about/about_blog_2.png'),
  about_blog_3: require('../assets/images/about/about_blog_3.png'),
  about_blog_4: require('../assets/images/about/about_blog_4.png'),
  about_blog_5: require('../assets/images/about/about_blog_5.png'),
  about_history_2: require('../assets/images/about/about_history_2.png'),
  about_logo_1: require('../assets/images/about/about_logo_1.png'),
  about_logo_2: require('../assets/images/about/about_logo_2.png'),
  about_logo_3: require('../assets/images/about/about_logo_3.png'),
};
