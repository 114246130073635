import React from 'react';
import qs from 'qs';
import { useHistory } from 'react-router-dom';

const RedirectPage = () => {
  const history = useHistory();

  const hashEmail = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  if (hashEmail.link && typeof hashEmail.link === 'string') {
    window.location.href = hashEmail.link;
  } else {
    history.push('/');
  }
  history.push('/');

  return null;
};

export default RedirectPage;
