import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ArrowBack } from '../../assets/images/newArrowBack.svg';

const StatusBackStyle = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  width: 100%;
  max-width: 768px;
  padding-left: 24px;

  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    height: 56px;
    padding-top: 12px;
    margin-bottom: 0px;
    padding-left: 20px;
  }
`;

type StatusBackProps = {
  title?: string;
  redirectTo?: string;
  style?: React.CSSProperties;
};

const NestedWebViewHeader = ({ title, redirectTo, style }: StatusBackProps) => {
  const history = useHistory();

  const onClickHandler = () => {
    if (history.length <= 1) {
      history.replace('/');
    }
    if (redirectTo) {
      history.push(redirectTo);
    } else {
      history.goBack();
    }
  };

  return (
    <StatusBackStyle onClick={onClickHandler} style={style}>
      <ArrowBack style={{ marginRight: 24 }} />
      {title}
    </StatusBackStyle>
  );
};

export default NestedWebViewHeader;
