import { LineDivider, PaddedContainer } from '@/styles/CommonStyledComponents';
import { SectionTitle } from '../PaymentStyles';
import PaymentInputComponent from './PaymentInputComponent';

function KrwInputSection() {
  return (
    <PaddedContainer>
      <SectionTitle>트레져러 예치금</SectionTitle>
      <LineDivider />

      <PaymentInputComponent isKrw />
    </PaddedContainer>
  );
}

export default KrwInputSection;
