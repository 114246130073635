import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { ORDER_TYPE } from '@treasurer/common';
import {
  getItemMarketPriceFetcher,
  getItemOnsaleListFetcher,
  getNotiCntFetcher,
  getItemDetailFetcher,
  getOrderSummaryFetcher,
  useRequestBuyFetcher,
  useRequestSellFetcher,
  getMarketItemListFetcher,
  getAdminTotalPieceFetcher,
  getRelatedItemFetcher,
  getPlatformPriceOfItemFetcher,
  getCalculatedOrderSummaryFetcher,
  getPurchaseOrderSummaryFetcher,
  requestPurchaseFetcher,
  requestRecruitmentPurchaseFetcher,
  getMetalPriceHistoryFetcher,
  getIndexPriceHistoryFetcher,
} from './ItemFetchers';
import {
  ItemMarketPriceTypes,
  ItemTypes,
  NotificationTypes,
} from '../types/ItemTypes';
import { PointSystemId } from '@/pages/Payment/PaymentUtils';

const getItemDetail = (
  tradeItemId: string,
  options?: UseQueryOptions<
    ItemTypes,
    AxiosError,
    ItemTypes,
    ['getItemDetail', string]
  >,
) =>
  useQuery(
    ['getItemDetail', tradeItemId],
    () => getItemDetailFetcher(tradeItemId),
    {
      ...options,
    },
  );

const getNotiCnt = (
  tradeItemId: number,
  options?: UseQueryOptions<
    NotificationTypes[],
    AxiosError,
    NotificationTypes[],
    ['getNotiCnt']
  >,
) =>
  useQuery(['getNotiCnt'], () => getNotiCntFetcher(tradeItemId), {
    ...options,
  });

const getItemOnsaleList = (
  tradeItemId: number,
  options?: UseQueryOptions<
    { total: number },
    AxiosError,
    { total: number },
    ['getItemOnsaleList']
  >,
) =>
  useQuery(['getItemOnsaleList'], () => getItemOnsaleListFetcher(tradeItemId), {
    ...options,
  });

const getItemMarketPrice = (
  ticker: string,
  options?: UseQueryOptions<
    ItemMarketPriceTypes,
    AxiosError,
    ItemMarketPriceTypes,
    ['getItemMarketPrice', string]
  >,
) =>
  useQuery(
    ['getItemMarketPrice', ticker],
    () => getItemMarketPriceFetcher(ticker),
    {
      ...options,
    },
  );

const getOrderSummary = (
  tradeItemId: number,
  orderType: keyof typeof ORDER_TYPE,
  enteredPrice?: number,
  enteredQuantity?: number,
) =>
  useQuery(
    ['getOrderSummary', tradeItemId, orderType, enteredPrice, enteredQuantity],
    () =>
      getOrderSummaryFetcher(tradeItemId, {
        orderType,
        enteredPrice,
        enteredQuantity,
      }),
  );

export interface PurchaseOrderSummaryInput {
  krwBudget: number;
  pointSystemId: PointSystemId;
  pointBudget: number;
}

export interface PurchaseOrderSummary extends PurchaseOrderSummaryInput {
  tradeItemId: number;
}

const createPaymentInfo = ({
  krwBudget,
  pointSystemId,
  pointBudget,
}: PurchaseOrderSummaryInput) => {
  const paymentInfo = {
    krw: krwBudget,
    treasurerPoint: 0,
    treasurerMetalPoint: 0,
    ocbPoint: 0,
    kbPoint: 0,
    lPoint: 0,
  };

  switch (pointSystemId) {
    case 'treasurerPoint':
      paymentInfo.treasurerPoint = pointBudget;
      break;
    case 'treasurerMetalPoint':
      paymentInfo.treasurerMetalPoint = pointBudget;
      break;
    case 'ocbPoint':
      paymentInfo.ocbPoint = pointBudget;
      break;
    case 'kbPoint':
      paymentInfo.kbPoint = pointBudget;
      break;
    case 'lPoint':
      paymentInfo.kbPoint = pointBudget;
      break;
    default:
      break;
  }

  return paymentInfo;
};

// TODO: new calculated order summary
const getPurchaseOrderSummary = ({
  tradeItemId,
  krwBudget,
  pointSystemId,
  pointBudget,
}: PurchaseOrderSummary) => {
  const paymentInfo = createPaymentInfo({
    krwBudget,
    pointSystemId,
    pointBudget,
  });
  const isEnabled = !!tradeItemId && !!paymentInfo;

  return useQuery(
    [
      'purchaseOrderSummary',
      tradeItemId,
      krwBudget,
      pointSystemId,
      pointBudget,
    ],
    () =>
      getPurchaseOrderSummaryFetcher({
        tradeItemId,
        baseMethod: 'PRICE',
        platform: 'TREASURER',
        paymentInfo,
      }),
    {
      enabled: isEnabled,
    },
  );
};

export type BuyOrderExtra = {
  mctTrToken?: string;
  availablePointree?: number;
};

export interface RequestPurchaseParams {
  tradeItemId: number;
  quantity: number;
  txBudget: number;
  krwBudget: number;
  pointSystemId: PointSystemId;
  pointBudget: number;
  extra?: BuyOrderExtra;
}

// New purchase with points fetcher
const useRequestPurchase = (options?: any) => {
  return useMutation(
    (params: RequestPurchaseParams) => {
      const {
        tradeItemId,
        quantity,
        txBudget,
        krwBudget,
        pointSystemId,
        pointBudget,
        extra,
      } = params;

      const buyOrderPaymentInfo = createPaymentInfo({
        krwBudget,
        pointSystemId,
        pointBudget,
      });

      const orderInfo = {
        tradeItemId,
        preFeeAmount: txBudget,
        requestedQuantity: quantity,
        buyOrderPaymentInfo,
        extra,
      };

      return requestPurchaseFetcher(orderInfo);
    },
    {
      ...options,
    },
  );
};

const useRequestRecruitmentPurchase = (options?: any) => {
  return useMutation(
    (params: RequestPurchaseParams) => {
      const {
        tradeItemId,
        quantity,
        txBudget,
        krwBudget,
        pointSystemId,
        pointBudget,
        extra,
      } = params;

      const buyOrderPaymentInfo = createPaymentInfo({
        krwBudget,
        pointSystemId,
        pointBudget,
      });

      const orderInfo = {
        tradeItemId,
        preFeeAmount: txBudget,
        requestedQuantity: quantity,
        buyOrderPaymentInfo,
        extra,
      };

      return requestRecruitmentPurchaseFetcher(orderInfo);
    },
    {
      ...options,
    },
  );
};

// TODO: use existing order summary api for sell orders
const getCalculatedOrderSummary = (
  tradeItemId: number,
  orderType: keyof typeof ORDER_TYPE,
  enteredPrice?: number,
  enteredQuantity?: number,
) =>
  useQuery(
    ['orderSummary', tradeItemId, orderType, enteredPrice, enteredQuantity],
    () =>
      getCalculatedOrderSummaryFetcher({
        pathParams: { tradeItemId },
        queryParams: { orderType, enteredPrice, enteredQuantity },
      }),
  );

const useRequestBuy = (
  param: {
    tradeItemId: number;
    appraisalPrice: number;
    quantity: number;
    totalTransactionPrice: number;
  },
  options?: any,
) =>
  useMutation(['useRequestBuy'], () => useRequestBuyFetcher(param), {
    ...options,
  });

const useRequestSell = (
  param: {
    tradeItemId: number;
    appraisalPrice: number;
    quantity: number;
    totalTransactionPrice: number;
  },
  options?: any,
) =>
  useMutation(['useRequestSell'], () => useRequestSellFetcher(param), {
    ...options,
  });

const getMarketItemList = () =>
  useQuery(['getMarketItemList'], () => getMarketItemListFetcher());

const getAdminTotalPiece = (tradeItemId: number) =>
  useQuery(['getAdminTotalPiece', tradeItemId], () =>
    getAdminTotalPieceFetcher(tradeItemId),
  );

const getRelatedItem = (tradeItemId: number) =>
  useQuery(['getRelatedItem', tradeItemId], () =>
    getRelatedItemFetcher(tradeItemId),
  );

const getPlatformPriceOfItem = (tradeItemId: number) =>
  useQuery(['getPlatformPriceOfItem', tradeItemId], () =>
    getPlatformPriceOfItemFetcher(tradeItemId),
  );

interface MetalPriceHistoryParams {
  tradeItemId: number;
  startDt: string;
  endDt: string;
}

const getMetalPriceHistory = ({
  tradeItemId,
  startDt,
  endDt,
}: MetalPriceHistoryParams) => {
  if (tradeItemId < 10000) {
    const assetsType = tradeItemId === 1001 ? 'peg' : 'pes';

    return useQuery(['getMetalPriceHistory', tradeItemId, startDt, endDt], () =>
      getMetalPriceHistoryFetcher({
        queryParams: { assetsType, startDt, endDt },
      }),
    );
  } else {
    return useQuery(['getIndexPriceHistory', tradeItemId, startDt, endDt], () =>
      getIndexPriceHistoryFetcher({ tradeItemId, startDt, endDt }),
    );
  }
};

export {
  getItemDetail,
  getNotiCnt,
  getItemOnsaleList,
  getItemMarketPrice,
  getOrderSummary,
  getPurchaseOrderSummary,
  useRequestPurchase,
  useRequestRecruitmentPurchase,
  getCalculatedOrderSummary,
  useRequestBuy,
  useRequestSell,
  getMarketItemList,
  getAdminTotalPiece,
  getRelatedItem,
  getPlatformPriceOfItem,
  getMetalPriceHistory,
};
