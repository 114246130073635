import { ORDER_TYPE, SinglePriceMarketRequest } from '@treasurer/common';
import { useMutation, useQuery } from 'react-query';
import {
  bulkOrderRequestFetcher,
  getIndividualUserPortfolioFetcher,
  getUserPortfolioFetcher,
} from './socialFetchers';

export const getUserPortfolio = () =>
  useQuery(['getUserPortfolio'], () => getUserPortfolioFetcher());

export const getIndividualUserPortfolio = (userId: number) =>
  useQuery(['getIndividualUserPortfolio'], () =>
    getIndividualUserPortfolioFetcher(userId),
  );

export const useBulkOrderRequest = (
  param: SinglePriceMarketRequest[],
  requestedPurchasePrice: number,
  orderType: keyof typeof ORDER_TYPE,
  options?: any,
) =>
  useMutation(
    ['useBulkOrderRequest'],
    () =>
      bulkOrderRequestFetcher(
        { requestedItemsInfo: param, requestedPurchasePrice },
        orderType,
      ),
    {
      ...options,
    },
  );
