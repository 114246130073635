import { Dispatch, useState, useCallback, useEffect, useRef } from 'react';
import { useMediaQuery } from '@material-ui/core';
import styled from '@emotion/styled';
import { getVirtualAccount, updateVirtualAccount } from '@/fetcher/userQueries';
import CustomBottomModal from '../Modal/CustomBottomModal';
import NumberPad from '../ItemDetail/Mobile/OnSale/NumberPad';
import FullLoading from '../Loading/FullLoading';
import CustomModal from '../Modal/CustomModal';
import { isMobileDevice } from '@/utils/platformDetection';

interface BankAccountNumberModalProps {
  numpadModalOpen: boolean;
  setRegisterModalOpen: Dispatch<boolean>;
  onClose?: () => void;
  bankName: string | null;
  bankCode: string | null;
  setStatusMsgModal: Dispatch<boolean>;
  setStatusTitle: Dispatch<string>;
  setStatusMsg: Dispatch<string>;
  migrationCheck?: boolean;
}

interface ResultTypes {
  code: number;
  message: string;
}

const BankAccountNumberModal = ({
  numpadModalOpen,
  setRegisterModalOpen,
  onClose,
  bankName,
  bankCode,
  setStatusMsgModal,
  setStatusTitle,
  setStatusMsg,
  migrationCheck,
}: BankAccountNumberModalProps) => {
  const [enteredNumber, setEnteredNumber] = useState<string | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [enabled, setEnabled] = useState(false);
  const [updateEnabled, setUpdateEnabled] = useState(false);

  const isMobile = useMediaQuery('(max-width: 768px)');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const extractedNumbers = value.replace(/\D/g, '');
      setEnteredNumber(extractedNumbers);
    },
    [],
  );

  const onChangeNumPadBtn = useCallback((num: number) => {
    setEnteredNumber(
      (prev) => (prev === null ? '' : String(prev)) + String(num),
    );
  }, []);

  const onChangeNumPadDelete = useCallback(() => {
    setEnteredNumber((prev) => (prev ? prev.slice(0, -1) : null));
  }, []);

  const onClear = useCallback(() => {
    setEnteredNumber(null);
  }, []);

  useEffect(() => {
    inputRef.current?.focus();
  }, [enteredNumber]);

  useEffect(() => {
    setIsButtonDisabled(!(enteredNumber && enteredNumber.length >= 8));
  }, [enteredNumber]);

  const { data: virtualAccount, isLoading: isVirtualAccountLoading } =
    getVirtualAccount(
      {
        bankCode: bankCode as string,
        accountNumber: enteredNumber as string,
      },
      {
        enabled,
        onSuccess: async (result: ResultTypes | { result: string }) => {
          setEnabled(false);

          if ('code' in result) {
            setStatusTitle('계좌 연결 실패');
            setStatusMsg(result.message);
          }
          setStatusMsgModal(true);
          onClose?.();
        },
        onError: () => {
          setStatusMsg(
            '요청이 정상적으로 처리되지 않았습니다. 네트워크 연결 확인 후 다시 시도해 주세요.',
          );
          onClose?.();
          setStatusMsgModal(true);
        },
      },
    );

  const {
    mutate: updateWithdrawAccount,
    isLoading: isUpdateBankAccountLoading,
  } = updateVirtualAccount(
    {
      bankCode: bankCode as string,
      accountNumber: enteredNumber as string,
    },
    {
      updateEnabled,
      onSuccess: async (result: ResultTypes | { result: string }) => {
        setUpdateEnabled(false);

        if ('code' in result) {
          setStatusMsg(result.message);
        }
        setStatusMsgModal(true);
        onClose?.();
      },

      onError: () => {
        setStatusMsg(
          '요청이 정상적으로 처리되지 않았습니다. 네트워크 연결 확인 후 다시 시도해 주세요.',
        );
        onClose?.();
        setStatusMsgModal(true);
      },
    },
  );

  if (isVirtualAccountLoading || isUpdateBankAccountLoading) {
    return <FullLoading backgroundDark />;
  }

  const BankAccountNumberModalContent = () => (
    <>
      <BankTitle>{bankName}</BankTitle>
      <InputField
        ref={inputRef}
        value={enteredNumber || ''}
        placeholder="연결할 계좌번호를 입력해 주세요."
        onChange={handleInputChange}
        enteredNumber={enteredNumber}
        inputMode={isMobileDevice() ? 'none' : 'text'}
      />

      <NumberPad
        onClear={onClear}
        onChangeNumPadBtn={onChangeNumPadBtn}
        onChangeNumPadDelete={onChangeNumPadDelete}
      />

      <BackButton
        onClick={() => {
          onClose?.();
          setRegisterModalOpen(true);
        }}
      >
        뒤로 가기
      </BackButton>

      <RequestButton
        disabled={isButtonDisabled}
        onClick={() =>
          migrationCheck ? updateWithdrawAccount() : setEnabled(true)
        }
        isButtonDisabled={isButtonDisabled}
      >
        {migrationCheck ? '수정하기' : '연결하기'}
      </RequestButton>
    </>
  );

  return (
    <>
      {isMobile ? (
        <CustomBottomModal modalOpen={numpadModalOpen} onRequestClose={onClose}>
          <BankAccountNumberModalContent />
        </CustomBottomModal>
      ) : (
        <CustomModal
          modalOpen={numpadModalOpen}
          onRequestClose={onClose}
          customWidth="500px"
        >
          <BankAccountNumberModalContent />
        </CustomModal>
      )}
    </>
  );
};

export default BankAccountNumberModal;

const BankTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin: 48px;
  text-align: center;
`;

const BackButton = styled.button`
  border: 1px solid #ff8a22;
  border-radius: 5px;
  padding: 11px 0px;
  width: calc(40% - 17px);
  height: 40px;
  margin-right: 17px;
  font-size: 14px;
  font-weight: 500;
`;

const InputField = styled.input<{ enteredNumber: string | null }>`
  text-align: center;
  color: ${(props) => (props.enteredNumber ? `#1D1D1D` : `#A3A7B0`)};
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
  background-color: transparent;
  width: 100%;
`;

const RequestButton = styled.button<{ isButtonDisabled: boolean }>`
  background-color: ${(props) =>
    props.isButtonDisabled ? `#a3a7b0` : `#FF8A22`};
  border-radius: 4px;
  color: #ffffff;
  font-weight: 700;
  padding: 11px 0px;
  width: 60%;
  height: 40px;
  cursor: ${(props) => (props.isButtonDisabled ? `default` : `pointer`)};
`;
