import { useMutation } from 'react-query';
import client from '@/apis/axios-instance';

export const useCreateCryptoApiKey = () =>
  useMutation(
    'coinone/oauth',
    async ({ request_token }: { request_token: string }) => {
      const { data } = await client.post('/v2/crypto/coinone/oauth', {
        request_token,
      });
      return data;
    },
  );
