export type BankName =
  | '카카오뱅크'
  | 'NH농협은행'
  | '신한은행'
  | 'IBK기업은행'
  | '우리은행'
  | 'SC제일은행'
  | '대구은행'
  | '부산은행'
  | '광주은행'
  | '새마을금고'
  | '경남은행'
  | '전북은행'
  | '제주은행'
  | '산업은행'
  | '우체국'
  | '신협은행'
  | '수협은행'
  | '씨티은행'
  | '케이뱅크'
  | '하나은행'
  | '국민은행'
  | '토스뱅크';

export type BankCode =
  | '090'
  | '011'
  | '088'
  | '003'
  | '020'
  | '023'
  | '031'
  | '032'
  | '034'
  | '045'
  | '039'
  | '037'
  | '035'
  | '002'
  | '071'
  | '048'
  | '007'
  | '027'
  | '089'
  | '081'
  | '004'
  | '092';

export const banks: { [key in BankName]: BankCode } = {
  카카오뱅크: '090',
  국민은행: '004',
  신한은행: '088',
  NH농협은행: '011',
  우리은행: '020',
  하나은행: '081',
  케이뱅크: '089',
  IBK기업은행: '003',
  토스뱅크: '092',
  새마을금고: '045',
  SC제일은행: '023',
  대구은행: '031',
  부산은행: '032',
  경남은행: '039',
  신협은행: '048',
  수협은행: '007',
  우체국: '071',
  씨티은행: '027',
  광주은행: '034',
  산업은행: '002',
  전북은행: '037',
  제주은행: '035',
};
