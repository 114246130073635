import { useState } from 'react';
import styled from 'styled-components';
import palette from '../../config/styles/color/Color';
import infoIcon from '../../assets/images/icon_info.svg';
import searchIcon from '../../assets/images/search_icon.svg';
import { useHistory } from 'react-router-dom';
import CustomModal from '@/Components/Modal/CustomModal';
import SearchModal from './SearchModal';
import { useIsTablet } from '../../utils/customHook/useIsMobile';

type TabMenuProps = {
  active: boolean;
};

const HeaderContainer = styled.div`
  padding: 35px 16px 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 792px;
  margin: auto;
`;

const TabMenuContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-right: 20px;
`;

const TabMenu = styled.div<TabMenuProps>`
  color: ${(props) => (props.active ? palette.black[0] : palette.ghost[2])};
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  cursor: pointer;
`;

const SearchBar = styled.button`
  margin-left: auto;
  margin-right: 16px;
`;

const HelpButton = styled.button`
  width: 22px;
`;

const HelpModalContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const MarketPageHeader = () => {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const history = useHistory();

  const isTablet = useIsTablet();

  const currentPathname = window.location.pathname;

  return (
    <HeaderContainer>
      {isTablet && (
        <TabMenuContainer>
          <TabMenu
            active={currentPathname === '/market'}
            onClick={() => history.push('/market')}
          >
            마켓
          </TabMenu>
          <TabMenu
            active={currentPathname === '/soldout'}
            onClick={() => history.push('/soldout')}
          >
            매각현황
          </TabMenu>
        </TabMenuContainer>
      )}

      {currentPathname === '/market' && (
        <>
          <SearchBar onClick={() => setIsSearchModalOpen(true)}>
            <img src={searchIcon} alt="검색" />
          </SearchBar>

          <HelpButton type="button" onClick={() => setIsHelpModalOpen(true)}>
            <img src={infoIcon} alt="도움말" />
          </HelpButton>

          <SearchModal
            isSearchModalOpen={isSearchModalOpen}
            setIsSearchModalOpen={setIsSearchModalOpen}
          />

          <CustomModal
            modalOpen={isHelpModalOpen}
            onRequestClose={() => setIsHelpModalOpen(false)}
          >
            <HelpModalContent>
              <p>[소팅 테이블 안내]</p>
              <p>
                시장가격: 조각으로 환산한 가격이 아닌 물품의 전체 가격으로,
                시장에서 거래되고 있는 가격입니다.
              </p>
              <p>누적거래: 트레져러 마켓에서 체결된 누적 거래액입니다.</p>
              <p>
                조각가격: 마켓에서 거래되고 있는 1조각당 가격 (호가거래)입니다.
              </p>
            </HelpModalContent>
          </CustomModal>
        </>
      )}
    </HeaderContainer>
  );
};

export default MarketPageHeader;
