import { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import klip_logo from '../../assets/images/klip/klip_logo.png';

import { klipPrepare, klipResult } from '../../redux/actions/authAction';

const ModalContainer = styled.div`
  /* width: 20rem; */
  width: 876px;
  height: 326px;
  text-align: center;

  span {
    margin-top: 102px;
    font-size: 19px;
    font-weight: bold;
  }

  p {
    color: #3d3d3d;
    font-size: 16px;
  }

  @media (max-width: 1024px) {
    max-width: 100%;
    width: 100%;
    max-height: 100vh;
    overflow-y: auto;
    scroll-behavior: smooth;
    background-color: white;

    padding: 20px;

    span {
      font-size: 15px;
    }

    p {
      font-size: 12px;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 42px;
`;

const KlipCompleteButton = styled.button`
  width: 201px;
  height: 52px;
  background-color: #ffd80d;
  text-align: center;
  color: black;
  font-size: 18px;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    width: 80%;
  }
`;

const RetryButton = styled.button`
  width: 201px;
  height: 52px;
  border: 1px solid black;
  font-size: 18px;
  font-weight: bold;
  color: #5a5a5a;
  margin-left: 48px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 1024px) {
    width: 80%;
  }
`;

const XButton = styled.div`
  color: #323232;
  font-size: 30px;
  margin: 30px;
  cursor: pointer;
`;
const ErrorMessage = styled.div`
  text-align: center;
  margin-top: 5px;
  font-size: 13px;
  color: #fe4f18;
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 0,
    border: '1px solid rgba(0,0,0,0.4)',
    boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 12px',
  },
};

const KakaoKlipModal = ({
  modalOpen,
  klipRequestKey,
  xBtnAction,
  onRequestClose,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [errorTerm, setErrorTerm] = useState(false);
  const [requestKey, setrequestKey] = useState('');

  // klip result
  const handleKlipResult = async () => {
    try {
      let result;
      if (requestKey) {
        result = await dispatch(klipResult(requestKey));
      } else {
        result = await dispatch(klipResult(klipRequestKey));
      }

      if (result.result === 'failed') {
        setErrorTerm(true);
      } else {
        alert('연동되었습니다.');
        if (location.pathname === '/my') {
          history.go(0);
        } else {
          history.push('/');
        }
      }
    } catch (e) {
      if (typeof window !== 'undefined') window.console.error(e);
    }
  };

  const handleKlipRetry = async () => {
    try {
      const prepare = await dispatch(klipPrepare());

      if (prepare?.data) {
        setrequestKey(prepare?.data.request_key);
      }

      alert('카카오톡으로 다시 보내드렸습니다.');
    } catch (e) {
      if (typeof window !== 'undefined') window.console.error(e);
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      style={customStyles}
      onRequestClose={onRequestClose}
    >
      <ModalContainer>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <XButton onClick={xBtnAction}>&times;</XButton>
        </div>
        <span>카카오 클립에서 인증을 기다리고 있습니다.</span>
        <p style={{ marginTop: '16px' }}>
          카카오톡으로 전달 된 메세지에서 연동을 선택하셔서 카카오 클립에서
          인증을 완료 하신후,
        </p>
        <p style={{ marginTop: '5px' }}>아래 완료 버튼을 클릭해주세요</p>
        <ButtonWrapper>
          <KlipCompleteButton onClick={() => handleKlipResult()}>
            <img
              style={{ width: '52px', marginRight: '10px' }}
              src={klip_logo}
              alt="클립 로고"
            />
            완료
          </KlipCompleteButton>
          <RetryButton onClick={() => handleKlipRetry()}>다시 시도</RetryButton>
        </ButtonWrapper>
        {errorTerm ? (
          <>
            <ErrorMessage style={{ marginTop: '17px' }}>
              *아직 인증이 완료되지 않았습니다. 트레져러 카카오채널 메세지를
              확인해주세요.
            </ErrorMessage>
            <ErrorMessage>
              인증메세지가 오지 않았다면, 다시 시도버튼을 누른 후 메세지를
              확인해 주세요.
            </ErrorMessage>
          </>
        ) : null}
      </ModalContainer>
    </Modal>
  );
};

export default KakaoKlipModal;
